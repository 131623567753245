angular.module("app").component("dashboard.humanTruth", {
	templateUrl: "app/dashboard/game/03-human-truth.html",
	controllerAs: "vm",

	controller: function (
		__env,
		$rootScope,
		$state,
		$scope,
		$localStorage,
		$window,
		dashboardFactory,
		showMyResultsService,
		utilService,
		$timeout,
		socket,
		sweetAlert
	) {
		if (__env.enableDebug) {
			console.log("humanTruthController");
		}

		if (
			$localStorage.role === "super admin" ||
			$localStorage.role === "facilitator"
		) {
			$state.go("dashboard.group-manage");
		}

		// Set this to self to avoid conflicts
		const self = this;

		// Used to set the navigator
		$scope.currentGame = 3;

		// Don't show popup if phase has been completed
		self.showPopup = true;
		if (
			angular.isDefined($localStorage.progress) && 
			angular.isDefined($localStorage.progress.human_truth[0]) &&
			$localStorage.progress.human_truth[0].completed
		) {
			if (__env.enableDebug) {
				console.log("This game has been completed");
			}
			self.showPopup = false;
		}

		// Popup Content
		$scope.popupContent =
			'<div class="row">\
				<div class="col-md-12">\
				<p><strong>Searching for the tension.</strong></p>\
				<p> People don\'t care about brands, they care about defining their identities and building their stories. Within this context, brands and products are storytelling tools that acquire relevance when they help to solve a tension or dilemma that people experience in their life. That\'s why starting from a powerful human and cultural tension is the first step to build purposeful and powerful brands.</p>\
				\
				<hr>\
				<p><strong>What\'s a tension?</strong></p>\
				<p>A tension should be something you can tangibly feel in society today: it should both have broad, social relevance and also offer a space in which the brand can credibly have a point of view. It\'s the core aspiration of the people whom the brand serves, reflecting a deep rooted dilemma they are struggling to solve.</p>\
				<p><a target="_blank" href="https://drive.google.com/file/d/1qOc6VDoFT9GSxOkZz8H2VP-ciRA4IrQY/view">Click here to view examples</a></p>\
				\
				<hr>\
				<p><strong>What are the cultural tensions our people are facing today?</strong></p>\
				<p>In your small group, you will now aim to identify relevant cultural tensions for the people the brand serves, based off what we’ve heard from them about their lives.</p>\
				</div>\
			</div>';

			$scope.navigateToGamePhase = function(gamePhaseName, completedPhaseNumber) {
				var result = utilService.navigateToGamePhase(gamePhaseName, completedPhaseNumber);
				if (result) {
					$state.go(result);
				}
			};

		$scope.currentWorkshopId = $localStorage.chosenWorkshop.id;
		$scope.url = __env.apiUrl;
		$rootScope.userEmail = $localStorage.user.email;
		$scope.email = $localStorage.user.email;
		$scope.progress = $localStorage.progress;
		$scope.profilerChat = [];
		$scope.isActive = 3;
		$scope.group = null;
		$scope.loadingScreen = true;

		// Get and Set available game results for show my results component
		showMyResultsService.getAvailableGameResults($scope.currentGame).then(function(myGameResults) {
			$scope.myResults = myGameResults;
			$scope.gameResultsToShow = {};
			$scope.currentResults = ($scope.myResults.length > 0) ? $scope.myResults[0].value : 'default';
			$scope.numberOfResults = showMyResultsService.getNumberOfShowingResults($scope.myResults);
		});

		// Set the function to update selected results on component
		$scope.updateSelectedResults = function(selectedResults) {
			if (angular.isDefined($scope.myResults)) {
				showMyResultsService.updateSelectedResults(selectedResults, $scope.myResults).then(function(result) {
					$scope.gameResultsToShow = result;
				});
			}
		};

		// Run when component is ready
		self.$onInit = function () {
			// Update progress
			dashboardFactory.progress().then(function (result) {
				$scope.progress = result.progress;
				$localStorage.progress = result.progress;
				// Get the users current workshop game
				$scope.game = JSON.parse(result.current_progress).current_game;
				var gotoPhase = utilService.navigateToGamePhase($scope.currentGame, $scope.game, $scope.currentGame);
				if (angular.isDefined(gotoPhase) && gotoPhase !== 'dashboard.human-truth') {
					$state.go(gotoPhase, {}, { reload: true });
				} else {
					$localStorage.lastPlayedPhase = $scope.game;
				}

				// Set completed state
				if (angular.isDefined(result.progress) && angular.isDefined(result.progress.human_truth[0])) {
					$scope.completed = result.progress.human_truth[0].completed;
				}
			});
			$scope.choosenWorkshopMethod();
			$scope.userGroups = $localStorage.groups;
		};

		$scope.next = function () {
			socket.emit("someOneSubmitted", "true");
			$localStorage.lastPlayedPhase = $scope.game;
			$state.go("dashboard.brand-pov");
		};

		$scope.choosenWorkshopMethod = function () {
			dashboardFactory.fetch().then(function (result) {
				$scope.chosenWorkshop = result.group;
				$localStorage.chosenWorkshop = result.group;

				if ($localStorage.progress.human_truth.length > 0) {
					$scope.tension = false;
					$scope.showDetails = true;

					if ($localStorage.progress.human_truth[0].group_id) {
						$scope.loadingScreen = true;

						$scope.choiceSetMethod(
							$localStorage.progress.human_truth[0].group_id
						);

						$scope.group = $localStorage.progress.human_truth[0].group_id;

						// Manage locking of content
						if(result.locks['human-truth-game'] && result.locks['human-truth-game'][$scope.group]){
							$scope.locked = true;
						}

						dashboardFactory
							.collaborationHumanGroupResults(result.group.id, 0)
							.then(function (result) {
								$scope.humanGroups = result.groups;
							});

						$timeout(function () {
							if ($scope.loadingScreen && self.showPopup) {
								sweetAlert.swal({
									html: $scope.popupContent,
									customClass: "instructions",
									showCancelButton: false,
									showConfirmButton: false,
									showCloseButton: true,
								});
							}
						});
					} else {
						$scope.loadingScreen = false;
					}
				} else {
					$scope.tension = true;
				}
			});
		};

		$scope.choiceSetMethod = function ($id) {
			$scope.tensionID = $scope.chosenWorkshop.human_truth_game[$id - 1].id;
			$scope.statement_1_shown = "true";
			$scope.statement_1_1 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_1_word_1;
			$scope.statement_1_1_isDisabled = "";
			$scope.statement_1_1_editing = "";
			$scope.statement_1_1_whoIsEditing = "check";
			$scope.statement_1_1_whoIsEditingName = "";

			$scope.statement_1_2 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_1_word_2;
			$scope.statement_1_2_isDisabled = "";
			$scope.statement_1_2_editing = "";
			$scope.statement_1_2_whoIsEditing = "check";
			$scope.statement_1_2_shown = "true";
			$scope.statement_1_2_whoIsEditingName = "";

			$scope.statement_2_shown = "true";
			$scope.statement_2_1 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_2_word_1;
			$scope.statement_2_1_isDisabled = "";
			$scope.statement_2_1_editing = "";
			$scope.statement_2_1_whoIsEditing = "check";
			$scope.statement_2_1_whoIsEditingName = "";

			$scope.statement_2_2 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_2_word_2;
			$scope.statement_2_2_isDisabled = "";
			$scope.statement_2_2_editing = "";
			$scope.statement_2_2_whoIsEditing = "check";
			$scope.statement_2_2_shown = "true";
			$scope.statement_2_2_whoIsEditingName = "";

			$scope.statement_3_shown = "true";
			$scope.statement_3_1 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_3_word_1;
			$scope.statement_3_1_isDisabled = "";
			$scope.statement_3_1_editing = "";
			$scope.statement_3_1_whoIsEditing = "check";
			$scope.statement_3_1_whoIsEditingName = "";

			$scope.statement_3_2 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_3_word_2;
			$scope.statement_3_2_isDisabled = "";
			$scope.statement_3_2_editing = "";
			$scope.statement_3_2_whoIsEditing = "check";
			$scope.statement_3_2_shown = "true";
			$scope.statement_3_2_whoIsEditingName = "";

			$scope.statement_4_shown = "false";
			$scope.statement_4_1 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_4_word_1;
			$scope.statement_4_1_isDisabled = "";
			$scope.statement_4_1_editing = "";
			$scope.statement_4_1_whoIsEditing = "check";
			$scope.statement_4_1_whoIsEditingName = "";

			$scope.statement_4_2 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_4_word_2;
			$scope.statement_4_2_isDisabled = "";
			$scope.statement_4_2_editing = "";
			$scope.statement_4_2_whoIsEditing = "check";
			$scope.statement_4_2_shown = "true";
			$scope.statement_4_2_whoIsEditingName = "";

			$scope.statement_5_shown = "false";
			$scope.statement_5_1 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_5_word_1;
			$scope.statement_5_1_isDisabled = "";
			$scope.statement_5_1_editing = "";
			$scope.statement_5_1_whoIsEditing = "check";
			$scope.statement_5_1_whoIsEditingName = "";

			$scope.statement_5_2 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_5_word_2;
			$scope.statement_5_2_isDisabled = "";
			$scope.statement_5_2_editing = "";
			$scope.statement_5_2_whoIsEditing = "check";
			$scope.statement_5_2_shown = "true";
			$scope.statement_5_2_whoIsEditingName = "";

			$scope.statement_6_shown = "false";
			$scope.statement_6_1 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_6_word_1;
			$scope.statement_6_1_isDisabled = "";
			$scope.statement_6_1_editing = "";
			$scope.statement_6_1_whoIsEditing = "check";
			$scope.statement_6_1_whoIsEditingName = "";

			$scope.statement_6_2 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_6_word_2;
			$scope.statement_6_2_isDisabled = "";
			$scope.statement_6_2_editing = "";
			$scope.statement_6_2_whoIsEditing = "check";
			$scope.statement_6_2_shown = "true";
			$scope.statement_6_2_whoIsEditingName = "";

			$scope.statement_7_shown = "false";
			$scope.statement_7_1 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_7_word_1;
			$scope.statement_7_1_isDisabled = "";
			$scope.statement_7_1_editing = "";
			$scope.statement_7_1_whoIsEditing = "check";
			$scope.statement_7_1_whoIsEditingName = "";

			$scope.statement_7_2 =
				$scope.chosenWorkshop.human_truth_game[$id - 1].statement_7_word_2;
			$scope.statement_7_2_isDisabled = "";
			$scope.statement_7_2_editing = "";
			$scope.statement_7_2_whoIsEditing = "check";
			$scope.statement_7_2_shown = "true";
			$scope.statement_7_2_whoIsEditingName = "";

			socket.emit("check", "first check");

			dashboardFactory
				.groupMembers($scope.chosenWorkshop.id, $id, "HumanTruthGame")
				.then(
					function (result) {
						$scope.groupMembers = result.members;
						$timeout(function () {});
					},
					function (error) {}
				);
			angular.forEach(
				$scope.chosenWorkshop.human_truth_game[$id - 1].chat,
				function (value, key) {
					if ($rootScope.userEmail == value.email) {
						is_currentUser = "true";
					} else {
						is_currentUser = "false";
					}

					$message = {
						id: value.id,
						message: value.message,
						user: value.email,
						name: value.name,
						timestamp: value.created_at,
						is_reply: is_currentUser,
					};
					$scope.profilerChat.push($message);
					$timeout(function () {
						var scroller = document.getElementById("chatscreen");
						if(scroller) scroller.scrollTop = scroller.scrollHeight;
					});
				}
			);
			$timeout(function () {
				socket.emit("check", "first check");
			});
		};

		// User clicks input to edit
		$scope.focusInput = function (data) {
			$name =
				$localStorage.user.name + " " + $localStorage.user.surname.substr(0, 1);
			socket.emit("editing", {
				user: $rootScope.userEmail,
				name: $name,
				element: data,
				id: $scope.tensionID,
			});
		};

		// Send a chat message
		$scope.sendChatMessage = function (message, game, chatId, emit) {
			$scope.input = {
				message: message,
				chat_id: chatId,
			};
			dashboardFactory.chat($scope.input, game).then(
				function (result) {
					result.chats.is_reply = "true"; // IN8-150
					socket.emit(emit, result.chats);
					angular.element(document.getElementById(game)).val("");
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.scrollFunction = function ($id) {
			var scroller = document.getElementById($id);
			scroller.scrollTop = scroller.scrollHeight;
		};

		$scope.activeCheck = function () {
			if ($scope.statement_4_shown === "false") {
				data = {
					field: "statement_4_shown",
					id: $scope.tensionID,
				};
				socket.emit("showTension", data);
				return;
			}

			if ($scope.statement_5_shown === "false") {
				data = {
					field: "statement_5_shown",
					id: $scope.tensionID,
				};
				socket.emit("showTension", data);
				return;
			}

			if ($scope.statement_6_shown === "false") {
				data = {
					field: "statement_6_shown",
					id: $scope.tensionID,
				};
				socket.emit("showTension", data);
				return;
			}

			if ($scope.statement_7_shown === "false") {
				data = {
					field: "statement_7_shown",
					id: $scope.tensionID,
				};
				socket.emit("showTension", data);
			}
		};

		$scope.showTension = function () {
			$scope.isActive++;
			$scope.activeCheck();
		};

		// Save the tension answer
		$scope.sumbitTension = function ($field, $value) {
			$scope.input = {
				field: $field,
				value: $value,
				id: $scope.tensionID,
			};
			dashboardFactory.saveTension($scope.input).then(
				function (result) {
					$timeout(function () {
						socket.emit("newTension", result.HumanTruthGame);
					});
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.others = function () {
			if ($scope.showOthers) {
				$scope.showOthers = false;
			} else {
				$scope.showOthers = true;
			}
		};

		$scope.passThePen = function (data) {
			socket.emit("editing", {
				user: null,
				name: null,
				element: data,
				id: $scope.tensionID,
			});
		};

		$scope.showFacilitator = function ($group, $game) {

			dashboardFactory
				.notifyFacilitator($scope.currentWorkshopId, $group, $game)
				.then(
					function () {
						$rootScope.facilitatorPopUp("no redirect");

						// Broadcast lock for all other group participants
						let lockedData = {
							wid: $scope.currentWorkshopId,
							gid: $group,
							game: $game,
							method: "lock"
						}
						socket.emit("lockGroupAnswers", lockedData);

					},
					function (error) {
						console.log(error);
					}
				);
		};

		// Listen for answer locking broadcast
		socket.on("lockGroupAnswers", function (data) {
			// Is broadcast is applicable to current phase?
			if(data.game == "Human Truth Game"){
				// Is broadcast applicable to this workshop and group?
				if(parseInt(data.wid) == parseInt($scope.currentWorkshopId) && parseInt(data.gid) == parseInt($localStorage.progress.human_truth[0].group_id)){
					// Lock answers
					if(data.method == "lock"){
						console.log("[+] Recieved lock broadcast");
						$scope.locked = 1;
					}
					// Unlock answers
					if(data.method == "unlock"){
						console.log("[+] Recieved unlock broadcast");
						$scope.locked = 0;
					}
				}
			}
		});

		// Request that a Facilitator joins the chate
		$scope.requestFacilitatorJoinsChat = function ($group, $game){
			// Broadcast lock for all other group participants
			let payload = {
				wid: parseInt($scope.currentWorkshopId),
				gid: parseInt($group),
				game: $game,
			}
			socket.emit("requestFacilitatorJoinsChat", payload);

			sweetAlert.swal({
				title: "Requested",
				text: "The facilitator has been asked to join this group.",
				type: "warning",
			});

		}

		// User wants to unlock a input that another user is editing
		$scope.unlockInput = function (data) {
			$scope.previousEditor = null;
			// Show sweet alert with callback
			if (data === "statement_1_1") {
				$scope.previousEditor = $scope.statement_1_1_whoIsEditingName;
			}

			if (data === "statement_1_2") {
				$scope.previousEditor = $scope.statement_1_2_whoIsEditingName;
			}

			if (data === "statement_2_1") {
				$scope.previousEditor = $scope.statement_2_1_whoIsEditingName;
			}

			if (data === "statement_2_2") {
				$scope.previousEditor = $scope.statement_2_2_whoIsEditingName;
			}

			if (data === "statement_3_1") {
				$scope.previousEditor = $scope.statement_3_1_whoIsEditingName;
			}

			if (data === "statement_3_2") {
				$scope.previousEditor = $scope.statement_3_2_whoIsEditingName;
			}

			if (data === "statement_4_1") {
				$scope.previousEditor = $scope.statement_4_1_whoIsEditingName;
			}

			if (data === "statement_4_2") {
				$scope.previousEditor = $scope.statement_4_2_whoIsEditingName;
			}

			if (data === "statement_5_1") {
				$scope.previousEditor = $scope.statement_5_1_whoIsEditingName;
			}

			if (data === "statement_5_2") {
				$scope.previousEditor = $scope.statement_5_2_whoIsEditingName;
			}

			if (data === "statement_6_1") {
				$scope.previousEditor = $scope.statement_6_1_whoIsEditingName;
			}

			if (data === "statement_6_2") {
				$scope.previousEditor = $scope.statement_6_2_whoIsEditingName;
			}

			if (data === "statement_7_1") {
				$scope.previousEditor = $scope.statement_7_1_whoIsEditingName;
			}

			if (data === "statement_7_2") {
				$scope.previousEditor = $scope.statement_7_2_whoIsEditingName;
			}

			sweetAlert
				.swal(
					{
						title: "Warning",
						text:
							"Please note by unlocking you are removing " +
							$scope.previousEditor +
							" from editing.",
						type: "warning",
					},
					function () {}
				)
				.then(function () {
					$name =
						$localStorage.user.name +
						" " +
						$localStorage.user.surname.substr(0, 1);
					socket.emit("editing", {
						user: $rootScope.userEmail,
						name: $name,
						element: data,
						id: $scope.tensionID,
					});
				});
		};

		socket.on("recieveHumanGroup", function (data) {
			if (data.id == $localStorage.chosenWorkshop.id) {
				$scope.loadingScreen = true;
			}
		});

		socket.on("recieveTension", function (data) {
			/**
			 * Before overwriting tension answers cofirm tension ID match
			 * This prevents overwriting of other groups information
			 */
			if (data.id == $scope.tensionID) {
				$scope.statement_1_1 = data.statement_1_word_1;
				$scope.statement_1_2 = data.statement_1_word_2;
				$scope.statement_2_1 = data.statement_2_word_1;
				$scope.statement_2_2 = data.statement_2_word_2;
				$scope.statement_3_1 = data.statement_3_word_1;
				$scope.statement_3_2 = data.statement_3_word_2;
				$scope.statement_4_1 = data.statement_4_word_1;
				$scope.statement_4_2 = data.statement_4_word_2;
				$scope.statement_5_1 = data.statement_5_word_1;
				$scope.statement_5_2 = data.statement_5_word_2;
				$scope.statement_6_1 = data.statement_6_word_1;
				$scope.statement_6_2 = data.statement_6_word_2;
				$scope.statement_7_1 = data.statement_7_word_1;
				$scope.statement_7_2 = data.statement_7_word_2;
			}
		});

		socket.on("hiddenTension", function (data) {
			if (data.id == $scope.tensionID) {
				if (data.field === "statement_4_shown") {
					$scope.statement_4_shown = "false";
				}

				if (data.field === "statement_5_shown") {
					$scope.statement_5_shown = "false";
				}

				if (data.field === "statement_6_shown") {
					$scope.statement_6_shown = "false";
				}

				if (data.field === "statement_7_shown") {
					$scope.statement_7_shown = "false";
				}
			}
		});

		socket.on("shownTension", function (data) {
			if (data.id == $scope.tensionID) {
				if (data.field === "statement_4_shown") {
					$scope.statement_4_shown = "true";
				}

				if (data.field === "statement_5_shown") {
					$scope.statement_5_shown = "true";
				}

				if (data.field === "statement_6_shown") {
					$scope.statement_6_shown = "true";
				}

				if (data.field === "statement_7_shown") {
					$scope.statement_7_shown = "true";
				}
			}
		});

		socket.on("recieveTensionMessage", function (data) {
			if (data.human_truth_game[0].id == $scope.tensionID) {
				if ($rootScope.userEmail == data.email) {
					data.is_reply = "true";
				} else {
					data.is_reply = "false";
				}

				$message = {
					id: data.id,
					message: data.message,
					user: data.email,
					name: data.name,
					timestamp: data.created_at,
					is_reply: data.is_reply,
				};
				$scope.profilerChat.push($message);
				$timeout(function () {
					var scroller = document.getElementById("chatscreen");
					scroller.scrollTop = scroller.scrollHeight;
				});
			}
		});

		socket.on("recieveAdminJoined", function (data) {
			if (
				data.workshop == $scope.chosenWorkshop.id &&
				data.group == $localStorage.progress.human_truth[0].group_id &&
				data.game === "Human Truth"
			) {
				if (
					!$scope.groupMembers.some((item) =>
						item.email.includes(data.user.email)
					)
				) {
					$scope.groupMembers.push(data.user);
				}
				sweetAlert.swal({
					title: "Note",
					text: "The facilitator has joined the group.",
					type: "warning",
				});
			}
		});

		socket.on("groupApproved", function (data) {
			if (
				data.group == $localStorage.progress.human_truth[0].group_id &&
				data.phase === "Phase Five"
			) {
				$state.go("dashboard.brand-pov");
			}
		});

		// Socket to check if any inputs are locked by another user
		socket.on("editingCheck", function (data) {
			if (data === "check") {
				if ($scope.statement_1_1_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_1_1_whoIsEditing,
						name: $scope.statement_1_1_whoIsEditingName,
						element: "statement_1_1",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_1_2_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_1_2_whoIsEditing,
						name: $scope.statement_1_2_whoIsEditingName,
						element: "statement_1_2",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_2_1_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_2_1_whoIsEditing,
						name: $scope.statement_2_1_whoIsEditingName,
						element: "statement_2_1",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_2_2_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_2_2_whoIsEditing,
						name: $scope.statement_2_2_whoIsEditingName,
						element: "statement_2_2",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_3_1_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_3_1_whoIsEditing,
						name: $scope.statement_3_1_whoIsEditingName,
						element: "statement_3_1",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_3_2_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_3_2_whoIsEditing,
						name: $scope.statement_3_2_whoIsEditingName,
						element: "statement_3_2",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_4_1_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_4_1_whoIsEditing,
						name: $scope.statement_4_1_whoIsEditingName,
						element: "statement_4_1",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_4_2_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_4_2_whoIsEditing,
						name: $scope.statement_4_2_whoIsEditingName,
						element: "statement_4_2",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_5_1_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_5_1_whoIsEditing,
						name: $scope.statement_5_1_whoIsEditingName,
						element: "statement_5_1",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_5_2_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_5_2_whoIsEditing,
						name: $scope.statement_5_2_whoIsEditingName,
						element: "statement_5_2",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_6_1_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_6_1_whoIsEditing,
						name: $scope.statement_6_1_whoIsEditingName,
						element: "statement_6_1",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_6_2_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_6_2_whoIsEditing,
						name: $scope.statement_6_2_whoIsEditingName,
						element: "statement_6_2",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_7_1_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_7_1_whoIsEditing,
						name: $scope.statement_7_1_whoIsEditingName,
						element: "statement_7_1",
						id: $scope.tensionID,
					});
				}

				if ($scope.statement_7_2_whoIsEditing != "check") {
					socket.emit("editing", {
						user: $scope.statement_7_2_whoIsEditing,
						name: $scope.statement_7_2_whoIsEditingName,
						element: "statement_7_2",
						id: $scope.tensionID,
					});
				}
			}
		});

		// Socket to recieve who is editing an input
		socket.on("editor", function (data) {
			if (data.id == $scope.tensionID) {
				if (data.element === "statement_1_1") {
					$scope.statement_1_1_whoIsEditing = data.user;
					$scope.statement_1_1_whoIsEditingName = data.name;
					if ($scope.statement_1_1_whoIsEditing == null) {
						$scope.statement_1_1_isDisabled = false;
					} else {
						if ($scope.statement_1_1_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_1_1_isDisabled = true;
						} else {
							$scope.statement_1_1_isDisabled = false;
						}

						$scope.statement_1_1_editing = false;
					}
				}

				if (data.element === "statement_1_2") {
					$scope.statement_1_2_whoIsEditing = data.user;
					$scope.statement_1_2_whoIsEditingName = data.name;
					if ($scope.statement_1_2_whoIsEditing == null) {
						$scope.statement_1_2_isDisabled = false;
					} else {
						if ($scope.statement_1_2_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_1_2_isDisabled = true;
						} else {
							$scope.statement_1_2_isDisabled = false;
						}

						$scope.statement_1_2_editing = false;
					}
				}

				if (data.element === "statement_2_1") {
					$scope.statement_2_1_whoIsEditing = data.user;
					$scope.statement_2_1_whoIsEditingName = data.name;
					if ($scope.statement_2_1_whoIsEditing == null) {
						$scope.statement_2_1_isDisabled = false;
					} else {
						if ($scope.statement_2_1_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_2_1_isDisabled = true;
						} else {
							$scope.statement_2_1_isDisabled = false;
						}

						$scope.statement_2_1_editing = false;
					}
				}

				if (data.element === "statement_2_2") {
					$scope.statement_2_2_whoIsEditing = data.user;
					$scope.statement_2_2_whoIsEditingName = data.name;
					if ($scope.statement_2_2_whoIsEditing == null) {
						$scope.statement_2_2_isDisabled = false;
					} else {
						if ($scope.statement_2_2_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_2_2_isDisabled = true;
						} else {
							$scope.statement_2_2_isDisabled = false;
						}

						$scope.statement_2_2_editing = false;
					}
				}

				if (data.element === "statement_3_1") {
					$scope.statement_3_1_whoIsEditing = data.user;
					$scope.statement_3_1_whoIsEditingName = data.name;
					if ($scope.statement_3_1_whoIsEditing == null) {
						$scope.statement_3_1_isDisabled = false;
					} else {
						if ($scope.statement_3_1_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_3_1_isDisabled = true;
						} else {
							$scope.statement_3_1_isDisabled = false;
						}

						$scope.statement_3_1_editing = false;
					}
				}

				if (data.element === "statement_3_2") {
					$scope.statement_3_2_whoIsEditing = data.user;
					$scope.statement_3_2_whoIsEditingName = data.name;
					if ($scope.statement_3_2_whoIsEditing == null) {
						$scope.statement_3_2_isDisabled = false;
					} else {
						if ($scope.statement_3_2_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_3_2_isDisabled = true;
						} else {
							$scope.statement_3_2_isDisabled = false;
						}

						$scope.statement_3_2_editing = false;
					}
				}

				if (data.element === "statement_4_1") {
					$scope.statement_4_1_whoIsEditing = data.user;
					$scope.statement_4_1_whoIsEditingName = data.name;
					if ($scope.statement_4_1_whoIsEditing == null) {
						$scope.statement_4_1_isDisabled = false;
					} else {
						if ($scope.statement_4_1_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_4_1_isDisabled = true;
						} else {
							$scope.statement_4_1_isDisabled = false;
						}

						$scope.statement_4_1_editing = false;
					}
				}

				if (data.element === "statement_4_2") {
					$scope.statement_4_2_whoIsEditing = data.user;
					$scope.statement_4_2_whoIsEditingName = data.name;
					if ($scope.statement_4_2_whoIsEditing == null) {
						$scope.statement_4_2_isDisabled = false;
					} else {
						if ($scope.statement_4_2_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_4_2_isDisabled = true;
						} else {
							$scope.statement_4_2_isDisabled = false;
						}

						$scope.statement_4_2_editing = false;
					}
				}

				if (data.element === "statement_5_1") {
					$scope.statement_5_1_whoIsEditing = data.user;
					$scope.statement_5_1_whoIsEditingName = data.name;
					if ($scope.statement_5_1_whoIsEditing == null) {
						$scope.statement_5_1_isDisabled = false;
					} else {
						if ($scope.statement_5_1_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_5_1_isDisabled = true;
						} else {
							$scope.statement_5_1_isDisabled = false;
						}

						$scope.statement_5_1_editing = false;
					}
				}

				if (data.element === "statement_5_2") {
					$scope.statement_5_2_whoIsEditing = data.user;
					$scope.statement_5_2_whoIsEditingName = data.name;
					if ($scope.statement_5_2_whoIsEditing == null) {
						$scope.statement_5_2_isDisabled = false;
					} else {
						if ($scope.statement_5_2_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_5_2_isDisabled = true;
						} else {
							$scope.statement_5_2_isDisabled = false;
						}

						$scope.statement_5_2_editing = false;
					}
				}

				if (data.element === "statement_6_1") {
					$scope.statement_6_1_whoIsEditing = data.user;
					$scope.statement_6_1_whoIsEditingName = data.name;
					if ($scope.statement_6_1_whoIsEditing == null) {
						$scope.statement_6_1_isDisabled = false;
					} else {
						if ($scope.statement_6_1_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_6_1_isDisabled = true;
						} else {
							$scope.statement_6_1_isDisabled = false;
						}

						$scope.statement_6_1_editing = false;
					}
				}

				if (data.element === "statement_6_2") {
					$scope.statement_6_2_whoIsEditing = data.user;
					$scope.statement_6_2_whoIsEditingName = data.name;
					if ($scope.statement_6_2_whoIsEditing == null) {
						$scope.statement_6_2_isDisabled = false;
					} else {
						if ($scope.statement_6_2_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_6_2_isDisabled = true;
						} else {
							$scope.statement_6_2_isDisabled = false;
						}

						$scope.statement_6_2_editing = false;
					}
				}

				if (data.element === "statement_7_1") {
					$scope.statement_7_1_whoIsEditing = data.user;
					$scope.statement_7_1_whoIsEditingName = data.name;
					if ($scope.statement_7_1_whoIsEditing == null) {
						$scope.statement_7_1_isDisabled = false;
					} else {
						if ($scope.statement_7_1_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_7_1_isDisabled = true;
						} else {
							$scope.statement_7_1_isDisabled = false;
						}

						$scope.statement_7_1_editing = false;
					}
				}

				if (data.element === "statement_7_2") {
					$scope.statement_7_2_whoIsEditing = data.user;
					$scope.statement_7_2_whoIsEditingName = data.name;
					if ($scope.statement_7_2_whoIsEditing == null) {
						$scope.statement_7_2_isDisabled = false;
					} else {
						if ($scope.statement_7_2_whoIsEditing !== $rootScope.userEmail) {
							$scope.statement_7_2_isDisabled = true;
						} else {
							$scope.statement_7_2_isDisabled = false;
						}

						$scope.statement_7_2_editing = false;
					}
				}
			}
		});

		socket.on("groupAssigned", function (data) {
			if (data.data.id == $localStorage.chosenWorkshop.id) {
				dashboardFactory
					.collaborationHumanGroupResults($localStorage.chosenWorkshop.id, 0)
					.then(function (result) {
						$scope.humanGroups = result.groups;
					});
				dashboardFactory.progress().then(function (result) {
					$scope.progress = result.progress;
					$localStorage.progress = result.progress;
					$scope.tension = false;
					$scope.showDetails = true;
					if ($localStorage.progress.human_truth[0].group_id) {
						$scope.loadingScreen = true;
						$scope.choiceSetMethod(
							$localStorage.progress.human_truth[0].group_id
						);
						$scope.group = $localStorage.progress.human_truth[0].group_id;
						$window.scrollTo(0, 0);
					} else {
						$scope.loadingScreen = false;
					}
				});
			}
		});

		// Socket to receive change to an input from another user
		// This should be removed after confirming it is not used
		socket.on("profiler", function (data) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data.part === value && data.id == $scope.choiceSet.ids[key]) {
					$scope.choiceSet.messages[key] = data.message;
				}
			});
		});

	},
});
