angular.module('app').factory('groupFactory', function($http, $localStorage, __env) {
	return {
		/**
		 * Get group information by group id
		 */
		get: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.get');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'group/' + input.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end get */
		/**
		 * Fetch groups
		 */
		fetch: function() {
			if (__env.enableDebug) {
				console.log('groupFactory.fetch');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'workshops',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end fetch */

		/**
		 * Fetch group
		 */
		fetchSingle: function() {
			if (__env.enableDebug) {
				console.log('groupFactory.fetchSingle');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'workshop',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end fetchSingle */

		/**
		 * Fetch pending invites
		 */
		fetchPendingInvites: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.fetchPendingInvites');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'invites/' + input.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end fetch pending invites */

		/**
		 * Create a new group
		 */
		create: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.create');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'group',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end create */

		/**
		 * Delete a group
		 */
		delete: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.delete');
			}
			return $http({
				method: 'DELETE',
				url: __env.apiUrl + 'group/' + input.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end delete */

		/**
		 * Assign a group
		 */
		assign: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.assign');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'group/user',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end assign */

		/**
		 * Assign list of users to group
		 */
		assignList: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.assign');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'import/users',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end assign list */

		/**
		 * Assign a addNewUser
		 */
		addNewUser: function(input, $id) {
			if (__env.enableDebug) {
				console.log('groupFactory.addNewUser');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'add/group/user/' + $id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end addNewUser */

		/**
		 * Assign a content
		 */
		content: function($id) {
			if (__env.enableDebug) {
				console.log('groupFactory.content');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'workshop/content/' + $id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end content */

		/**
		 * content
		 */
		contentSubmit: function(input, $id) {
			if (__env.enableDebug) {
				console.log('groupFactory.content');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'workshop/content/save/' + $id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end content */

		/**
		 * question delete
		 */
		deleteQuestion: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.question delete');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'question/delete',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end question delete */

		/**
		 *  immersion question create
		 */
		createImmersionQuestion: function(input, id) {
			if (__env.enableDebug) {
				console.log('groupFactory.createImmersionQuestion ');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'immersion/question/create/' + id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end immersion question create */

		/**
		 *  ProductTruth create
		 */
		ProductTruthQuestion: function(input, id) {
			if (__env.enableDebug) {
				console.log('groupFactory.createImmersionQuestion ');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'productTruth/question/create/' + id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end ProductTruth create */

		/**
		 *  profiler question create
		 */
		createProfilerQuestion: function(input, id) {
			if (__env.enableDebug) {
				console.log('groupFactory.createProfilerQuestion ');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'profiler/question/create/' + id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end profiler question create */

		/**
		 *  pov video remove
		 */
		removePovVideo: function(id) {
			if (__env.enableDebug) {
				console.log('groupFactory.removePovVideo ');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'pov/video/remove/' + id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end pov video remove*/

		/**
		 *  pov video add
		 */
		addPovVideo: function(id, input) {
			if (__env.enableDebug) {
				console.log('groupFactory.addPovVideo ');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'pov/video/add/' + id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end pov video add */

		/**
		 *  Pov question create
		 */
		createPovQuestion: function(input, id) {
			if (__env.enableDebug) {
				console.log('groupFactory.createPovQuestion ');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'pov/question/create/' + id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end Pov question create */

		/**
		 * Get group information by group id
		 */
		export: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.export');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'immersion/export/' + input.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},

		deleteUsersWorkshops: function(input) {
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'users/workshop/remove/',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},

		saveMainQuestion: function(input) {
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'workshop/main/question',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		reorder: function(input) {
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'reorder/questions',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},

		/**
		 * Send mail to user
		 */
		sendMail: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.sendMail');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'mail/user',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		sendMailAll: function(input) {
			if (__env.enableDebug) {
				console.log('groupFactory.sendMailAll');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'mail/all/users',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
	};
});
