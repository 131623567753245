angular.module("app").component("brandPov", {
	templateUrl: "app/dashboard/game/05-brand-pov.html",
	controllerAs: "self",

	controller: function (
		$rootScope,
		$scope,
		$state,
		titleService,
		$timeout,
		$localStorage,
		$document,
		$window,
		dashboardFactory,
		showMyResultsService,
		utilService,
		socket,
		sweetAlert,
		__env,
		Upload
	) {
		if (__env.enableDebug) {
			console.log("brandPOVController");
		}

		// Set this to self to avoid conflicts
		const self = this;

		// Used to set the navigator
		$scope.currentGame = 4;

		$scope.choiceSet = {
			messages: [],
			editing: [],
			whoIsEditing: [],
			isDisabled: [],
			parts: [],
			questions: [],
			ids: [],
			names: [],
		};
		$scope.editing = false;
		$scope.isDisabled = false;
		$scope.whoIsEditing = "check";
		$scope.loadingScreen = true;
		$scope.loadingTshirtScreen = true;
		$scope.loadingGroupTshirtScreen = true;
		$scope.editingTshirt = false;
		$scope.isDisabledTshirt = false;
		$scope.whoIsEditingTshirt = "check";
		$scope.singleTshirt = null;
		$scope.voteTshirt = null;
		$scope.winnerTshirt = null;
		$scope.showOthers = false;
		$scope.answers = [];
		$scope.chatStatementMessage = null;

		// Set the basic controller data to be passed to title service
		titleService.data = {
			title: "Brand Pov",
			subtitle:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.",
		};

		// Don't show popup if phase has been completed
		self.showPopup = true;
		if (
			angular.isDefined($localStorage.progress) &&
			angular.isDefined($localStorage.progress.tshirt[0]) &&
			$localStorage.progress.tshirt[0].submitted
		) {
			if (__env.enableDebug) {
				console.log("This game has been completed");
			}
			self.showPopup = false;
		}

		$scope.navigateToGamePhase = function(gamePhaseName, completedPhaseNumber) {
			var gotoPhase = utilService.navigateToGamePhase(gamePhaseName, completedPhaseNumber);
			if (gotoPhase) {
				$state.go(gotoPhase);
			}
		};

		$scope.popupContent =
			'<div class="row">\
    <div class="col-md-12">\
     <p><strong>Deciding what our brand will stand for.</strong></p>\
	 <p>The brand point of view is the brand belief that makes it relevant to people and justifies its existence and importance in people\'s lives. It reflects what the brand stands for and the point of view the brand offers to cut through the human tension. It defines the higher-level contribution the brand brings to the lives of the people it serves.</p>\
	 \
	  <hr>\
      <p><strong>Looking back at Moment of Greatness</strong></p>\
      <p>In the following exercise we invite you to look back at that brand\'s moments of greatness, because it helps surface moments in which the brand has been able to engage people and make a difference in their lives. It might not always be clear why it was successful, but these moments always contain nuggets of meaning that can serve as inspiration for the point of view of the brand.</p>\
      </div>\
    </div>';

		$scope.htmlContent =
			'<div class="row">\
      <div class="col-md-12">\
      <p><strong>Deciding what our brand will stand for.</strong></p>\
      <p>The brand point of view is the brand belief that makes it relevant to people and justifies its existence and importance in people\'s lives. It reflects what the brand stands for and the point of view the brand offers to cut through the human tension. It defines the higher-level contribution the brand brings to the lives of the people it serves.</p>\
      \
      <hr>\
      <p><strong>Looking back at Moment of Greatness</strong></p>\
      <p> In the following exercise we invite you to look back at that brand\'s moments of greatness, because it helps surface moments in which the brand has been able to engage people and make a difference in their lives. It might not always be clear why it was successful, but these moments always contain nuggets of meaning that can serve as inspiration for the point of view of the brand.</p>\
	  \
      <hr>\
      <p><strong>What makes a good brand POV?</strong></p>\
	  <p>A good Brand POV is a clear statement that expresses a position that not everybody would agree with. It\'s important to stay away from truisms, because they are not playing around tensions and tend to leave people indifferent. Remember that the Brand POV needs to motivate the people the brand serves, as well as the marketeers that need to bring it to life. It must have lovers but also haters.</p>\
	  <p><a target="_blank" href="https://drive.google.com/file/d/1QbrjbPTvY0kAN5-ADQmJTO2khEVvpf5Z/view">Click here to view examples</a></p>\
	  \
      <hr>\
      <p><strong>Brand Manifesto and Brand T-shirt</strong></p>\
	  <p> For our group exercise we would like you to do two exercises working with your team.<br/>(1) Write a Brand manifesto: a story that tells the world what the brand believes in, fight for and the type of people that is calling upon.<br/>(2) Summarize the Brand Manifesto into a single-minded statement that you would put on a T-shirt that you would be proud to wear. Once you agreed on the statement you can use your creativity to build the T-shirt that best represent what you stand for. </p>\
      </div>\
    </div>';

		$scope.upload = "Upload Image";
		$scope.url = __env.apiUrl;

		$scope.currentWorkshopId = $localStorage.chosenWorkshop.id;
		$scope.splitWords = [];
		$scope.QandA = [];
		$scope.statements = [];
		$scope.answerSet = { answers: [] };
		$rootScope.userEmail = $localStorage.user.email;
		$scope.email = $rootScope.userEmail;
		$scope.answer2 = null;
		$scope.statement_pov_id = null;
		$scope.workshop = null;
		$scope.workshops = $localStorage.workshops;
		$scope.statement = null;
		$scope.statementChat = [];
		$scope.selectedWordsGame = [];

		$scope.sortableOptions = {
			containment: "#sortable-container",
		};

		// Get and Set available game results for show my results component
		showMyResultsService.getAvailableGameResults($scope.currentGame).then(function(myGameResults) {
			$scope.myResults = myGameResults;
			$scope.gameResultsToShow = {};
			$scope.currentResults = ($scope.myResults.length > 0) ? $scope.myResults[0].value : 'default';
			$scope.numberOfResults = showMyResultsService.getNumberOfShowingResults($scope.myResults);
		});

		// Set the function to update selected results on component
		$scope.updateSelectedResults = function(selectedResults) {
			if (angular.isDefined($scope.myResults)) {
				showMyResultsService.updateSelectedResults(selectedResults, $scope.myResults).then(function(result) {
					$scope.gameResultsToShow = result;
				});
			}
		};

		// Run when component is ready
		self.$onInit = function () {
			dashboardFactory.progress().then(function (result) {
				$scope.progress = result.progress;
				$localStorage.progress = result.progress;
				$scope.tshirt = result.progress.tshirt[0];
				// Get the users current workshop game
				$scope.game = JSON.parse(result.current_progress).current_game;

				var current_progress = JSON.parse(result.current_progress);
				var current_game = current_progress.current_game;

				if (current_game === 5) {
					$scope.singleTshirt = true;
					$scope.voteTshirt = false;
				}

				var gotoPhase =  utilService.navigateToGamePhase($scope.currentGame, $scope.game, $scope.currentGame);
				if (angular.isDefined(gotoPhase) && gotoPhase !== 'dashboard.brand-pov') {
					$state.go(gotoPhase, {}, { reload: true });
				} else {
					$localStorage.lastPlayedPhase = $scope.game;
				}
			});

			if ($localStorage.chosenWorkshop) {
				dashboardFactory.fetch().then(function (result) {
					if (self.showPopup) {
						sweetAlert.swal({
							html: $scope.popupContent,
							customClass: "instructions",
							showCancelButton: false,
							showConfirmButton: false,
							showCloseButton: true,
						});
					}

					$scope.chosenWorkshop = result.group;
					$localStorage.chosenWorkshop = result.group;
					$scope.optionValue = 1;
					$questionID = -1;

					angular.forEach(result.group.pov_question, function (value, key) {
						if (
							value.type === "video" ||
							value.type === "image" ||
							value.type === "youtube"
						) {
							$scope.answerSet.answers.push("none");
							$questionID++;
							$scope.mainQandA = [];
							$q = {
								question: value.question,
								questionID: $questionID,
								question_id: value.id,
								type: value.type,
								questions: $scope.mainQandA,
							};
							$scope.QandA.push($q);
							if (value.main.length) {
								angular.forEach(value.main, function (value2, key2) {
									$scope.answerSet.answers.push("");
									$questionID++;

									$q = {
										question: value2.question,
										questionID: $questionID,
										question_id: value2.id,
										type: value2.type,
									};
									$scope.mainQandA.push($q);
								});
							}
						}

						if (value.type === "ordered") {
							$scope.answerSet.answers.push("");
							$questionID++;
							$q = {
								question: value.question,
								questionID: $questionID,
								question_id: value.id,
								type: value.type,
							};
							$scope.QandA.push($q);
						}

						if (value.type === "words") {
							$scope.answerSet.answers.push("");
							$questionID++;
							$jsonObj = JSON.parse(value.answer_options);
							$options = [];
							angular.forEach($jsonObj, function (value, key) {
								$options.push({
									name: value.word,
									id: $questionID,
									color: value.color,
								});
							});
							$q = {
								question: value.question,
								questionID: $questionID,
								question_id: value.id,
								type: value.type,
							};
							$q.options = $options;
							$scope.QandA.push($q);
						}
					});

					angular.forEach(result.group.statement, function (value, key) {
						if (value.game === "pov") {
							$scope.statements.push(value);
						}
					});

					$timeout(function () {
						$scope.groupPov = null;
						if ($localStorage.progress.pov.length > 0) {
							if (
								$localStorage.progress.pov[0].group_id &&
								$scope.chosenWorkshop.phase_three_open === "true"
							) {
								$scope.progress = $localStorage.progress;
								$scope.groupPov = $localStorage.progress.pov[0].group_id;

								// Manage locking of content
								if(result.locks['brand-manifesto'] && result.locks['brand-manifesto'][$scope.groupPov]){
									$scope.locked = true;
								}

								$scope.collaborationPovGroupResults(
									$scope.chosenWorkshop.id,
									$scope.groupPov
								);
								$localStorage.statement_id =
									$scope.statements[
										$localStorage.progress.pov[0].group_id - 1
									].id;
								$scope.showDetails = true;
								$scope.choiceSet.messages.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1]
										.message1
								);
								$scope.choiceSet.editing.push("");
								$scope.choiceSet.whoIsEditing.push("check");
								$scope.choiceSet.isDisabled.push("");
								$scope.choiceSet.parts.push("message1");
								$scope.choiceSet.questions.push(
									$scope.chosenWorkshop.bp_statement_1
								);
								$scope.choiceSet.ids.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
								);
								$scope.choiceSet.names.push("");

								$scope.choiceSet.messages.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1]
										.message2
								);
								$scope.choiceSet.editing.push("");
								$scope.choiceSet.whoIsEditing.push("check");
								$scope.choiceSet.isDisabled.push("");
								$scope.choiceSet.parts.push("message2");
								$scope.choiceSet.questions.push(
									$scope.chosenWorkshop.bp_statement_2
								);
								$scope.choiceSet.ids.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
								);
								$scope.choiceSet.names.push("");

								$scope.choiceSet.messages.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1]
										.message3
								);
								$scope.choiceSet.editing.push("");
								$scope.choiceSet.whoIsEditing.push("check");
								$scope.choiceSet.isDisabled.push("");
								$scope.choiceSet.parts.push("message3");
								$scope.choiceSet.questions.push(
									$scope.chosenWorkshop.bp_statement_3
								);
								$scope.choiceSet.ids.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
								);
								$scope.choiceSet.names.push("");

								$scope.choiceSet.messages.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1]
										.message4
								);
								$scope.choiceSet.editing.push("");
								$scope.choiceSet.whoIsEditing.push("check");
								$scope.choiceSet.isDisabled.push("");
								$scope.choiceSet.parts.push("message4");
								$scope.choiceSet.questions.push(
									$scope.chosenWorkshop.bp_statement_4
								);
								$scope.choiceSet.ids.push(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
								);
								$scope.choiceSet.names.push("");
								$scope.choiceSet.messages[3] = $scope.choiceSet.questions[0];

								if ($scope.choiceSet.messages[0]) {
									$scope.choiceSet.messages[3] =
										$scope.choiceSet.messages[3] +
										" " +
										$scope.choiceSet.messages[0];
								} else {
									$scope.choiceSet.messages[3] =
										$scope.choiceSet.messages[3] + " ";
								}

								$scope.choiceSet.messages[3] =
									$scope.choiceSet.messages[3] +
									" " +
									$scope.choiceSet.questions[1];
								if ($scope.choiceSet.messages[1]) {
									$scope.choiceSet.messages[3] =
										$scope.choiceSet.messages[3] +
										" " +
										$scope.choiceSet.messages[1];
								} else {
									$scope.choiceSet.messages[3] =
										$scope.choiceSet.messages[3] + " ";
								}

								$scope.choiceSet.messages[3] =
									$scope.choiceSet.messages[3] +
									" " +
									$scope.choiceSet.questions[2];
								if ($scope.choiceSet.messages[2]) {
									$scope.choiceSet.messages[3] =
										$scope.choiceSet.messages[3] +
										" " +
										$scope.choiceSet.messages[2];
								} else {
									$scope.choiceSet.messages[3] =
										$scope.choiceSet.messages[3] + " ";
								}

								$scope.tshirtmanifest = $scope.choiceSet.messages[3];
								$scope.manifestoChat = [];
								angular.forEach(
									$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1]
										.chat,
									function (value, key) {
										if ($rootScope.userEmail == value.email) {
											is_currentUser = "true";
										} else {
											is_currentUser = "false";
										}

										$message = {
											id: value.id,
											message: value.message,
											name: value.name,
											user: value.email,
											timestamp: value.created_at,
											is_reply: is_currentUser,
										};
										$scope.manifestoChat.push($message);
										$timeout(function () {
											if (document.getElementById("chatscreen")) {
												var scroller = document.getElementById("chatscreen");
												scroller.scrollTop = scroller.scrollHeight;
											}
										});
									}
								);

								if ($scope.tshirt && $scope.tshirt.submitted == "true") {
									$scope.groupPov = "false";
									$scope.singleTshirt = "false";
									$scope.voteTshirt = true;

									dashboardFactory
										.getGroupTshirts(
											$localStorage.chosenWorkshop.id,
											$scope.tshirt.group_id
										)
										.then(
											function (result) {
												$scope.groupTshirts = result.tshirt;
												$scope.groupTshirtVoting = result.voting;
											},
											function (error) {
												console.log(error);
											}
										);
								}

								if (
									$scope.tshirt &&
									($scope.tshirt.submitted == "true",
									$scope.tshirt.voted == "true")
								) {
									$scope.groupPov = "false";
									$scope.singleTshirt = "false";
									$scope.voteTshirt = "false";
									$loadingVoteScreen = $scope.winnerTshirt = true;
									$scope.loadingTshirtScreen = false;
									$timeout(function () {
										dashboardFactory
											.winnercheck(
												$scope.tshirt.group_id,
												$localStorage.chosenWorkshop.id
											)
											.then(
												function (result) {
													socket.emit("winnerTshirt", result.tshirts[0]);
												},
												function (error) {
													console.log(error);
												}
											);
									}, 2000);
								}

								if ($scope.tshirt && $scope.tshirt.winner === "true") {
									$scope.groupPov = "false";
									$scope.singleTshirt = "false";
									$scope.voteTshirt = "false";
									$loadingVoteScreen = $scope.winnerTshirt = true;
									$scope.loadingTshirtScreen = true;
									socket.emit("winnerTshirt", $scope.tshirt);
								}
							} else {
								$scope.loadingScreen = false;
							}
						}
					});
				});
			} else {
				$scope.chosenWorkshop = null;
			}

			if ($localStorage.user) {
				$scope.user = $localStorage.user;
			}

			if ($localStorage.selectedWorkshop) {
				$scope.workshop = $localStorage.selectedWorkshop;
			} else {
				$scope.workshop = null;
			}

			if ($localStorage.statement_pov_id) {
				$scope.statement_pov_id = $localStorage.statement_pov_id;
			} else {
				$scope.statement_pov_id = null;
			}

			$scope.userGroups = $localStorage.groups;
		};

		$scope.next = function () {
			socket.emit("someOneSubmitted", "true");
			$localStorage.lastPlayedPhase = $scope.game;
			$state.go("dashboard.product-truth");
		};

		$scope.checkGroupAnswer = function () {
			var otherThanNull = $scope.choiceSet.messages.some(function (el) {
				return el == null;
			});
			return otherThanNull;
		};

		$scope.selectWords = function (questionID, word) {
			if ($scope.selectedWordsGame.indexOf(word) !== -1) {
				var index = $scope.selectedWordsGame.indexOf(word);
				$scope.selectedWordsGame.splice(index, 1);
			} else {
				$scope.selectedWordsGame[0] = word;
			}

			$scope.answerSet.answers[questionID] = word;
		};

		$scope.showMethod = function () {
			if ($scope.showDetails == true) {
				$scope.showDetails = false;
			} else {
				$scope.showDetails = true;
			}
		};

		$scope.rotateImage = function ($direction, $imageName) {
			dashboardFactory
				.ImageRotate($direction, $imageName)
				.then(function (result) {
					dashboardFactory.progress().then(
						function (result) {
							// Get the users current workshop game
							$scope.game = JSON.parse(result.current_progress).current_game;
							$scope.tshirt = result.progress.tshirt[0];
							$scope.tshirt.icon =
								result.progress.tshirt[0].icon + "?decache=" + Math.random();
						},
						function (error) {
							console.log(error);
						}
					);
				});
		};

		$scope.collaborationPovGroupResults = function ($wid, $gid) {
			$scope.categories = [];
			$scope.data = [];
			dashboardFactory
				.collaborationPovGroupResults($wid, 0)
				.then(function (result) {
					$scope.groupAnswers1 = result.groupOne.answer;
					$scope.brandStatements1 = result.groupOne.brandStatements;
					$scope.tshirts1 = result.groupOne.tshirts;
					$scope.categories1 = [];
					$scope.data1 = [];
					angular.forEach(result.groupOne.answer, function (value, key) {
						$scope.categories1.push(value.answer);
						$scope.data1.push(value.count);
					});

					$scope.groupAnswers2 = result.groupTwo.answer;
					$scope.brandStatements2 = result.groupTwo.brandStatements;
					$scope.tshirts2 = result.groupTwo.tshirts;
					$scope.categories2 = [];
					$scope.data2 = [];
					angular.forEach(result.groupTwo.answer, function (value, key) {
						$scope.categories2.push(value.answer);
						$scope.data2.push(value.count);
					});

					$scope.groupAnswers3 = result.groupThree.answer;
					$scope.brandStatements3 = result.groupThree.brandStatements;
					$scope.tshirts3 = result.groupThree.tshirts;
					$scope.categories3 = [];
					$scope.data3 = [];
					angular.forEach(result.groupThree.answer, function (value, key) {
						$scope.categories3.push(value.answer);
						$scope.data3.push(value.count);
					});

					$scope.groupAnswers4 = result.groupFour.answer;
					$scope.brandStatements4 = result.groupFour.brandStatements;
					$scope.tshirts4 = result.groupFour.tshirts;
					$scope.categories4 = [];
					$scope.data4 = [];
					angular.forEach(result.groupFour.answer, function (value, key) {
						$scope.categories4.push(value.answer);
						$scope.data4.push(value.count);
					});
				});
			dashboardFactory.groupMembers($wid, $gid, "pov").then(
				function (result) {
					$scope.groupMembers = result.members;
					$timeout(function () {});
				},
				function (error) {
					console.log(error);
				}
			);
			socket.emit("checkBrandManifesto", "First Check");
		};

		$scope.submitIndividualTshirt = function () {
			dashboardFactory.updateTshirtStatus($localStorage.chosenWorkshop.id).then(
				function (response) {
					dashboardFactory
						.getGroupTshirts(
							$localStorage.chosenWorkshop.id,
							$scope.tshirt.group_id
						)
						.then(
							function (result) {
								$scope.groupTshirts = result.tshirt;
								$scope.groupTshirtVoting = result.voting;
							},
							function (error) {
								console.log(error);
							}
						);
					// $scope.loadingGroupTshirtScreen = false;
					$scope.tshirt = response.tshirt;
					$scope.singleTshirt = "false";
					$scope.voteTshirt = true;
					$window.scrollTo(0, 0);
					socket.emit("newTshirtSubmitted", $scope.tshirt.group_id);
				},
				function (error) {
					console.log(error);
				}
			);
		};

		// User clicks input to edit
		$scope.focusInput = function (data, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data === value && id == $scope.choiceSet.ids[key]) {
					$name =
						$localStorage.user.name +
						" " +
						$localStorage.user.surname.substr(0, 1);
					// Broadcasts to to connected sockets that the user is editing an input
					socket.emit("editing", {
						user: $rootScope.userEmail,
						name: $name,
						element: data,
						id: $scope.choiceSet.ids[key],
					});
				}
			});
		};

		$scope.others = function () {
			if ($scope.showOthers) {
				$scope.showOthers = false;
			} else {
				$scope.showOthers = true;
			}
		};

		$scope.showFacilitator = function ($group, $game) {

			dashboardFactory
				.notifyFacilitator($scope.currentWorkshopId, $group, $game)
				.then(
					function () {
						$rootScope.facilitatorPopUp("no redirect");

						// Broadcast lock for all other group participants
						let lockedData = {
							wid: $scope.currentWorkshopId,
							gid: $group,
							game: $game,
							method: "lock"
						}
						socket.emit("lockGroupAnswers", lockedData);

					},
					function (error) {
						console.log(error);
					}
				);
		};

		// Listen for answer locking broadcast
		socket.on("lockGroupAnswers", function (data) {
			// Is broadcast is applicable to current phase?
			if(data.game == "Brand Manifesto"){
				// Is broadcast applicable to this workshop and group?
				if(parseInt(data.wid) == parseInt($scope.currentWorkshopId) && parseInt(data.gid) == parseInt($localStorage.progress.pov[0].group_id)){
					// Lock answers
					if(data.method == "lock"){
						console.log("[+] Recieved lock broadcast");
						$scope.locked = 1;
					}
					// Unlock answers
					if(data.method == "unlock"){
						console.log("[+] Recieved unlock broadcast");
						$scope.locked = 0;
					}
				}
			}
		});

		// Request that a Facilitator joins the chate
		$scope.requestFacilitatorJoinsChat = function ($group, $game){
			// Broadcast lock for all other group participants
			let payload = {
				wid: parseInt($scope.currentWorkshopId),
				gid: parseInt($group),
				game: $game,
			}
			socket.emit("requestFacilitatorJoinsChat", payload);

			sweetAlert.swal({
				title: "Requested",
				text: "The facilitator has been asked to join this group.",
				type: "warning",
			});

		}

		$scope.passThePen = function (data, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data === value && id == $scope.choiceSet.ids[key]) {
					// Broadcasts to to connected sockets that the user is editing an input
					socket.emit("editing", {
						user: null,
						name: null,
						element: data,
						id: $scope.choiceSet.ids[key],
					});
				}
			});
		};

		// User wants to unlock a input that another user is editing
		$scope.unlockInput = function (data, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data === value && id == $scope.choiceSet.ids[key]) {
					// Show sweet alert with callback
					sweetAlert
						.swal({
							title: "Warning",
							text:
								"Please note by unlocking you are removing " +
								$scope.choiceSet.names[key] +
								" from editing.",
							type: "warning",
						})
						.then(function () {
							$name =
								$localStorage.user.name +
								" " +
								$localStorage.user.surname.substr(0, 1);
							socket.emit("editing", {
								user: $rootScope.userEmail,
								name: $name,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						});
				}
			});
		};

		// Submit edit of input to the api
		$scope.submitManifesto = function ($part, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if ($part === value && id == $scope.choiceSet.ids[key]) {
					$scope.input = {
						part: $part,
						message: $scope.choiceSet.messages[key],
						profiler_id: $scope.choiceSet.ids[key],
						group_id: $localStorage.progress.pov[0].group_id,
					};
					dashboardFactory.saveManifesto($scope.input).then(
						function (result) {
							$timeout(function () {
								socket.emit("newProfiler", result.profiler);
							});
						},
						function (error) {
							console.log(error);
						}
					);
				}
			});

			$scope.input = {
				part: $scope.choiceSet.parts[3],
				message: $scope.choiceSet.messages[3],
				profiler_id: $scope.choiceSet.ids[3],
				group_id: $localStorage.progress.pov[0].group_id,
			};

			dashboardFactory.saveManifesto($scope.input).then(
				function (result) {
					$timeout(function () {
						socket.emit("newProfiler", result.profiler);
					});
				},
				function (error) {
					console.log(error);
				}
			);
		};

		// Get statement for group
		$scope.getStatement = function () {
			if ($localStorage.statement_pov_id) {
				// Get Statement
				dashboardFactory.fetchStatement($localStorage.statement_pov_id).then(
					function (result) {
						$localStorage.statement = result.statement;
						$scope.statement = result.statement;
						angular.forEach(result.statement.chat, function (value, key) {
							if ($rootScope.userEmail == value.email) {
								is_currentUser = "true";
							} else {
								is_currentUser = "false";
							}

							$message = {
								id: value.id,
								message: value.message,
								name: value.name,
								user: value.email,
								timestamp: value.created_at,
								is_reply: is_currentUser,
							};
							$scope.statementChat.push($message);
							$timeout(function () {
								var scroller = document.getElementById("chatscreen");
								scroller.scrollTop = scroller.scrollHeight;
							});
						});
						$timeout(function () {
							socket.emit("checkStatementPov", "first check");
						});
					},
					function (error) {
						console.log(error);
					}
				);
			}
		};
		$scope.getStatement();

		$scope.vote = function ($id) {
			$window.scrollTo(0, 0);
			dashboardFactory.vote($id).then(
				function (result) {
					$scope.groupTshirts = result.tshirt;
					$scope.groupTshirtVoting = result.voting;
					$scope.singleTshirt = "false";
					$scope.voteTshirt = "false";
					$loadingVoteScreen = true;
					$scope.winnerTshirt = true;
					$scope.loadingTshirtScreen = false;
					$timeout(function () {
						dashboardFactory
							.winnercheck(
								$scope.tshirt.group_id,
								$localStorage.chosenWorkshop.id
							)
							.then(
								function (result) {
									socket.emit("winnerTshirt", result.tshirts[0]);
								},
								function (error) {
									console.log(error);
								}
							);
					}, 2000);
				},
				function (error) {
					console.log(error);
				}
			);
		};

		// Send a chat message
		$scope.sendChatMessage = function (message, game, chatId, emit) {
			$scope.input = {
				message: message,
				chat_id: chatId,
			};
			dashboardFactory.chat($scope.input, game).then(
				function (result) {
					result.chats.is_reply = "true"; // IN8-150
					socket.emit(emit, result.chats);
					angular.element(document.getElementById(game)).val("");
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.sumbitTshirt = function ($message, $id) {
			if ($message) {
				$scope.input = {
					message: $message,
					tshirt_id: $id,
				};
				dashboardFactory.tshirt($scope.input).then(
					function (result) {
						$timeout(function () {
							$scope.tshirt = result.tshirt;
						});
					},
					function (error) {
						console.log(error);
					}
				);
			}
		};

		$scope.checkAnswers = function () {
			$count = 0;
			angular.forEach($scope.answerSet.answers, function (value, key) {
				if (value == "") {
					$count++;
				}
			});
			if ($count > 1) {
				return true;
			}
			return false;
		};

		$scope.tshirtColor = function ($id, $color) {
			$scope.input = {
				color: $color,
				tshirt_id: $id,
			};
			dashboardFactory.tshirt($scope.input).then(
				function (result) {
					$scope.tshirt = result.tshirt;
					var myEl = angular.element(document.querySelector("#tshitImage"));
					myEl.css("background-color", result.tshirt.color);
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.tshirtMessageColor = function ($id, $color) {
			$scope.input = {
				message_color: $color,
				tshirt_id: $id,
			};
			dashboardFactory.tshirt($scope.input).then(
				function (result) {
					$scope.tshirt = result.tshirt;
					var myEl = angular.element(document.querySelector("#tshitImage"));
					myEl.css("background-color", result.tshirt.color);
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.tshirtMessageSize = function ($id, $size) {
			$scope.input = {
				message_size: $size,
				tshirt_id: $id,
			};
			dashboardFactory.tshirt($scope.input).then(
				function (result) {
					$timeout(function () {
						$scope.tshirt = result.tshirt;
						var myEl = angular.element(document.querySelector("#tshitImage"));
						myEl.css("background-color", result.tshirt.color);
					});
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.next = function ($part) {
			dashboardFactory
				.updateProgress($part, $localStorage.chosenWorkshop.id)
				.then(
					function (result) {
						$localStorage.lastPlayedPhase = $scope.currentGame += 1;
						$state.go("dashboard.product-truth");
					},
					function (error) {
						console.log(error);
					}
				);
		};

		// Upload profile picture
		$scope.uploadIcon = function (file, $id) {
			$scope.upload = "Uploading Image...";
			Upload.upload({
				url: __env.apiUrl + "tshirtIcon/" + $scope.tshirt.id,
				headers: {
					Authorization: "Bearer " + $localStorage.token,
				},
				data: {
					upload: file,
				},
			}).then(
				function (resp) {
					$scope.tshirt.icon = resp.data.tshirt.icon + "?t=" + new Date();
					$scope.upload = "Upload Image";
				},
				function (resp) {},
				function (evt) {}
			);
		};

		$scope.tshirtIcon = function ($id, $icon) {
			$scope.input = {
				icon: $icon,
				tshirt_id: $id,
			};
			dashboardFactory.tshirt($scope.input).then(
				function (result) {
					$timeout(function () {});
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.updateWords = function () {
			$scope.splitWords = [];
			angular.forEach($scope.answerSet.answers, function (value, key) {
				if (!value) {
				} else if (value != "none") {
					$split = value.split(",");
					angular.forEach($split, function (value, key) {
						if ($scope.splitWords.indexOf(value) !== -1) {
						} else {
							$scope.splitWords.push(value);
						}
					});
				}
			});
		};

		// Checks if all answers are answered.
		$scope.answersCheck = function () {
			$scope.unanswered = false;
			angular.forEach($scope.answerSet.answers, function (value, key) {
				if (!value) {
					$scope.unanswered = true;
				}

				if ($scope.answerSet.answers.length == key + 1) {
					return $scope.unanswered;
				}
			});
		};

		// Submit statement
		$scope.sumbitStatement = function ($message) {
			$scope.input = {
				message: $message,
				statement_id: $localStorage.statement_pov_id,
			};
			dashboardFactory.saveStatement($scope.input).then(
				function (result) {
					$timeout(function () {
						socket.emit("newStatement", result.statement);
					});
				},
				function (error) {
					console.log(error);
				}
			);
		};

		// Collect data after question answered
		$scope.collectData = function () {

			$orderedanswers = $scope.splitWords.join();

			angular.forEach($scope.answerSet.answers, function (value, key) {
				if (!value) {
					$scope.answerSet.answers[key] = $orderedanswers;
				}
			});
			$timeout(function () {
				$scope.answersCheck();
			});

			$scope.answersCheck();
			// If all answered seperate into groups
			if (!$scope.unanswered) {
				// Post answer set to api
				$question = [];

				angular.forEach($scope.QandA, function (value, key) {

					if (value.type === "video" || value.type === "image" || value.type === "youtube") {
						$question.push(value);
						angular.forEach(value.questions, function (value1, key1) {
							$question.push(value1);
						});
					}

					if (value.type === "ordered") {
						$question.push(value);
					}

					if (value.type === "words") {
						$question.push(value);
					}
				});

				$scope.input = {
					workshop_id: $localStorage.chosenWorkshop.id,
					answers: $scope.answerSet,
					questions: $question,
				};

				dashboardFactory.pov($scope.input).then(
					function (result) {
						$timeout(function () {
							dashboardFactory.progress().then(
								function (result) {
									socket.emit("someOneSubmitted", "true");
									$scope.progress = result.progress;
									$localStorage.progress = result.progress;
									// Get the users current workshop game
									$scope.game = JSON.parse(result.current_progress).current_game;
									if ($scope.chosenWorkshop.phase_three_open === "false") {
										$scope.loadingScreen = false;
									}

									$scope.loadingScreen = false;
								},
								function (error) {
									console.log(error);
								}
							);
						});
					},
					function (error) {
						console.log(error);
					}
				);
			}
		};

		$scope.contactFacilitator = function () {
			Swal({
				title: "Message to facilitator",
				input: "textarea",
				inputPlaceholder: "Type your message here...",
				showCancelButton: true,
			}).then((result) => {
				if (result.value) {
					$input = {
						id: $localStorage.chosenWorkshop.id,
						message: result.value,
					};
					dashboardFactory.facilitatorMail($input).then(function (result) {
						Swal(result.message);
					});
				}
			});
		};

		// Socket for statement
		socket.on("recieveStatement", function (data) {
			if (data.id == $localStorage.statement_pov_id) {
				$scope.statement = data;
			}
		});

		// Socket for chat
		socket.on("recieveManifestoMessage", function (data) {
			if (
				data.manifesto[0].id ==
				$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
			) {
				if ($rootScope.userEmail == data.email) {
					data.is_reply = "true";
				} else {
					data.is_reply = "false";
				}

				$message = {
					id: data.id,
					message: data.message,
					name: data.name,
					user: data.email,
					timestamp: data.created_at,
					is_reply: data.is_reply,
				};
				$scope.manifestoChat.push($message);
				$timeout(function () {
					var scroller = document.getElementById("chatscreen");
					scroller.scrollTop = scroller.scrollHeight;
				});
			}
		});

		socket.on("recieveNewTshirt", function (data) {
			if (data == $scope.tshirt.group_id) {
				dashboardFactory
					.getGroupTshirts(
						$localStorage.chosenWorkshop.id,
						$scope.tshirt.group_id
					)
					.then(
						function (result) {
							console.log(result);
							$scope.groupTshirts = result.tshirt;
							$scope.groupTshirtVoting = result.voting;
						},
						function (error) {
							console.log(error);
						}
					);
			}
		});

		socket.on("winnerTshirtRecieve", function (data) {
			$window.scrollTo(0, 0);
			if (data.group_id == $scope.tshirt.group_id) {
				$scope.singleTshirt = "false";
				$scope.voteTshirt = "false";
				$loadingVoteScreen = true;
				$scope.winnerTshirt = true;
				$scope.loadingTshirtScreen = true;
				$scope.winningTshirt = data;
			}
		});

		// Socket to receive change to an input from another user
		socket.on("profiler", function (data) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data.part === value && data.id == $scope.choiceSet.ids[key]) {
					$scope.choiceSet.messages[key] = data.message;
					$scope.newMessage =
						$scope.choiceSet.questions[key] + $scope.choiceSet.messages[key];
				}
			});
			$scope.choiceSet.messages[3] = $scope.choiceSet.questions[0];
			if ($scope.choiceSet.messages[0]) {
				$scope.choiceSet.messages[3] =
					$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[0];
			} else {
				$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
			}

			$scope.choiceSet.messages[3] =
				$scope.choiceSet.messages[3] + " " + $scope.choiceSet.questions[1];
			if ($scope.choiceSet.messages[1]) {
				$scope.choiceSet.messages[3] =
					$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[1];
			} else {
				$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
			}

			$scope.choiceSet.messages[3] =
				$scope.choiceSet.messages[3] + " " + $scope.choiceSet.questions[2];
			if ($scope.choiceSet.messages[2]) {
				$scope.choiceSet.messages[3] =
					$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[2];
			} else {
				$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
			}

			$scope.tshirtmanifest = $scope.choiceSet.messages[3];
		});

		socket.on("recievePovGroup", function (data) {
			if (data.id == $localStorage.chosenWorkshop.id) {
				dashboardFactory.progress().then(
					function (result) {
						$scope.loadingScreen = true;
						// Get the users current workshop game
						$scope.game = JSON.parse(result.current_progress).current_game;
						var html1 = null;
						var html2 = null;
						var html3 = null;
						angular.forEach($scope.chosenWorkshop.brand_pov_data[0], function (
							value,
							key
						) {
							if (key === "heading_1") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_1 +
											"</h4>";
									} else {
										html1 =
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_1 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_1") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_1 +
											"</p>";
									} else {
										html1 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_1 +
											"</p>";
									}
								}
							}

							if (key === "heading_2") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_2 +
											"</h4>";
									} else {
										html1 =
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_2 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_2") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_2 +
											"</p>";
									} else {
										html1 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_2 +
											"</p>";
									}
								}
							}

							if (key === "heading_3") {
								if (value) {
									if (html2) {
										html2 =
											html2 +
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_3 +
											"</h4>";
									} else {
										html2 =
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_3 +
											"</h4>";
									}
								}
							}

							if (key === "video") {
								if (value) {
									if (html2) {
										html2 =
											html2 +
											'<video width="100%" src="' +
											$scope.url +
											$scope.chosenWorkshop.brand_pov_data[0].video +
											'" controls>';
									} else {
										html2 =
											'<video width="100%" src="' +
											$scope.url +
											$scope.chosenWorkshop.brand_pov_data[0].video +
											'" controls>';
									}
								}
							}

							if (key === "heading_4") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_4 +
											"</h4>";
									} else {
										html3 =
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_4 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_4") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_4 +
											"</p>";
									} else {
										html3 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_4 +
											"</p>";
									}
								}
							}

							if (key === "heading_5") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_5 +
											"</h4>";
									} else {
										html3 =
											'<h4 style="color:#99ca43;">' +
											$scope.chosenWorkshop.brand_pov_data[0].heading_5 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_5") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_5 +
											"</p>";
									} else {
										html3 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.brand_pov_data[0].paragraph_5 +
											"</p>";
									}
								}
							}
						});

						$timeout(function () {
							if (self.showPopup) {
								sweetAlert.swal({
									html: $scope.popupContent,
									customClass: "instructions",
									showCancelButton: false,
									showConfirmButton: false,
									showCloseButton: true,
								});
							}
						});

						$scope.progress = result.progress;
						$scope.tshirt = result.progress.tshirt[0];
						$localStorage.progress = result.progress;
						if ($localStorage.progress.pov[0].group_id) {
							$localStorage.statement_id =
								$scope.statements[
									$localStorage.progress.pov[0].group_id - 1
								].id;
							$scope.groupPov = $localStorage.progress.pov[0].group_id;
							$scope.progress = $localStorage.progress;
							$scope.collaborationPovGroupResults($scope.chosenWorkshop.id, 0);
						}
					},
					function (error) {
						console.log(error);
					}
				);
			}
		});

		socket.on("groupAssigned", function (data) {
			if (data.data.id == $localStorage.chosenWorkshop.id) {
				if (!$scope.loadingScreen) {
					dashboardFactory.fetch().then(
						function (result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							dashboardFactory.progress().then(
								function (result) {
									$scope.progress = result.progress;
									$localStorage.progress = result.progress;
									$scope.groupPov = null;
									// Get the users current workshop game
									$scope.game = JSON.parse(result.current_progress).current_game;
									if (result.progress.pov.length > 0) {
										if (
											$localStorage.progress.pov[0].group_id &&
											$scope.chosenWorkshop.phase_three_open === "true"
										) {
											$scope.loadingScreen = true;
											$scope.progress = $localStorage.progress;
											$scope.groupPov = $localStorage.progress.pov[0].group_id;
											$scope.collaborationPovGroupResults(
												$scope.chosenWorkshop.id,
												$scope.groupPov
											);
											$localStorage.statement_id =
												$scope.statements[
													$localStorage.progress.pov[0].group_id - 1
												].id;
											$scope.showDetails = true;

											$scope.choiceSet.messages.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].message1
											);
											$scope.choiceSet.editing.push("");
											$scope.choiceSet.whoIsEditing.push("check");
											$scope.choiceSet.isDisabled.push("");
											$scope.choiceSet.parts.push("message1");
											$scope.choiceSet.questions.push(
												$scope.chosenWorkshop.bp_statement_1
											);
											$scope.choiceSet.ids.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].id
											);
											$scope.choiceSet.names.push("");

											$scope.choiceSet.messages.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].message2
											);
											$scope.choiceSet.editing.push("");
											$scope.choiceSet.whoIsEditing.push("check");
											$scope.choiceSet.isDisabled.push("");
											$scope.choiceSet.parts.push("message2");
											$scope.choiceSet.questions.push(
												$scope.chosenWorkshop.bp_statement_2
											);
											$scope.choiceSet.ids.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].id
											);
											$scope.choiceSet.names.push("");

											$scope.choiceSet.messages.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].message3
											);
											$scope.choiceSet.editing.push("");
											$scope.choiceSet.whoIsEditing.push("check");
											$scope.choiceSet.isDisabled.push("");
											$scope.choiceSet.parts.push("message3");
											$scope.choiceSet.questions.push(
												$scope.chosenWorkshop.bp_statement_3
											);
											$scope.choiceSet.ids.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].id
											);
											$scope.choiceSet.names.push("");

											$scope.choiceSet.messages.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].message4
											);
											$scope.choiceSet.editing.push("");
											$scope.choiceSet.whoIsEditing.push("check");
											$scope.choiceSet.isDisabled.push("");
											$scope.choiceSet.parts.push("message4");
											$scope.choiceSet.questions.push(
												$scope.chosenWorkshop.bp_statement_4
											);
											$scope.choiceSet.ids.push(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].id
											);
											$scope.choiceSet.names.push("");

											$scope.choiceSet.messages[3] =
												$scope.choiceSet.questions[0];
											if ($scope.choiceSet.messages[0]) {
												$scope.choiceSet.messages[3] =
													$scope.choiceSet.messages[3] +
													" " +
													$scope.choiceSet.messages[0];
											} else {
												$scope.choiceSet.messages[3] =
													$scope.choiceSet.messages[3] + " ";
											}

											$scope.choiceSet.messages[3] =
												$scope.choiceSet.messages[3] +
												" " +
												$scope.choiceSet.questions[1];
											if ($scope.choiceSet.messages[1]) {
												$scope.choiceSet.messages[3] =
													$scope.choiceSet.messages[3] +
													" " +
													$scope.choiceSet.messages[1];
											} else {
												$scope.choiceSet.messages[3] =
													$scope.choiceSet.messages[3] + " ";
											}

											$scope.choiceSet.messages[3] =
												$scope.choiceSet.messages[3] +
												" " +
												$scope.choiceSet.questions[2];
											if ($scope.choiceSet.messages[2]) {
												$scope.choiceSet.messages[3] =
													$scope.choiceSet.messages[3] +
													" " +
													$scope.choiceSet.messages[2];
											} else {
												$scope.choiceSet.messages[3] =
													$scope.choiceSet.messages[3] + " ";
											}

											$scope.manifestoChat = [];
											angular.forEach(
												$localStorage.chosenWorkshop.manifesto[
													$scope.groupPov - 1
												].chat,
												function (value, key) {
													if ($rootScope.userEmail == value.email) {
														is_currentUser = "true";
													} else {
														is_currentUser = "false";
													}

													$message = {
														id: value.id,
														message: value.message,
														name: value.name,
														user: value.email,
														timestamp: value.created_at,
														is_reply: is_currentUser,
													};
													$scope.manifestoChat.push($message);
												}
											);

											$scope.tshirtChat = [];
											if (
												angular.isDefined(
													$localStorage.chosenWorkshop.tshirt[
														$scope.groupPov - 1
													]
												)
											) {
												angular.forEach(
													$localStorage.chosenWorkshop.tshirt[
														$scope.groupPov - 1
													].chat,
													function (value) {
														if ($rootScope.userEmail == value.email) {
															is_currentUser = "true";
														} else {
															is_currentUser = "false";
														}

														$message = {
															id: value.id,
															message: value.message,
															name: value.name,
															user: value.email,
															timestamp: value.created_at,
															is_reply: is_currentUser,
														};
														$scope.tshirtChat.push($message);
														$timeout(function () {
															var scroller = document.getElementById(
																"chatscreen"
															);
															scroller.scrollTop = scroller.scrollHeight;
														});
													}
												);
											}

											$scope.loadingScreen = true;
											$window.scrollTo(0, 0);
											var html1 = null;
											var html2 = null;
											var html3 = null;
											angular.forEach(
												$scope.chosenWorkshop.brand_pov_data[0],
												function (value, key) {
													if (key === "heading_1") {
														if (value) {
															if (html1) {
																html1 =
																	html1 +
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_1 +
																	"</h4>";
															} else {
																html1 =
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_1 +
																	"</h4>";
															}
														}
													}

													if (key === "paragraph_1") {
														if (value) {
															if (html1) {
																html1 =
																	html1 +
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_1 +
																	"</p>";
															} else {
																html1 =
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_1 +
																	"</p>";
															}
														}
													}

													if (key === "heading_2") {
														if (value) {
															if (html1) {
																html1 =
																	html1 +
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_2 +
																	"</h4>";
															} else {
																html1 =
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_2 +
																	"</h4>";
															}
														}
													}

													if (key === "paragraph_2") {
														if (value) {
															if (html1) {
																html1 =
																	html1 +
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_2 +
																	"</p>";
															} else {
																html1 =
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_2 +
																	"</p>";
															}
														}
													}

													if (key === "heading_3") {
														if (value) {
															if (html2) {
																html2 =
																	html2 +
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_3 +
																	"</h4>";
															} else {
																html2 =
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_3 +
																	"</h4>";
															}
														}
													}

													if (key === "video") {
														if (value) {
															if (html2) {
																html2 =
																	html2 +
																	'<video width="100%" src="' +
																	$scope.url +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.video +
																	'" controls>';
															} else {
																html2 =
																	'<video width="100%" src="' +
																	$scope.url +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.video +
																	'" controls>';
															}
														}
													}

													if (key === "heading_4") {
														if (value) {
															if (html3) {
																html3 =
																	html3 +
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_4 +
																	"</h4>";
															} else {
																html3 =
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_4 +
																	"</h4>";
															}
														}
													}

													if (key === "paragraph_4") {
														if (value) {
															if (html3) {
																html3 =
																	html3 +
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_4 +
																	"</p>";
															} else {
																html3 =
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_4 +
																	"</p>";
															}
														}
													}

													if (key === "heading_5") {
														if (value) {
															if (html3) {
																html3 =
																	html3 +
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_5 +
																	"</h4>";
															} else {
																html3 =
																	'<h4 style="color:#99ca43;">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.heading_5 +
																	"</h4>";
															}
														}
													}

													if (key === "paragraph_5") {
														if (value) {
															if (html3) {
																html3 =
																	html3 +
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_5 +
																	"</p>";
															} else {
																html3 =
																	'<p class="m-0">' +
																	$scope.chosenWorkshop.brand_pov_data[0]
																		.paragraph_5 +
																	"</p>";
															}
														}
													}
												}
											);
											$timeout(function () {
												$scope.loadingScreen = true;
											});
										} else {
											$scope.loadingScreen = false;
										}
									}
								},
								function (error) {
									console.log(error);
								}
							);
						},
						function (error) {
							console.log(error);
						}
					);
				}
			}
		});

		// Socket to check if any inputs are locked by another user
		socket.on("editingCheckStatementPov", function (data) {
			console.log("checking");
			if (data === "check") {
				if ($scope.whoIsEditing != "check") {
					socket.emit("editingStatementPov", {
						user: $scope.whoIsEditing,
						id: $localStorage.statement.id,
					});
				}
			}
		});

		// Socket to recieve who is editing an input
		socket.on("editorStatementPov", function (data) {
			if (data.id == $localStorage.statement.id) {
				$scope.whoIsEditing = data.user;
				if ($scope.whoIsEditing == null) {
					$scope.isDisabled = false;
				} else {
					if ($scope.whoIsEditing !== $rootScope.userEmail) {
						$scope.isDisabled = true;
					} else {
						$scope.isDisabled = false;
					}

					$scope.editing = false;
				}
			}
		});

		socket.on("recieveAdminJoined", function (data) {
			if (
				data.workshop == $scope.chosenWorkshop.id &&
				data.group == $localStorage.progress.pov[0].group_id &&
				data.game === "Brand POV"
			) {
				if (
					!$scope.groupMembers.some((item) =>
						item.email.includes(data.user.email)
					)
				) {
					$scope.groupMembers.push(data.user);
				}

				sweetAlert
					.swal(
						{
							title: "Note",
							text: "The facilitator has joined the group.",
							type: "warning",
						},
						function () {}
					)
					.then(function () {});
			}
		});

		// Socket to recieve who is editing an input
		socket.on("editor", function (data) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data.element === value && data.id == $scope.choiceSet.ids[key]) {
					$scope.choiceSet.whoIsEditing[key] = data.user;
					$scope.choiceSet.names[key] = data.name;
					if ($scope.choiceSet.whoIsEditing[key] == null) {
						$scope.choiceSet.isDisabled[key] = false;
					} else {
						if ($scope.choiceSet.whoIsEditing[key] !== $rootScope.userEmail) {
							$scope.choiceSet.isDisabled[key] = true;
						} else {
							$scope.choiceSet.isDisabled[key] = false;
						}

						$scope.choiceSet.editing[key] = false;
					}
				}
			});
		});

		socket.on("editingBrandManifesto", function (data) {
			if (data === "check") {
				angular.forEach($scope.choiceSet.whoIsEditing, function (value, key) {
					if (value != "check") {
						socket.emit("editing", {
							user: value,
							name: $scope.choiceSet.names[key],
							element: $scope.choiceSet.parts[key],
							id: $scope.choiceSet.ids[key],
						});
					}
				});
			}
		});

		socket.on("groupApproved", function (data) {
			if (data.group == $scope.groupPov && data.phase === "Phase Three") {
				$window.scrollTo(0, 0);
				$scope.singleTshirt = true;
				$scope.groupPov = "false";
				$scope.voteTshirt = false;
			}
		});
	},
});
