angular.module('app').config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
	$locationProvider.html5Mode(true).hashPrefix('!');

	/* Setup route states */
	/* Default */
	$stateProvider
		.state('login', {
			url: '/login',
			component: 'login',
			restricted: false,
		})
		.state('reset', {
			url: '/reset',
			component: 'reset',
			restricted: false,
		})
		.state('password', {
			url: '/password?token',
			component: 'password',
			restricted: false,
		})
		.state('dashboard', {
			abstract: true,
			url: '/',
			component: 'dashboard',
			restricted: true,
		})
		.state('dashboard.dashboard', {
			url: 'dashboard',
			component: 'dashboard.dashboard',
			// Resolve below before rendering view
			restricted: true,
		})
		.state('logout', {
			url: 'logout',
			resolve: {
				groups: function(authFactory) {
					return authFactory.logout();
				},
			},
		});
	/* End Default */

	/* Group */
	$stateProvider
		.state('dashboard.assign-group', {
			url: 'assign-group',
			component: 'assignGroup',
			// Resolve below before rendering view
			resolve: {
				// Get the groups
				groups: function(groupFactory) {
					return groupFactory.fetch().then(
						function(result) {
							return result.group;
						},
						function(error) {
							console.log(error);
						}
					);
				},
			},
			restricted: true,
		})
		.state('dashboard.delete-group', {
			url: 'delete-group',
			component: 'deleteGroup',
			// Resolve below before rendering view
			resolve: {
				// Get the groups
				groups: function(groupFactory) {
					return groupFactory.fetch().then(
						function(result) {
							return result.group;
						},
						function(error) {
							return error;
						}
					);
				},
			},
			restricted: true,
		});
	/* End Group */

	/* Group Results */
	$stateProvider
		.state('dashboard.group-dashboard', {
			url: 'group-dashboard',
			component: 'groupDashboard',
			restricted: true,
		})
		.state('dashboard.game-copy', {
			url: 'game-copy',
			component: 'gameCopy',
			restricted: true,
		})
		.state('dashboard.immersion-debrief', {
			url: 'immersion-debrief',
			component: 'immersionDebrief',
			restricted: true,
		})
		.state('dashboard.product-truth', {
			url: 'product-truth',
			component: 'productTruth',
			restricted: true,
		})
		.state('dashboard.brand-pov', {
			url: 'brand-pov',
			component: 'brandPov',
			restricted: true,
		})
		.state('dashboard.group-organiser', {
			url: 'group-organiser',
			component: 'groupOrganiser',
		})
		.state('dashboard.group-moderation', {
			url: 'group-moderation',
			component: 'workshopModeration',
		})
		.state('dashboard.pyramid', {
			url: 'pyramid',
			component: 'pyramid',
			restricted: true,
		})
		.state('dashboard.completed', {
			url: 'completed',
			component: 'completed',
			restricted: true,
		})
		.state('dashboard.pws', {
			url: 'people-we-serve',
			component: 'peopleWeServe',
			restricted: true,
		})
		.state('dashboard.human-truth', {
			url: 'human-truth',
			component: 'dashboard.humanTruth',
			restricted: true,
		})
		.state('dashboard.create-group', {
			url: 'create-group',
			component: 'createGroup',
			restricted: true,
		})
		.state('dashboard.group-manage', {
			url: 'group-manage',
			component: 'manageGroup',
			params: {
				group: null,
			},
			restricted: true,
		})
		.state('dashboard.group-results', {
			url: 'group-results',
			component: 'groupResults',
			params: {
				group: null,
			},
			// Resolve below before rendering view
			restricted: true,
		})
		.state('dashboard.manage-content', {
			url: 'manage-content/:param1',
			component: 'manageContent',
			// Resolve below before rendering view
			restricted: true,
		});
	/* End Group Results */

	// Send to login if route is not found
	$urlRouterProvider.otherwise('/dashboard');
}
