/* eslint-disable no-multi-str */
angular.module("app").component("immersionDebrief", {
	templateUrl: "app/dashboard/game/01-immersion.html",
	controllerAs: "self",

	controller: function (
		$rootScope,
		$scope,
		$state,
		$timeout,
		$localStorage,
		$window,
		dashboardFactory,
		showMyResultsService,
		utilService,
		socket,
		sweetAlert,
		__env,
		Upload,
		$http
	) {
		if (__env.enableDebug) {
			console.log("immersionDebriefController");
		}

		if (
			$localStorage.role === "super admin" ||
			$localStorage.role === "facilitator"
		) {
			$state.go("dashboard.group-manage");
		}

		// Set this to self to avoid conflicts
		const self = this;

		// Used to set the navigator
		$scope.currentGame = 1;

		// Don't show popup if phase has been completed
		self.showPopup = true;
		if (
			angular.isDefined($localStorage.progress) && angular.isDefined($localStorage.progress.people_we_serve[0]) &&
			$localStorage.progress.people_we_serve[0].completed
		) {
			if (__env.enableDebug) {
				console.log("This game has been completed");
			}
			self.showPopup = false;
		}

		$scope.goToState = $state.go.bind($state);
		$scope.canProgress = function(){
			// Check if user has completed phase
			if ($localStorage.progress.people_we_serve[0].completed == 1) {
				return true;
			} else {
				return false;
			}
		};

		$scope.navigateToGamePhase = function(gamePhaseName, completedPhaseNumber) {
			var result = utilService.navigateToGamePhase(gamePhaseName, completedPhaseNumber);
			if (result) {
				$state.go(result);
			}
		};

		// Popup Content
		$scope.popupContent =
			'<div class="row">\
				<div class="col-md-12">\
					<p><strong>What’s an immersion?</strong></p>\
					<p>The immersions are in-depth, person-to-person conversations that the team conducts themselves, without the presence of a moderator. The process is designed to create real intimacy, and is a candid, first-hand experience of deep connection with the people that the brand serves.</p>\
					\
					<hr>\
					<p><strong>What to expect in your immersion:</strong></p>\
					<p>Whether you are doing it in-home or remotely, the category and brand are always discussed in the last half hour of these 2-hour conversations. The front half of the conversation will be mainly geared towards human themes relating to the category or brand we work on but with a broader human and emotional relevance (i.e. self-image, masculinity, parenting). The themes explored reflect aspects of consumers\' lives where they experience tensions, and where our brands can provide clear points of view and solutions.</p>\
					\
					<hr>\
					<p><strong>Finished with your Immersion? Capture your debrief.</strong></p>\
					<p>Once you have finished your immersion, you will capture the experience in the Immersion Debrief here. You will want to capture thoughts, feelings, and other learnings from the experience. Be sure to keep the learnings elevated to the human and life level, and not only the category and brand level. This is really about what makes these people tick.</p>\
					<p><a target="_blank" href="https://drive.google.com/file/d/1Pk3S0RB0W3H0bxxgsbpVeGT5pZ-12_i9/view">Click here to view examples</a></p>\
				</div>\
			</div>';

		$scope.showOthers = false;
		$scope.tension = false;

		$rootScope.userEmail = $localStorage.user.email;
		$scope.workshop = null;
		$scope.workshops = $localStorage.workshops;
		$scope.dataset = $localStorage.dataset;

		$scope.language = $localStorage.language;
		$rootScope.language = $localStorage.language;
		$scope.uploadPp = "Upload";
		$scope.upload = "Upload";
		$scope.QandA = [];
		$scope.button = "Submit";
		$scope.url = __env.apiUrl;

		$scope.played = false;
		$scope.QandA = [];
		$scope.url = __env.apiUrl;
		$scope.statements = [];
		$scope.answerSet = { answers: [] };
		$scope.progress = $localStorage.progress;
		$scope.answerC = null;
		$scope.answerA = null;
		$scope.answerB = null;

		// Get and Set available game results for show my results component
		showMyResultsService.getAvailableGameResults($scope.currentGame).then(function(myGameResults) {
			$scope.myResults = myGameResults;
			$scope.gameResultsToShow = {};
			$scope.currentResults = ($scope.myResults.length > 0) ? $scope.myResults[0].value : 'default';
			$scope.numberOfResults = showMyResultsService.getNumberOfShowingResults($scope.myResults);
		});

		// Set the function to update selected results on component
		$scope.updateSelectedResults = function(selectedResults) {
			if (angular.isDefined($scope.myResults)) {
				showMyResultsService.updateSelectedResults(selectedResults, $scope.myResults).then(function(result) {
					$scope.gameResultsToShow = result;
				});
			}
		};

		// Run when component is ready
		self.$onInit = function () {
			if ($localStorage.chosenWorkshop) {
				$scope.chosenWorkshop = $localStorage.chosenWorkshop;
				dashboardFactory.fetch().then(
					function (result) {
						$scope.chosenWorkshop = result.group;
						$localStorage.chosenWorkshop = result.group;
						$scope.optionValue = 1;

						if ($scope.progress.people_we_serve.length === 1) {
							$numanswers = $scope.progress.people_we_serve[0].answer.length;
							$numquestions = $scope.chosenWorkshop.immersion_question.length;

							if ($numanswers >= $numquestions) {
								$scope.played = true;
								$scope.tension = true;
							}

							$timeout(function () {
								if (self.showPopup) {
									sweetAlert.swal({
										html: $scope.popupContent,
										customClass: "instructions",
										showCancelButton: false,
										showConfirmButton: false,
										showCloseButton: true,
									});
								}
							});
						} else {
							$numanswers = 0;

							$timeout(function () {
								if (self.showPopup) {
									sweetAlert.swal({
										html: $scope.popupContent,
										customClass: "instructions",
										showCancelButton: false,
										showConfirmButton: false,
										showCloseButton: true,
									});
								}
							});
						}

						$numquestions = $scope.chosenWorkshop.immersion_question.length;
						$timeout(function () {
							$scope.group = null;

							if ($numanswers > $numquestions) {
								$scope.played = true;
								$scope.tension = true;
							}

							angular.forEach(
								$scope.chosenWorkshop.immersion_question,
								function (value, key) {
									if ($scope.progress.people_we_serve.length > 0) {
										$qid = value.id;

										$found = false;
										for (
											var i = 0;
											i < $scope.progress.people_we_serve[0].answer.length;
											i++
										) {
											if (
												$scope.progress.people_we_serve[0].answer[i]
													.question_id == $qid
											) {
												$found = $scope.progress.people_we_serve[0].answer[i];
											}
										}

										if ($found) {
											if (
												value.type === "image" &&
												value.question ===
													"Start by Uploading a profile picture of the person you met"
											) {
												$found.answer =
													$found.answer + "?decache=" + Math.random();
											}

											$q = {
												question: value.question,
												questionID: key + 1,
												question_id: value.id,
												type: value.type,
												answer: $found.answer,
											};
										} else {
											$q = {
												question: value.question,
												questionID: key + 1,
												question_id: value.id,
												type: value.type,
												answer: "",
											};
										}
									} else {
										$q = {
											question: value.question,
											questionID: key + 1,
											question_id: value.id,
											type: value.type,
											answer: "",
										};
									}

									$scope.answerSet.answers.push("");

									$scope.QandA.push($q);
								}
							);
						});
					},
					function (error) {
						console.log(error);
					}
				);
			} else {
				$scope.chosenWorkshop = null;
			}

			// Check the users current progress
			dashboardFactory.progress().then(function (result) {
				$scope.progress = result.progress;
				$localStorage.progress = result.progress;

				// Get the users current workshop game
				$scope.game = JSON.parse(result.current_progress).current_game;
				$localStorage.lastPlayedPhase = $scope.game;
				var gotoPhase = utilService.navigateToGamePhase($scope.currentGame, $scope.game, $scope.currentGame);
				if (angular.isDefined(gotoPhase) && gotoPhase !== 'dashboard.immersion-debrief') {
					$state.go(gotoPhase, {}, { reload: true });
				}
				
				if (
					angular.isDefined(result.progress)  &&
					0 < result.progress.human_truth.length &&
					0 < result.progress.human_truth[0].tension.length
				) {
					$scope.completed = result.progress.human_truth[0].tension[0].answer;
					$scope.answerA = $scope.completed.slice(0, 1);
					$scope.answerB = $scope.completed.slice(1, 2);
					$scope.answerAB = $scope.answerA + $scope.answerB;
					$scope.answerC = $scope.completed.slice(2, 3);
				}

				if (
					angular.isDefined($localStorage.progress)  &&
					0 < $localStorage.progress.people_we_serve.length &&
					$localStorage.progress.people_we_serve[0].completed
				) {
					$scope.immersionCompleted = true;
				}
			});

			// Fetch immersion results
			dashboardFactory
				.immersionResults($scope.chosenWorkshop.id)
				.then(function (result) {
					$scope.immersions = result.immersions;
				});
		};

		$scope.next = function () {
			socket.emit("someOneSubmitted", "true");
			$localStorage.lastPlayedPhase = $scope.game;
			$state.go("dashboard.pws");
		};

		// Capture In Their Shoes (Tension) answers
		$scope.answer = function (qId, answerText) {
			if (qId === "1") {
				$scope.answerA = answerText;
				$scope.answers = null;
			}

			if (qId === "2") {
				$scope.answerB = answerText;
				$scope.answers = null;
			}

			if (qId === "3") {
				$scope.answerC = answerText;
			}

			if ($scope.answerA && $scope.answerB) {
				$scope.answerAB = $scope.answerA + $scope.answerB;
			}

			if ($scope.answerA && $scope.answerB && $scope.answerC) {
				$scope.answers = $scope.answerA + $scope.answerB + $scope.answerC;
			}
		};

		// Logic for 3rd In Their Shows (Tension) question
		$scope.oldTensionCheck = function () {
			if ($scope.answerA && $scope.answerB && $scope.answerC) {
				return false;
			}
			return true;
		};

		// Save In Their Shows (Tension) answers
		$scope.submitResults = function () {
			var apiURI = __env.apiUrl;
			$http({
				method: "POST",
				url: apiURI + "in-their-shoes",
				headers: {
					Authorization: "Bearer " + $localStorage.token,
				},
				data: {
					archetype: $scope.answers,
					workshop_id: $localStorage.chosenWorkshop.id,
				},
			}).then(
				function (success) {
					if (success.data.status == "success") {
						dashboardFactory.progress().then(function (result) {
							$scope.progress = result.progress;
							$localStorage.progress = result.progress;
							$scope.loadingScreen = false;
							// Get the users current workshop game
							$scope.game = JSON.parse(result.current_progress).current_game;
							socket.emit("someOneSubmitted", "true");
							$state.go("dashboard.pws");
						});
					}
				},
				function (error) {
					console.log(error);
					swal(
						"Error",
						"An unexpected error has occured. We've sent an error log ot our developers.",
						"error"
					);
				}
			);
		};

		// Show all Immersion answers
		$scope.others = function () {
			if ($scope.showOthers) {
				$scope.showOthers = false;
			} else {
				$scope.showOthers = true;
			}
		};

		// Rotate an Immage
		$scope.rotateImage = function ($direction, $imageName) {
			dashboardFactory
				.ImageRotate($direction, $imageName)
				.then(function (result) {
					dashboardFactory.progress().then(
						function (result) {
							// Get the users current workshop game
							$scope.game = JSON.parse(result.current_progress).current_game;
							if ($scope.progress.people_we_serve.length == 1) {
								$numanswers = $scope.progress.people_we_serve[0].answer.length;
							} else {
								$numanswers = false;
							}

							$numquestions = $scope.chosenWorkshop.immersion_question.length;
							$timeout(function () {
								if ($numanswers == $numquestions) {
									$scope.played = true;
								}
							});

							$scope.progress = result.progress;
							$localStorage.progress = result.progress;
							angular.forEach($scope.QandA, function (value, key) {
								if ($scope.progress.people_we_serve.length == 1) {
									$qid = value.question_id;

									$found = false;
									for (
										var i = 0;
										i < $scope.progress.people_we_serve[0].answer.length;
										i++
									) {
										if (
											$scope.progress.people_we_serve[0].answer[i]
												.question_id == $qid
										) {
											$found = $scope.progress.people_we_serve[0].answer[i];
										}
									}

									if ($found) {
										if (
											value.type === "image" &&
											value.question ===
												"Start by Uploading a profile picture of the person you met"
										) {
											$found.answer =
												$found.answer + "?decache=" + Math.random();
										}

										value.answer = $found.answer;
									}
								}
							});
						},
						function (error) {}
					);
				});
		};

		// Upload an Immersion image
		$scope.uploadImageImmersion = function ($e, file, $id) {
			var myElement = angular.element(document.querySelector("#button-" + $e));
			var originalText = myElement[0].innerHTML;
			myElement[0].innerHTML = "Uploading...";
			Upload.upload({
				url: __env.apiUrl + "immersion/image/" + $id,
				headers: {
					Authorization: "Bearer " + $localStorage.token,
				},
				data: {
					upload: file,
					workshop_id: $scope.chosenWorkshop.id,
				},
			}).then(
				function (resp) {
					myElement[0].innerHTML = originalText;
					dashboardFactory.progress().then(
						function (result) {
							// Get the users current workshop game
							$scope.game = JSON.parse(result.current_progress).current_game;

							if ($scope.progress.people_we_serve.length == 1) {
								$numanswers = $scope.progress.people_we_serve[0].answer.length;
							} else {
								$numanswers = false;
							}

							$numquestions = $scope.chosenWorkshop.immersion_question.length;
							$timeout(function () {
								if ($numanswers == $numquestions) {
									$scope.played = true;
								}
							});

							$scope.progress = result.progress;
							$localStorage.progress = result.progress;
							angular.forEach($scope.QandA, function (value, key) {
								if ($scope.progress.people_we_serve.length == 1) {
									$qid = value.question_id;
									$found = false;
									for (
										var i = 0;
										i < $scope.progress.people_we_serve[0].answer.length;
										i++
									) {
										if (
											$scope.progress.people_we_serve[0].answer[i]
												.question_id == $qid
										) {
											$found = $scope.progress.people_we_serve[0].answer[i];
										}
									}

									if ($found) {
										if (
											value.type === "image" &&
											value.question ===
												"Start by Uploading a profile picture of the person you met"
										) {
											$found.answer =
												$found.answer + "?decache=" + Math.random();
										}

										value.answer = $found.answer;
									}
								}
							});
						},
						function (error) {}
					);
				},
				function (err) {
					console.log(err);
					sweetAlert.swal({
						title: "Error",
						text:
							"There appears to be an issue with the image upload. This is usually caused by the image size being too large. Please, try again with a smaller image. If the upload still doesn't work please contact support.",
						type: "error",
					});
					myElement[0].innerHTML = "Try again";
				}
			);
		};

		// Lock Immersion next button until all questions have been answered
		$scope.checkAnswers = function () {
			var otherThanNull = $scope.QandA.some(function (el) {
				return el.answer == "";
			});
			return otherThanNull;
		};

		// Finalise Immersion data and proceed to next game
		$scope.collectData = function (completed) {
			$scope.unanswered = true;
			angular.forEach($scope.QandA, function (value, key) {
				if (!value.answer) {
					$scope.unanswered = true;
				} else {
					$scope.unanswered = false;
				}
			});

			if (!$scope.unanswered) {
				$scope.input = {
					workshop_id: $localStorage.chosenWorkshop.id,
					questions: $scope.QandA,
					complete: completed,
				};

				dashboardFactory.immersion($scope.input).then(function () {
					if (completed) {
						$scope.played = true;
						$scope.tension = true;
						$scope.immersionCompleted = true;
						// Reload page to see results
						// Fetch immersion results
						dashboardFactory
							.immersionResults($scope.chosenWorkshop.id)
							.then(function (result) {
								$scope.immersions = result.immersions;
							});
						$window.scrollTo(0, 0);
					} else {
						swal("Success", "Your answers have been saved.", "success");
					}
				});
			} else {
				sweetAlert.swal({
					title: "Warning",
					text: "Please answer all questions before submitting.",
					type: "warning",
				});
			}
		};

		$scope.deleteImage = function ($name, $id, $question) {
			$input = {
				name: $name,
				qid: $id,
			};

			var removeValue = function (list, value, separator) {
				separator = separator || ",";
				var values = list.split(separator);
				for (var i = 0; i < values.length; i++) {
					if (values[i] == value) {
						values.splice(i, 1);
						return values.join(separator);
					}
				}
				return list;
			};

			$question.answer = removeValue($question.answer, $name);

			dashboardFactory.deleteImage($input).then(function (result) {
				// console.log('done');
			});
		};
	},
});
