angular.module('app').component('manageGroup', {
	templateUrl: 'app/dashboard/group/group-manage.html',
	controllerAs: 'vm',
	bindings: {
		groups: '<',
		users: '<',
	},
	controller: function(
		$rootScope,
		$state,
		$scope,
		$localStorage,
		groupFactory,
		userFactory,
		sweetAlert,
		titleService,
		Upload,
		$timeout,
		ngToast,
		$compile,
		__env
	) {
		if (__env.enableDebug) {
			console.log('manageGroupController');
		}

		$scope.tinymceModel = null;
		$scope.tinymceOptions = {
			menubar: false,
			plugins: 'link code lists',
			toolbar: 'removeformat | bold italic | alignleft aligncenter | numlist bullist outdent indent | link | code',
		};

		// Set this to self to avoid conflicts
		const self = this;

		// Set the basic controller data to be passed to title service
		titleService.data = {
			title: 'Manage Workshop',
		};

		// Toggle participants page section
		$scope.toggle1 = function() {
			$scope.state1 = !$scope.state1;
			$scope.state2 = false;
			$scope.state3 = false;
		};
		$scope.toggle2 = function() {
			$scope.state2 = !$scope.state2;
			$scope.state1 = false;
			$scope.state3 = false;
		};
		$scope.toggle3 = function() {
			$scope.state3 = !$scope.state3;
			$scope.state1 = false;
			$scope.state2 = false;
		};

		// Broadcast section title to the header
		$rootScope.$broadcast('headerTitle', {
			title: 'Workshop Management',
			icon: 'fa-users',
		});
		self.show = null;

		// Set defaults
		self.toggle = true;
		self.toggleButton = 'Upload User List';
		self.deleteButton = 'Delete Workshop';
		self.addButton = 'Invite Users To This Workshop';
		self.exportButton = 'Export and Save to Drive';
		self.button = 'Invite Users To This Workshop';
		self.assignButton = 'Assign Users';
		$scope.emailSending = false;
		self.group = null;

		$scope.addNewUsersArray = [
				{name: null, surname: null, email: null, password: null}
		];

		// Run when component is ready
		self.$onInit = function() {
			self.updateGroupInfo($localStorage.chosenWorkshop.id);
		};

		// Refetch group information
		self.updateGroupInfo = function(groupId) {
			const input = {
				id: groupId,
			};
			groupFactory.get(input).then(function(result) {
					// Update the group
					self.group = result.group;
					$localStorage.chosenWorkshop.user = result.group;
			});
		};

		// Add a new user to the group
		self.addNewUser = function() {
			self.addButton = 'Adding User';

			// Validation
			$scope.valid = true;
			angular.forEach($scope.addNewUsersArray, function(value, key) {
				if (!value.name || !value.surname || !value.email || !$scope.password) {
					$scope.valid = false;
				}else{
					value.password = $scope.password;
				}
			});

			if ($scope.valid == true) {

				$input = {
					data: $scope.addNewUsersArray,
				};

				groupFactory.addNewUser($input, self.group.id).then(
					function(result) {
						self.addButton = 'Add User';
						$scope.addNewUsersArray = [
							{
								name: null,
								surname: null,
								email: null,
								password: null,
							},
						];
						self.group.user = result.users;
						$localStorage.chosenWorkshop.user = result.users;
					},
					function(error) {
						console.log(error);
						self.addButton = 'Add User';
					}
				);
			} else {
				swal('Error', 'Please make sure all inputs are filled in.', 'error');
				self.addButton = 'Add User';
			}

		};
		// Handle adding an email address
		self.addEmail = function() {
			$scope.addNewUsersArray.push({
				name: null,
				surname: null,
				email: null,
				password: null,
			});
		};
		// Handle removing an email address
		self.removeEmail = function() {
			$scope.addNewUsersArray.splice($scope.addNewUsersArray.length - 1);
		};

		// Mass import users
		self.uploadListHandler = function() {
			if (self.file) {
				self.button = 'Bulk Inviting Users To This Workshop...';
				// Upload the file
				Upload.upload({
					url: env.apiUrl + 'import/users',
					data: {
						file: self.file,
						group_id: $localStorage.chosenWorkshop.id,
						password: self.password,
					},
					headers: {
						Authorization: 'Bearer ' + $localStorage.token,
					},
				}).then(
					function(result) {
						// Show sweet alert with callback
						sweetAlert.swal({
							title: 'Success',
							text: 'The users have been successfully assigned to the workshop.',
							type: 'success',
						});
						// Set scopes
						self.password = null;
						self.file = null;
						self.button = 'Invite Users To This Workshop';
						self.group.user = result.data.users;
						$localStorage.chosenWorkshop.user = result.data.users;
					},
					function(resp) {
						console.log(resp);
						sweetAlert.swal({
							title: 'Error',
							text:
								"There appears to be an issue with the bulk upload. Please, try refresh the page and upload again. If the upload still doesn't work please contact support.",
							type: 'error',
						});
					}
				);
			}
		};

		// Remove a user and admin of the group
		$scope.makeUserAdmin = function(userID, userRole) {
			if (userRole === 'admin') {
				userFactory.removeAdmin($localStorage.chosenWorkshop.id, userID).then(
					function(result) {
						self.group.user = result.users;
						$localStorage.chosenWorkshop.user = result.users;
						swal('Success', 'The user is no longer a facilitator.', 'success');
					},
					function(error) {
						sweetAlert.swal({
							title: 'Error',
							text: error.data.message,
							type: 'error',
						});
					}
				);
			} else {
				userFactory.makeAdmin($localStorage.chosenWorkshop.id, userID).then(
					function(result) {
						self.group.user = result.users;
						$localStorage.chosenWorkshop.user = result.users;
						swal('Success', 'The user has been made a facilitator.', 'success');
					},
					function(error) {
						sweetAlert.swal({
							title: 'Error',
							text: error.data.message,
							type: 'error',
						});
					}
				);
			}
		};

		// Toggles the edit user boxes
		$scope.editUser = function(userID) {
			$scope.isEditingUser = userID;
		};

		// Remove a user from the group
		$scope.editUserRemove = function(userId) {
			sweetAlert
				.swal({
					title: 'Are you sure?',
					text: 'This user will be removed from the group.',
					type: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, remove user!',
				})
				.then(function(check) {
					if (check.value) {
						let input = {
							user_id: userId,
							group_id: $localStorage.chosenWorkshop.id,
						};
						userFactory.remove(input).then(
							function(result) {
							self.group.user = result.users;
							$localStorage.chosenWorkshop.user = result.users;
							sweetAlert
								.swal({
									title: 'Success',
									text: 'The user has been successfully removed.',
									type: 'success',
								});
							},
							function(error) {
								sweetAlert.swal({
									title: 'Error',
									text: error.data.message,
									type: 'error',
								});
							}
						);
					}
				});
			// Close edit box
			$scope.isEditingUser = null;
		};

		// Remove user information
		$scope.editUserUpdate = function(userID, userData) {
			// Check whether any user information has changed
			if (userData) {
				userData.id = userID;
				userData.group_id = $localStorage.chosenWorkshop.id;
				userFactory.update(userData).then(
					function(result) {
						self.group.user = result.users;
						$localStorage.chosenWorkshop.user = result.users;
						swal('Success', 'This users information has been updated.', 'success');
					},
					function(error) {
						// Show sweet alert with callback
						sweetAlert.swal({
							title: 'Error',
							text: error.data.message,
							type: 'error',
						});
					}
				);
			}
			// Close edit box
			$scope.isEditingUser = null;
		};

		// Email a single user
		$scope.message = function($email) {
			Swal({
				title: 'Message to participant',
				input: 'textarea',
				inputPlaceholder: 'Type your message here...',
				showCancelButton: true,
			}).then(result => {
				if (result.value) {
					$input = {
						email: $email,
						message: result.value,
					};
					groupFactory.sendMail($input).then(function(result) {
						Swal('Success', result.message, 'success');
					});
				}
			});
		};

		// Message all of the users
		$scope.messageAll = function() {
			let attachmentFile = '';

			if (self.attachment) {
				attachmentFile = self.attachment;
			}

			// Disable the sending button
			$scope.emailSending = true;
			if ($scope.tinymceModel) {
				$input = {
					id: $localStorage.chosenWorkshop.id,
					message: $scope.tinymceModel,
					attachment: attachmentFile,
				};

				Upload.upload({
					url: env.apiUrl + 'mail/all/users',
					data: $input,
					headers: {
						Authorization: 'Bearer ' + $localStorage.token,
					},
				}).then(function(result) {
					Swal('Success', result.message, 'success');
					$scope.tinymceModel = null;
					self.attachment = null;
					$scope.emailSending = false;
				});
			}
		};

		// Handle group delete
		self.deleteGroup = function() {
			if (__env.enableDebug) {
				console.log('deleteGroup');
			}

			// Change button text
			self.deleteButton = 'Deleting Workshop...';

			// Set the input object
			const input = {
				id: self.group.id,
			};

			// Confirm group delete
			sweetAlert
				.swal({
					title: 'Are you sure?',
					text: 'You will not be able to recover this workshop!',
					type: 'warning',
					showCancelButton: true,
					// ConfirmButtonColor: '#DD6B55',
					confirmButtonText: 'Yes, delete it!',
				})
				.then(function(result) {
					if (result.value) {

						// Change button text back
						$timeout(function() {
							self.deleteButton = 'Delete Workshop';
						});

						// Call the groupFactory delete method
						groupFactory.delete(input).then(
							function(input) {
										// Show sweet alert with callback
										sweetAlert
											.swal({
												title: 'Success',
												text: 'Your workshop has been successfully deleted.',
												type: 'success',
											})
											.then(
												function(input) {

													// Remove group from localstorage
													groupIndex = $localStorage.user.group.findIndex(group => group.id === input.id);
													$localStorage.user.group.splice(groupIndex, 1);

													// Hide the group bar
													$localStorage.showWorkshopHeader = false;

													// Redirect user to dashboard
													$state.go('dashboard.dashboard', {}, {reload: true});
												},
												function(error) {
													console.error(error);
												}
											);
							},
							function(error) {
								// Show sweet alert with callback
								sweetAlert.swal({
									title: 'Error',
									text: error.data.message,
									type: 'error',
								});
							}
						);

					} else {
						self.deleteButton = 'Delete Workshop';
					}
				});
		};

	},
});
