angular.module('app').component('dashboardFooter', {
	templateUrl: 'app/footer.html',
	controllerAs: 'vm',

	controller: function($rootScope) {
		// Set self to this to avoid conflicts
		const self = this;
		self.version = $rootScope.version;

		// Run when component is ready
		self.$onInit = function() {
			// Define the date
			let date = new Date();

			// Set the copyright year
			self.year = date.getFullYear();

			self.siteName = 'Digital Purpose Dive';
		};
	},
});
