angular
	.module('app')

	// Workshop header
	.directive('workshopHeader', function() {
		return {
			restrict: 'E',
			templateUrl: 'app/components/workshop-header.html',
			scope: true, // Get the scope of the current Controller
		};
	})

	// Show my results component
	.directive('showMyResults', function() {
		return {
			restrict: 'E',
			templateUrl: 'app/components/show-my-results.html',
			scope: true,
			link: function(scope) {
				
			}
		};
	})

	// Convert string to number directive
	.directive('number', function() {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function(scope, element, attr, ngModel) {
				function toModel(value) {
					return '' + value; // convert to string
				}

				function toView(value) {
					return parseInt(value); // convert to number
				}
				ngModel.$formatters.push(toView);
				ngModel.$parsers.push(toModel);
			},
		};
	})

	// Scroll to element on page directive
	.directive('scrollTo', function() {
		return {
			restrict: 'A',
			scope: {
				scrollTo: '@',
			},
			link: function(scope, $elm, attr) {
				$elm.on('click', function() {
					// Checks if archetype has results
					if (scope.$parent.data.percentage !== 0) {
						$('html,body').animate(
							{
								scrollTop: $(scope.scrollTo).offset().top - 60, // Subtract the height of the header
							},
							'slow'
						);
					}
				});
			},
		};
	})

	// Assign words to quadrant directive
	.directive('toolbar', function($rootScope, gameFactory, sweetAlert, $state) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs, vm) {
				scope.callback;
				$(element).toolbar({
					event: 'click',
					hideOnClick: true,
					content: '#toolbar-options',
					adjustment: 35,
				});
				// Capture the click event
				$(element).on('toolbarItemClick', function(event, el) {
					const word = this.innerText; // Get the word that is being assigned to quadrant
					const quadrant = $(el).data('quad-id'); // Get the quadrant id we want to assign word to
					const input = {
						word: word,
						quadrant: quadrant,
						group_id: scope.vm.group.id,
					};

					// Assign the word to selected quadrant
					gameFactory.assignWord(input).then(
						function(result) {
							// Show sweet alert with callback
							sweetAlert
								.swal({
									title: 'Success',
									text: 'Word successfully added to quadrant.',
									type: 'success',
								})
								.then(function() {
									// Refresh the group results
									scope.vm.refreshGroupResults();
								});
							// Trigger body click to close the toolbar
							$('body').trigger('click');
						},
						function(error) {
							console.log(error);
						}
					);
				});
			},
		};
	})

	// Consolidate words toggle directive
	.directive('consolidate', function($timeout) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				const el = $(element);
				el.on('click', function() {
					let word = el.text();
					let i = scope.arrWords.indexOf(word);
					// toggle word in array
					$timeout(function() {
						i === -1 ? scope.arrWords.push(word) : scope.arrWords.splice(i, 1);
					});
					// Toggle active class
					el.toggleClass('active');
				});
			},
		};
	})

	// Toggle Hamburger Menu
	.directive('hamburger', function() {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				const el = $(element);
				el.on('click', function() {
					// Toggle active class on hamburger icon
					el.toggleClass('is-active');
					// Toggle active class on menu and wrapper
					$('#sidebar-wrapper, #wrapper').toggleClass('is-active');
				});
			},
		};
	})

	// Animate the progress circle and line
	.directive('progress', function() {
		return {
			restrict: 'A',
			link: function(scope, element, attrs, $timeout) {
				const el = $(element);
				el.children().each(function(i) {
					const circle = $(this);
					$timeout(function() {
						circle.css('transform', 'scale(1)');
					}, 275 * i);
				});
			},
		};
	})

	// Confirm on navigate or reload
	.directive('confirmOnExit', function() {
		return {
			link: function($scope) {
				window.onbeforeunload = function() {
					if ($scope.unsaved) {
						return 'Have you saved all of your changes?';
					}
				};
				$scope.$on('$locationChangeStart', function(event) {
					if ($scope.unsaved) {
						if (
							!confirm(
								'We\'ve detected that your keyboard was pressed and just want to make sure you\'ve saved any edits before proceeding? Click "ok" to continue or "cancel" to go back and save your edits.'
							)
						) {
							event.preventDefault();
						}
					}
				});
			},
		};
	});
