angular.module("app").component("peopleWeServe", {
	templateUrl: "app/dashboard/game/02-people-we-serve.html",
	controllerAs: "vm",

	controller: function (
		__env,
		$rootScope,
		$state,
		$scope,
		$localStorage,
		$window,
		dashboardFactory,
		showMyResultsService,
		utilService,
		$timeout,
		socket,
		sweetAlert
	) {
		if (__env.enableDebug) {
			console.log("peopleWeServeController");
		}

		// Set this to self to avoid conflicts
		const self = this;

		// Used to set the navigator
		$scope.currentGame = 2;

		// Don't show popup if phase has been completed
		self.showPopup = true;
		if (angular.isDefined($localStorage.progress.human_truth[0])) {
			let usersGameGroupID = $localStorage.progress.human_truth[0].group_id;
			if ( 
				angular.isDefined($localStorage.progress) &&
				angular.isDefined($localStorage.progress.profiler[usersGameGroupID]) &&
				$localStorage.progress.profiler[usersGameGroupID][0].completed
			) {
				if (__env.enableDebug) {
					console.log("This game has been completed");
				}
				self.showPopup = false;
			}
		}

		$scope.navigateToGamePhase = function(gamePhaseName, completedPhaseNumber) {
			var result = utilService.navigateToGamePhase(gamePhaseName, completedPhaseNumber);
			if (result) {
				$state.go(result);
			}
		};

		// Popup Content
		$scope.popupContent =
			'<div class="row">\
					<div class="col-md-12">\
						<p><strong>A single people portrait uniting the people we met.</strong></p>\
						<p>Our brand will be strongest when we create meaning and relevance within the lives of the people we serve. We will now shape a profile that can unify our many stories from the Immersions.</p>\
						\
						<hr>\
						<p><strong>What makes a strong people portrait?</strong></p>\
						<p>A strong people portrait looks beyond the product, the brand, and the category — it looks into the lives of the people we serve: their hopes, fears, and dreams, capturing who they are as people, not just consumers.</p>\
						<p><a target="_blank" href="https://drive.google.com/file/d/1aaOoHb-zShGdyRSVgY7xzzjyo3RoSgye/view">Click here to view examples</a></p>\
					</div>\
				</div>';

		$scope.showOthers = false;
		$rootScope.userEmail = $localStorage.user.email;
		$scope.currentWorkshopId = $localStorage.chosenWorkshop.id;
		$scope.url = __env.apiUrl;
		$scope.profilerChat = [];
		$scope.phaseOneOpened = "false";
		$scope.email = $rootScope.userEmail;
		$scope.answerA = null;
		$scope.group = null;
		$scope.answerB = null;
		$scope.answers = null;
		$scope.startpage = false;
		$scope.loadingScreen = "true";

		$scope.dataset = $localStorage.dataset;

		$scope.choiceSet = {
			messages: [],
			editing: [],
			whoIsEditing: [],
			isDisabled: [],
			parts: [],
			questions: [],
			icons: [],
			ids: [],
			names: [],
		};
		$scope.progress = $localStorage.progress;
		$scope.editing = false;
		$scope.isDisabled = false;
		$scope.whoIsEditing = null;
		$scope.EditorName = null;
		$scope.editingStatement = false;
		$scope.isDisabledStatement = false;
		$scope.whoIsEditingStatement = null;

		// Get and Set available game results for show my results component
		showMyResultsService.getAvailableGameResults($scope.currentGame).then(function(myGameResults) {
			$scope.myResults = myGameResults;
			$scope.gameResultsToShow = {};
			$scope.currentResults = ($scope.myResults.length > 0) ? $scope.myResults[0].value : 'default';
			$scope.numberOfResults = showMyResultsService.getNumberOfShowingResults($scope.myResults);
		});

		// Set the function to update selected results on component
		$scope.updateSelectedResults = function(selectedResults) {
			if (angular.isDefined($scope.myResults)) {
				showMyResultsService.updateSelectedResults(selectedResults, $scope.myResults).then(function(result) {
					$scope.gameResultsToShow = result;
				});
			}
		};

		// Run when component is ready
		self.$onInit = function () {
			// Fetch users progress
			dashboardFactory.progress().then(
				function (result) {
					$scope.progress = result.progress;
					$localStorage.progress = result.progress;
					// Get the users current workshop game
					$scope.game = JSON.parse(result.current_progress).current_game;
					var gotoPhase = utilService.navigateToGamePhase($scope.currentGame, $scope.game, $scope.currentGame);
					if (angular.isDefined(gotoPhase) && gotoPhase !== 'dashboard.pws') {
						$state.go(gotoPhase, {}, { reload: true });
					} else {
						$localStorage.lastPlayedPhase = $scope.game;
					}
				},
				function (error) {
					console.log(error);
				}
			);

			$scope.choosenWorkshopMethod();

			$scope.userGroups = $localStorage.groups;
		};

		// Handle next button after submission
		$scope.next = function () {
			socket.emit("someOneSubmitted", "true");
			$localStorage.lastPlayedPhase = $scope.game;
			$state.go("dashboard.human-truth");
		};

		// Fetch workshop data
		$scope.choosenWorkshopMethod = function () {
			// Fetch all workshop results
			dashboardFactory.fetch().then(
				function (result) {
					$scope.chosenWorkshop = result.group;
					$localStorage.chosenWorkshop = result.group;
					$scope.phaseOneOpened = result.group.phase_one_open;

					// If the user is assigned to a group then get the chat ID for this group
					if (
						angular.isDefined(
							$scope.chosenWorkshop.collaboration_group[
								$localStorage.progress.human_truth[0].group_id - 1
							]
						)
					) {
						$scope.thisChatId =
							$scope.chosenWorkshop.collaboration_group[
								$localStorage.progress.human_truth[0].group_id - 1
							].id;
					}

					// Get the current users group
					let usersGameGroupID = $localStorage.progress.human_truth[0].group_id;

					// Manage locking of content
					if (
						result.locks["profiler"] &&
						result.locks["profiler"][usersGameGroupID]
					) {
						$scope.locked = true;
					}

					// Check whether phase has been completed
					if (
						angular.isDefined(
							$localStorage.progress.profiler[usersGameGroupID]
						) &&
						$localStorage.progress.profiler[usersGameGroupID][0].completed
					) {
						$scope.completed = true;
					}

					if ($localStorage.progress.human_truth.length > 0) {
						$scope.tension = false;
						$scope.showDetails = true;

						if ($localStorage.progress.human_truth[0].group_id) {
							$scope.loadingScreen = "true";
							$scope.choiceSetMethod(
								$localStorage.progress.human_truth[0].group_id
							);
							$scope.group = $localStorage.progress.human_truth[0].group_id;

							// Fetch In Their Shoes Results
							dashboardFactory
								.collaborationHumanGroupResults(result.group.id, 0)
								.then(function (result) {
									$scope.humanGroups = result.groups;
								});

							$timeout(function () {
								if ($scope.loadingScreen && self.showPopup) {
									sweetAlert.swal({
										html: $scope.popupContent,
										customClass: "instructions",
										showCancelButton: false,
										showConfirmButton: false,
										showCloseButton: true,
									});
								}
							});
						} else {
							$scope.loadingScreen = "false";
						}
					} else {
						$scope.tension = true;
					}
				},
				function (error) {
					console.log(error);
				}
			);
		};

		/**
		 * Build the question /  answer set
		 */
		$scope.choiceSetMethod = function ($id) {

			// Reset the data to avoid duplicates
			$scope.choiceSet = {
				messages: [],
				editing: [],
				whoIsEditing: [],
				isDisabled: [],
				parts: [],
				questions: [],
				icons: [],
				ids: [],
				names: [],
			};

			// Build the question set
			angular.forEach(
				$scope.chosenWorkshop.collaboration_group[$id - 1].profiler,
				function (value, key) {
					$scope.choiceSet.messages.push(value.message);
					$scope.choiceSet.editing.push("");
					$scope.choiceSet.whoIsEditing.push("check");
					$scope.choiceSet.isDisabled.push("");
					$scope.choiceSet.parts.push(value.part);
					$scope.choiceSet.ids.push(value.id);
					$scope.choiceSet.questions.push(value.profiler_question[0].question);
					$scope.choiceSet.icons.push(value.profiler_question[0].icon);
					$scope.choiceSet.names.push("");
				}
			);

			// Fetch group members
			dashboardFactory
				.groupMembers($scope.chosenWorkshop.id, $id, "profiler")
				.then(function (result) {
					$scope.groupMembers = result.members;
				});

			// Show the chat messages
			angular.forEach(
				$scope.chosenWorkshop.collaboration_group[$id - 1].chat,
				function (value, key) {
					if ($rootScope.userEmail == value.email) {
						is_currentUser = "true";
					} else {
						is_currentUser = "false";
					}

					$message = {
						id: value.id,
						message: value.message,
						name: value.name,
						user: value.email,
						timestamp: value.created_at,
						is_reply: is_currentUser,
					};
					$scope.profilerChat.push($message);

					$timeout(function () {
						var scroller = document.getElementById("chatscreen");
						if (scroller) scroller.scrollTop = scroller.scrollHeight;
					});
				}
			);
			$timeout(function () {
				socket.emit("check", "first check");
			});
		};

		// Notify users when admin joins the group
		$scope.showFacilitator = function ($group, $game) {
			dashboardFactory
				.notifyFacilitator($scope.currentWorkshopId, $group, $game)
				.then(
					function () {
						$rootScope.facilitatorPopUp("no redirect");

						// Broadcast lock for all other group participants
						let lockedData = {
							wid: $scope.currentWorkshopId,
							gid: $group,
							game: $game,
							method: "lock",
						};
						socket.emit("lockGroupAnswers", lockedData);
					},
					function (error) {
						console.log(error);
					}
				);
		};

		// Listen for answer locking broadcast
		socket.on("lockGroupAnswers", function (data) {
			// console.log("[+] Lock broadcast");
			// console.log('Data', data);
			// console.log('Participant:', parseInt($scope.currentWorkshopId), parseInt($localStorage.progress.human_truth[0].group_id));
			// Is broadcast is applicable to current phase?
			if (data.game == "Profiler") {
				// Is broadcast applicable to this workshop and group?
				if (
					parseInt(data.wid) == parseInt($scope.currentWorkshopId) &&
					parseInt(data.gid) ==
						parseInt($localStorage.progress.human_truth[0].group_id)
				) {
					// Lock answers
					if (data.method == "lock") {
						console.log("[+] Recieved lock broadcast");
						$scope.locked = 1;
					}
					// Unlock answers
					if (data.method == "unlock") {
						console.log("[+] Recieved unlock broadcast");
						$scope.locked = 0;
					}
				}
			}
		});

		// Request that a Facilitator joins the chate
		$scope.requestFacilitatorJoinsChat = function ($group, $game) {
			// Broadcast lock for all other group participants
			let payload = {
				wid: parseInt($scope.currentWorkshopId),
				gid: parseInt($group),
				game: $game,
			};
			socket.emit("requestFacilitatorJoinsChat", payload);

			sweetAlert.swal({
				title: "Requested",
				text: "The facilitator has been asked to join this group.",
				type: "warning",
			});
		};

		// Submit edit of input to the api
		$scope.sumbitProfiler = function ($part, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if ($part === value && id == $scope.choiceSet.ids[key]) {
					$scope.input = {
						part: $part,
						message: $scope.choiceSet.messages[key],
						profiler_id: $scope.choiceSet.ids[key],
						group_id: $localStorage.progress.human_truth[0].group_id,
					};

					dashboardFactory.save($scope.input).then(
						function (result) {
							$timeout(function () {
								socket.emit("newProfiler", result.profiler);
							});
						},
						function (error) {
							console.log(error);
						}
					);
				}
			});
		};

		// Send a chat message
		$scope.sendChatMessage = function (message, game, chatId, emit) {
			$scope.input = {
				message: message,
				chat_id: chatId,
			};
			dashboardFactory.chat($scope.input, game).then(
				function (result) {
					result.chats.is_reply = "true"; // IN8-150
					socket.emit(emit, result.chats);
					angular.element(document.getElementById(game)).val("");
				},
				function (error) {
					console.log(error);
				}
			);
		};

		// Submit answers to facilitator
		$scope.checkAnswers = function () {
			var otherThanNull = $scope.choiceSet.messages.some(function (el) {
				return el == null;
			});
			return otherThanNull;
		};

		// See what others have done
		$scope.others = function () {
			if ($scope.showOthers) {
				$scope.showOthers = false;
			} else {
				$scope.showOthers = true;
			}
		};

		// User clicks input to edit
		$scope.focusInput = function (data, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data === value && id == $scope.choiceSet.ids[key]) {
					$name =
						$localStorage.user.name +
						" " +
						$localStorage.user.surname.substr(0, 1);
					// Broadcasts to to connected sockets that the user is editing an input
					socket.emit("editing", {
						user: $rootScope.userEmail,
						name: $name,
						element: data,
						id: $scope.choiceSet.ids[key],
					});
				}
			});
		};

		// Pass the pen
		$scope.passThePen = function (data, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data === value && id == $scope.choiceSet.ids[key]) {
					// Broadcasts to to connected sockets that the user is editing an input
					socket.emit("editing", {
						user: null,
						name: null,
						element: data,
						id: $scope.choiceSet.ids[key],
					});
				}
			});
		};

		// User wants to unlock a input that another user is editing
		$scope.unlockInput = function (data, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data === value && id == $scope.choiceSet.ids[key]) {
					// Show sweet alert with callback
					sweetAlert
						.swal(
							{
								title: "Warning",
								text:
									"Please note by unlocking you are removing " +
									$scope.choiceSet.names[key] +
									" from editing.",
								type: "warning",
							},
							function () {}
						)
						.then(function () {
							$name =
								$localStorage.user.name +
								" " +
								$localStorage.user.surname.substr(0, 1);
							socket.emit("editing", {
								user: $rootScope.userEmail,
								name: $name,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						});
				}
			});
		};

		socket.on("recieveHumanGroup", function (data) {
			if (data.id == $localStorage.chosenWorkshop.id) {
				$scope.loadingScreen = "true";
			}
		});

		// Push users forward on admin approval
		socket.on("groupApproved", function (data) {
			if (
				data.group == $localStorage.progress.human_truth[0].group_id &&
				data.phase === "Phase One"
			) {
				$state.go("dashboard.human-truth");
			}
		});

		// Socket for chat
		socket.on("recieveProfilerMessage", function (data) {
			if (
				data.collaboration_group[0].id ==
				$scope.chosenWorkshop.collaboration_group[
					$localStorage.progress.human_truth[0].group_id - 1
				].id
			) {
				if ($rootScope.userEmail == data.email) {
					data.is_reply = "true";
				} else {
					data.is_reply = "false";
				}

				$message = {
					id: data.id,
					message: data.message,
					name: data.name,
					user: data.email,
					timestamp: data.created_at,
					is_reply: data.is_reply,
				};
				$scope.profilerChat.push($message);
				$timeout(function () {
					var scroller = document.getElementById("chatscreen");
					scroller.scrollTop = scroller.scrollHeight;
				});
			}
		});

		// Socket to check if any inputs are locked by another user
		socket.on("editingCheck", function (data) {
			if (data === "check") {
				angular.forEach($scope.choiceSet.whoIsEditing, function (value, key) {
					if (value != "check") {
						socket.emit("editing", {
							user: value,
							name: $scope.choiceSet.names[key],
							element: $scope.choiceSet.parts[key],
							id: $scope.choiceSet.ids[key],
						});
					}
				});
			}
		});

		socket.on("recieveAdminJoined", function (data) {
			if (
				data.workshop == $scope.chosenWorkshop.id &&
				data.group == $localStorage.progress.human_truth[0].group_id &&
				data.game === "People Portrait"
			) {
				if (
					!$scope.groupMembers.some((item) =>
						item.email.includes(data.user.email)
					)
				) {
					$scope.groupMembers.push(data.user);
				}

				sweetAlert.swal({
					title: "Note",
					text: "The facilitator has joined the group.",
					type: "warning",
				});
			}
		});

		// Socket to recieve who is editing an input
		socket.on("editor", function (data) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data.element === value && data.id == $scope.choiceSet.ids[key]) {
					$scope.choiceSet.whoIsEditing[key] = data.user;
					$scope.choiceSet.names[key] = data.name;
					$scope.EditorName = data.name;
					if ($scope.choiceSet.whoIsEditing[key] == null) {
						$scope.choiceSet.isDisabled[key] = false;
					} else {
						if ($scope.choiceSet.whoIsEditing[key] !== $rootScope.userEmail) {
							$scope.choiceSet.isDisabled[key] = true;
						} else {
							$scope.choiceSet.isDisabled[key] = false;
						}

						$scope.choiceSet.editing[key] = false;
					}
				}
			});
		});

		// Socket to check if any inputs are locked by another user
		socket.on("editingCheckStatementColab", function (data) {
			if (data === "check") {
				if ($scope.whoIsEditingStatement != "check") {
					socket.emit("editingStatementColab", {
						user: $scope.whoIsEditingStatement,
						id: $localStorage.collaboration_statement.id,
					});
				}
			}
		});

		// Socket to recieve who is editing an input
		socket.on("editorStatementColab", function (data) {
			if (data.id == $localStorage.collaboration_statement.id) {
				$scope.whoIsEditingStatement = data.user;
				if ($scope.whoIsEditingStatement == null) {
					$scope.isDisabledStatement = false;
				} else {
					if ($scope.whoIsEditingStatement !== $rootScope.userEmail) {
						$scope.isDisabledStatement = true;
					} else {
						$scope.isDisabledStatement = false;
					}

					$scope.editingStatement = false;
				}
			}
		});

		socket.on("groupAssigned", function (data) {
			if (data.data.id == $localStorage.chosenWorkshop.id) {
				dashboardFactory
					.collaborationHumanGroupResults($localStorage.chosenWorkshop.id, 0)
					.then(function (result) {
						$scope.humanGroups = result.groups;
					});
				dashboardFactory.progress().then(
					function (result) {

						$scope.progress = result.progress;
						$localStorage.progress = result.progress;
						$scope.tension = false;
						$scope.showDetails = true;
						// Get the users current workshop game
						$scope.game = JSON.parse(result.current_progress).current_game;

						if ($localStorage.progress.human_truth[0].group_id) {
							$scope.loadingScreen = "true";

							$scope.choiceSetMethod(
								$localStorage.progress.human_truth[0].group_id
							);

							$scope.group = $localStorage.progress.human_truth[0].group_id;

							if (
								angular.isDefined(
									$scope.chosenWorkshop.collaboration_group[
										$localStorage.progress.human_truth[0].group_id - 1
									]
								)
							) {
								$scope.thisChatId =
									$scope.chosenWorkshop.collaboration_group[
										$localStorage.progress.human_truth[0].group_id - 1
									].id;
							}

							$window.scrollTo(0, 0);

							$timeout(function () {
								if (self.showPopup) {
									sweetAlert.swal({
										html: $scope.popupContent,
										customClass: "instructions",
										showCancelButton: false,
										showConfirmButton: false,
										showCloseButton: true,
									});
								}
								$scope.loadingScreen = "true";
								$scope.phaseOneOpened = "true";
							});
						} else {
							$scope.loadingScreen = "false";
						}
					},
					function (error) {
						console.log(error);
					}
				);
			}
		});

		// Socket to receive change to an input from another user
		socket.on("profiler", function (data) {
			// Only update the answers fo the group that has made the change
			if(angular.isDefined($localStorage.progress.human_truth[0]) && data.group_id == $localStorage.progress.human_truth[0].group_id){
				console.log("Updating answers for group " + $localStorage.progress.human_truth[0].group_id);
				angular.forEach($scope.choiceSet.parts, function (value, key) {
					if (data.part === value && data.id == $scope.choiceSet.ids[key]) {
						$scope.choiceSet.messages[key] = data.message;
					}
				});
			}

		});
	},
});
