angular
	.module('app')
	.service('utilService', function ($localStorage) {        
        var gamePhaseRoutes = ["dashboard.immersion-debrief", "dashboard.pws", "dashboard.human-truth", "dashboard.brand-pov", "dashboard.product-truth", "dashboard.pyramid", "dashboard.completed"];
        var secondRun = false;
        return {
            navigateToGamePhase: function (gamePhaseName, completedPhaseNumber, calledInFunction = false) {
                var lastGame = (angular.isDefined($localStorage.lastPlayedPhase)) ? $localStorage.lastPlayedPhase : calledInFunction;
                if(!calledInFunction && completedPhaseNumber) {
                    if ((gamePhaseName == gamePhaseRoutes[0] || gamePhaseName == 1) && (completedPhaseNumber > 0 || lastGame > 0)) {
                        return(gamePhaseRoutes[0]);
                    } else if ((gamePhaseName == gamePhaseRoutes[1] || gamePhaseName == 2) && (completedPhaseNumber > 1  || lastGame > 1)) {
                        return(gamePhaseRoutes[1]);
                    } else if ((gamePhaseName == gamePhaseRoutes[2] || gamePhaseName == 3) && (completedPhaseNumber > 2  || lastGame > 2)) {
                        return(gamePhaseRoutes[2]);
                    } else if ((gamePhaseName == gamePhaseRoutes[3] || gamePhaseName == 4) && (completedPhaseNumber > 3  || lastGame > 3)) {
                        return(gamePhaseRoutes[3]);
                    } else if ((gamePhaseName == gamePhaseRoutes[4] || gamePhaseName == 5) && (completedPhaseNumber > 4  || lastGame > 4)) {
                        return(gamePhaseRoutes[4]);
                    } else if ((gamePhaseName == gamePhaseRoutes[5] || gamePhaseName == 6) && (completedPhaseNumber > 5  || lastGame > 5)) {
                        return(gamePhaseRoutes[5]);
                    }
                } else {
                    if ((gamePhaseName == 1 || calledInFunction == 1)) {
                        return(gamePhaseRoutes[0]);
                    } else if (gamePhaseName ==  2 || calledInFunction == 2) {
                        return(gamePhaseRoutes[1]);
                    } else if (gamePhaseName ==  3 || calledInFunction == 3) {
                        return(gamePhaseRoutes[2]);
                    } else if (gamePhaseName ==  4 || calledInFunction == 4) {
                        return(gamePhaseRoutes[3]);
                    } else if (gamePhaseName ==  5 || calledInFunction == 5) {
                        return(gamePhaseRoutes[4]);
                    } else if (gamePhaseName ==  6 || calledInFunction == 6) {
                        return(gamePhaseRoutes[5]);
                    } else if (gamePhaseName ==  7 || calledInFunction == 7) {
                        return(gamePhaseRoutes[6]);
                    }
                    secondRun = true;
                }
                //Redirect user to the last completed game phase
                if (!secondRun) {
                    return this.navigateToGamePhase(completedPhaseNumber, completedPhaseNumber, completedPhaseNumber);
                } else {
                    return(gamePhaseRoutes[completedPhaseNumber - 1]);
                }
            },
        }
    })
