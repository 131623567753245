angular.module('app').component('completed', {
	templateUrl: 'app/dashboard/game/07-completed.html',
	controllerAs: 'self',

	controller: function($rootScope, $scope, $state, showMyResultsService, $timeout, $localStorage, $window, dashboardFactory, socket, sweetAlert, __env, utilService) {
		if (__env.enableDebug) {
			console.log('completedController');
		}

		// Set this to self to avoid conflicts
		const SELF = this;

        $scope.currentGame = 7;
		$localStorage.showWorkshopHeader = true;
		$scope.chosenWorkshop = $localStorage.chosenWorkshop;
        $scope.choiceSet = {
            messages: [],
            editing: [],
            whoIsEditing: [],
            isDisabled: [],
            parts: [],
            questions: [],
            ids: [],
            names: [],
        };
        $scope.editing = false;
        $scope.isDisabled = false;
        $scope.whoIsEditing = 'check';
        $scope.loadingScreen = true;
        $scope.Math = $window.Math;
        $scope.chatStatementMessage = null;

        // Get and Set available game results for show my results component
		showMyResultsService.getAvailableGameResults($scope.currentGame).then(function(myGameResults) {
			$scope.myResults = myGameResults;
			$scope.gameResultsToShow = {};
			$scope.currentResults = ($scope.myResults.length > 0) ? $scope.myResults[0].value : 'default';
			$scope.numberOfResults = showMyResultsService.getNumberOfShowingResults($scope.myResults);
		});

		// Set the function to update selected results on component
		$scope.updateSelectedResults = function(selectedResults) {
			if (angular.isDefined($scope.myResults)) {
				showMyResultsService.updateSelectedResults(selectedResults, $scope.myResults).then(function(result) {
					$scope.gameResultsToShow = result;
				});
			}
        };

        $scope.navigateToGamePhase = function(gamePhaseName, completedPhaseNumber) {
			var result = utilService.navigateToGamePhase(gamePhaseName, completedPhaseNumber);
			if (result) {
				$state.go(result);
			}
		};

        var gotoPhase =  utilService.navigateToGamePhase(false, false, $scope.currentGame);
        if (angular.isDefined(gotoPhase) && gotoPhase !== 'dashboard.completed') {
            $state.go(gotoPhase);
        }
        

		SELF.$onInit = function() {
			$scope.facilitatorShown = false;
			$gid = 0;

            dashboardFactory.progress().then(
            function(result) {

                // Get the users current workshop game
				$scope.game = JSON.parse(result.current_progress).current_game;

                // Set completed state
                if (angular.isDefined(result.progress.pyramid[0])) {
                    $scope.completed = result.progress.pyramid[0].completed;
                }

                if ($localStorage.chosenWorkshop) {
                dashboardFactory.fetch().then(
                    function(result2) {
                    $scope.chosenWorkshop = result2.group;
                    $localStorage.chosenWorkshop = result2.group;
                    $scope.progress = result.progress;
                    $localStorage.progress = result.progress;

                    if ($localStorage.progress.pyramid[0].group_id && $scope.chosenWorkshop.phase_four_open === 'true') {
                        if ($localStorage.progress.pyramid[0].group_id) {
                        $povGroupID = $localStorage.progress.pyramid[0].group_id;
                        $scope.povGroupID = $povGroupID;
                        } else {
                        $scope.loadingScreen = false;
                        }
                    } else {
                        $povGroupID = 0;
                        $scope.povGroupID = $povGroupID;
                        if (
                        $localStorage.progress.pyramid[0].human_truth &&
                                            $localStorage.progress.pyramid[0].people_we_serve &&
                                            $localStorage.progress.pyramid[0].brand_point_of_view &&
                                            $localStorage.progress.pyramid[0].product_truth
                        ) {
                        $scope.loadingScreen = false;
                        }
                    }

                    $scope.answerSet = { answers: [] };
                    if ($localStorage.chosenWorkshop) {
                        $scope.chosenWorkshop = $localStorage.chosenWorkshop;

                        dashboardFactory.fetch().then(
                            function(result) {
                            $scope.chosenWorkshop = result.group;

                            $localStorage.chosenWorkshop = result.group;
                            angular.forEach(result.group.question, function(value, key) {
                                $scope.answerSet.answers.push('');
                                $q = {
                                question: value.question,
                                questionID: key + 1,
                                question_id: value.id,
                                type: value.type,
                                };
                                if (value.answer_options) {
                                $splitoptions = value.answer_options.split(',');
                                $options = [];
                                angular.forEach($splitoptions, function(value, key) {
                                    $options.push({
                                    name: value,
                                    id: key + 1,
                                    });
                                    $scope.optionValue++;
                                });
                                $q.options = $options;
                                }

                                $scope.QandA.push($q);
                            });

                            $scope.optionValue = 1;
                            $timeout(function() {
                            $timeout(function() {
                                if ($scope.loadingScreen && self.showPopup) {
                                sweetAlert.swal({
                                    html: $scope.popupContent,
                                    customClass: 'instructions',
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                    });
                                }
                                });
                                if ($povGroupID > 0) {
                                $scope.groupPyramidCompleted = $localStorage.chosenWorkshop.pyramid[$povGroupID-1].completed;

                                $scope.choiceSet.messages.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].people_we_serve);
                                $scope.choiceSet.editing.push('');
                                $scope.choiceSet.whoIsEditing.push('check');
                                $scope.choiceSet.isDisabled.push('');
                                $scope.choiceSet.parts.push('people_we_serve');
                                $scope.choiceSet.questions.push('People We Serve');
                                $scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].id);
                                $scope.choiceSet.names.push('');

                                $scope.choiceSet.messages.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].human_truth);
                                $scope.choiceSet.editing.push('');
                                $scope.choiceSet.whoIsEditing.push('check');
                                $scope.choiceSet.isDisabled.push('');
                                $scope.choiceSet.parts.push('human_truth');
                                $scope.choiceSet.questions.push('Human Truth');
                                $scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].id);
                                $scope.choiceSet.names.push('');

                                $scope.choiceSet.messages.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].brand_point_of_view);
                                $scope.choiceSet.editing.push('');
                                $scope.choiceSet.whoIsEditing.push('check');
                                $scope.choiceSet.isDisabled.push('');
                                $scope.choiceSet.parts.push('brand_point_of_view');
                                $scope.choiceSet.questions.push('Brand Point Of View');
                                $scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].id);
                                $scope.choiceSet.names.push('');

                                $scope.choiceSet.messages.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].product_truth);
                                $scope.choiceSet.editing.push('');
                                $scope.choiceSet.whoIsEditing.push('check');
                                $scope.choiceSet.isDisabled.push('');
                                $scope.choiceSet.parts.push('product_truth');
                                $scope.choiceSet.questions.push('Product Truth');
                                $scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].id);
                                $scope.choiceSet.names.push('');

                                dashboardFactory.groupMembers($scope.chosenWorkshop.id, $povGroupID, 'pyramid').then(
                                    function(result) {
                                    $scope.groupMembers = result.members;
                                    $timeout(function() {});
                                    },
                                    function(error) {
                                        console.log(error);
                                    }
                                );
                                } else {
                                $scope.choiceSet.messages.push($localStorage.progress.pyramid[0].people_we_serve);
                                $scope.choiceSet.parts.push('people_we_serve');
                                $scope.choiceSet.questions.push('People We Serve');
                                $scope.choiceSet.ids.push($localStorage.progress.pyramid[0].id);

                                $scope.choiceSet.messages.push($localStorage.progress.pyramid[0].human_truth);
                                $scope.choiceSet.parts.push('human_truth');
                                $scope.choiceSet.questions.push('Human Truth');
                                $scope.choiceSet.ids.push($localStorage.progress.pyramid[0].id);

                                $scope.choiceSet.messages.push($localStorage.progress.pyramid[0].brand_point_of_view);
                                $scope.choiceSet.parts.push('brand_point_of_view');
                                $scope.choiceSet.questions.push('Brand Point Of View');
                                $scope.choiceSet.ids.push($localStorage.progress.pyramid[0].id);

                                $scope.choiceSet.messages.push($localStorage.progress.pyramid[0].product_truth);
                                $scope.choiceSet.parts.push('product_truth');
                                $scope.choiceSet.questions.push('Product Truth');
                                $scope.choiceSet.ids.push($localStorage.progress.pyramid[0].id);
                                }
                            });
                            },
                            function(error) {
                                console.log(error);
                            }
                        );
                        $wid = $scope.chosenWorkshop.id;
                        dashboardFactory.collaborationHumanGroupResults($wid, $gid).then(function(result) {
                            $scope.humanGroups = result.groups;
                        });
                        } else {
                        $scope.chosenWorkshop = null;
                        }

                        $scope.url = __env.apiUrl;
                        $scope.finish = function() {
                        $state.go('dashboard.dashboard');
                        };

                        if ($localStorage.user) {
                        $scope.user = $localStorage.user;
                        }

                        $scope.showMethod = function() {
                        if ($scope.showDetails == true) {
                            $scope.showDetails = false;
                        } else {
                            $scope.showDetails = true;
                        }
                        };

                        dashboardFactory.collaborationProductGroupResults($wid, $gid).then(function(result) {
                        $scope.productGroups = result.groups;
                        });

                        dashboardFactory.immersionResults($wid).then(function(result) {
                        $scope.immersions = result.immersions;
                    });

                    $scope.changeData = function(data, color) {
                        $scope.dataPage = data;
                        $scope.dataPageColor = color;
                    };

                    $scope.dataPage = 'pyramids';
                    // Build a new object with completed pyramids only
                    $scope.completedPyramids = [];
                    angular.forEach($localStorage.chosenWorkshop.pyramid, function(value, key) {
                        if(value.completed){
                            $scope.completedPyramids.push(value);
                        }
                    });

                    $scope.categories = [];
                    $scope.data = [];
                    dashboardFactory.collaborationPovGroupResults($wid, $gid).then(function(result) {
                        $scope.groupAnswers1 = result.groupOne.answer;
                        $scope.brandStatements1 = result.groupOne.brandStatements;
                        $scope.tshirts1 = result.groupOne.tshirts;
                        $scope.categories1 = [];
                        $scope.data1 = [];
                        angular.forEach(result.groupOne.answer, function(value, key) {
                            $scope.categories1.push(value.answer);
                            $scope.data1.push(value.count);
                        });

                        /// //
                        $scope.groupAnswers2 = result.groupTwo.answer;
                        $scope.brandStatements2 = result.groupTwo.brandStatements;
                        $scope.tshirts2 = result.groupTwo.tshirts;
                        $scope.categories2 = [];
                        $scope.data2 = [];
                        angular.forEach(result.groupTwo.answer, function(value, key) {
                            $scope.categories2.push(value.answer);
                            $scope.data2.push(value.count);
                        });

                        /// //
                        $scope.groupAnswers3 = result.groupThree.answer;
                        $scope.brandStatements3 = result.groupThree.brandStatements;
                        $scope.tshirts3 = result.groupThree.tshirts;
                        $scope.categories3 = [];
                        $scope.data3 = [];
                        angular.forEach(result.groupThree.answer, function(value, key) {
                            $scope.categories3.push(value.answer);
                            $scope.data3.push(value.count);
                        });

                        /// //
                        $scope.groupAnswers4 = result.groupFour.answer;
                        $scope.brandStatements4 = result.groupFour.brandStatements;
                        $scope.tshirts4 = result.groupFour.tshirts;
                        $scope.categories4 = [];
                        $scope.data4 = [];
                        angular.forEach(result.groupFour.answer, function(value, key) {
                            $scope.categories4.push(value.answer);
                            $scope.data4.push(value.count);
                        });
                        });
                        if ($povGroupID > 0) {
                        if ($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].human_truth) {
                            $human_truth = $localStorage.chosenWorkshop.pyramid[$povGroupID - 1].human_truth;
                        } else {
                            $human_truth = 'Statement for Human Truth here';
                        }

                        if ($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].product_truth) {
                            $product_truth = $localStorage.chosenWorkshop.pyramid[$povGroupID - 1].product_truth;
                        } else {
                            $product_truth = 'Statement for Product Truth here';
                        }

                        if ($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].people_we_serve) {
                            $people_we_serve = $localStorage.chosenWorkshop.pyramid[$povGroupID - 1].people_we_serve;
                        } else {
                            $people_we_serve = 'People We Serve Here';
                        }

                        if ($localStorage.chosenWorkshop.pyramid[$povGroupID - 1].brand_point_of_view) {
                            $brand_point_of_view = $localStorage.chosenWorkshop.pyramid[$povGroupID - 1].brand_point_of_view;
                        } else {
                            $brand_point_of_view = 'Statement for Brand Pov here';
                        }

                        $scope.immersionChat = [];

                        angular.forEach($scope.chosenWorkshop.pyramid[$povGroupID - 1].chat, function(value, key) {
                            if ($rootScope.userEmail == value.email) {
                            is_currentUser = 'true';
                            } else {
                            is_currentUser = 'false';
                            }

                            $message = {
                            id: value.id,
                            message: value.message,
                            name: value.name,
                            user: value.email,
                            timestamp: value.created_at,
                            is_reply: is_currentUser,
                            };
                            $scope.immersionChat.push($message);
                        });
                        } else {
                        if ($scope.progress.pyramid[0].human_truth) {
                            $human_truth = $scope.progress.pyramid[0].human_truth;
                        } else {
                            $human_truth = 'Statement for Human Truth here';
                        }

                        if ($scope.progress.pyramid[0].product_truth) {
                            $product_truth = $scope.progress.pyramid[0].product_truth;
                        } else {
                            $product_truth = 'Statement for Product Truth here';
                        }

                        if ($scope.progress.pyramid[0].people_we_serve) {
                            $people_we_serve = $scope.progress.pyramid[0].people_we_serve;
                        } else {
                            $people_we_serve = 'People We Serve Here';
                        }

                        if ($scope.progress.pyramid[0].brand_point_of_view) {
                            $brand_point_of_view = $scope.progress.pyramid[0].brand_point_of_view;
                        } else {
                            $brand_point_of_view = 'Statement for Brand Pov here';
                        }
                        }

                        $scope.checkAnswers = function() {
                        var otherThanNull = $scope.choiceSet.messages.some(function(el) {
                            return el == null;
                        });
                        return otherThanNull;
                        };

                        $scope.pyramid = {
                        human_truth: $human_truth,
                        product_truth: $product_truth,
                        serve: $people_we_serve,
                        brand_purpose: $brand_point_of_view,
                        };
                        $scope.splitWords = [];
                        $scope.QandA = [];
                        $scope.statements = [];
                        $scope.answerSet = { answers: [] };

                        $rootScope.userEmail = $localStorage.user.email;
                        $scope.answer2 = null;
                        $scope.statement_pov_id = null;
                        $scope.workshop = null;
                        $scope.workshops = $localStorage.workshops;

                        if ($localStorage.selectedWorkshop) {
                            $scope.workshop = $localStorage.selectedWorkshop;
                        } else {
                            $scope.workshop = null;
                        }

                        $scope.others = function() {
                        if ($scope.showOthers) {
                            $scope.showOthers = false;
                        } else {
                            $scope.showOthers = true;
                        }
                        };
                    },
                    function(error) {
                        console.log(error);
                    }
                );
                } else {
                    $scope.chosenWorkshop = null;
                }
            },
            function(error) {
                console.log(error);
            });
		};
	},
});
