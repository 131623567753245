// Set the environment variables
const env = {};

// Import variables if present (from env.js)
if (window) {
	// eslint-disable-next-line angular/window-service
	Object.assign(env, window.__env);
}

// Define AngularJS application
angular
	.module('app', [
		'ngRaven',
		'ui.router',
		'ngAnimate',
		'19degrees.ngSweetAlert2',
		'ngStorage',
		'btorfs.multiselect',
		'720kb.tooltips',
		'pathgather.popeye',
		'ngFileUpload',
		'ngToast',
		'ui.knob',
		'ang-drag-drop',
		'as.sortable',
		'videosharing-embed',
		'colorpicker.module',
		'ui.tinymce',
	])
	.constant('__env', env) // Register environment in AngularJS as constant
	.run(function($rootScope, $state, $transitions, $window, $localStorage, $timeout, $document, sweetAlert, dashboardFactory) {
		$rootScope.version = "6.2.4";
		console.info('Purpose Dive Version ' + $rootScope.version);

		// On successful state load - Object takes 'from' an 'to' states (optional)
		$transitions.onSuccess({}, function() {
			// Check if current state is restricted or not (defined in routes.js)
			var restricted = $state.current.restricted;
			// Go to login page if user object is not present (user has not been authenticated)
			if (restricted && !$localStorage.token) {
				$state.go('login');
			}

			var thisState = $window.location.pathname;
			// Toggle active class on hamburger icon
			if ($('#wrapper').hasClass('is-active')) {
				$('#sidebar-wrapper, #wrapper').toggleClass('is-active');
				$('.hamburger, .hamburger--spin').toggleClass('is-active');
			}

			// Scroll to top of $window on state change
			$window.scrollTo(0, 0);

			$rootScope.facilitatorPopUp = function($id) {
				if ($id === 'last phase') {
					sweetAlert.swal({
						title: 'Success',
						text: 'The facilitator has been notified of your submission. This was the last phase. Congratulations',
						type: 'success',
						allowOutsideClick: false,
						confirmButtonText: 'Done',
					});
				} else {
					sweetAlert.swal({
						title: 'Success',
						text: 'The facilitator has been notified of your submission. Once approved the facilitator will move you to the next phase.',
						type: 'success',
						allowOutsideClick: false,
						confirmButtonText: 'Okay',
					});
				}
			};

			$($document).ready(function() {
				$('.select-pane button').click(function() {
					$('.select-pane button').removeClass('shine');
					$(this).addClass('shine');
				});

				$('#nav-facilitate-tab').click(function(event) {
					event.preventDefault();
					$('.tab-content').removeClass('turquoise');
					$('#nav-edit-content-tab').css({
						'background-color': '#4e535b',
						'border-color': '#4e535b',
					});

					$('.tab-content').addClass('orange');
					$(this).css({
						'background-color': '#f58220',
						'border-color': '#f58220',
					});
					$('#participants').addClass('shine');
					$('#participants').addClass('activeItem');

					$timeout(function() {
						$state.go('dashboard.group-manage');
					});
				});

				$('#nav-edit-content-tab').click(function(event) {
					event.preventDefault();
					$('.tab-content').removeClass('orange');
					$('#nav-facilitate-tab').css({
						'background-color': '#4e535b',
						'border-color': '#4e535b',
					});

					$('.tab-content').addClass('turquoise');
					$(this).css({
						'background-color': '#08b2a6',
						'border-color': '#08b2a6',
					});
					$window.location.href = '/manage-content/immersion';
				});

				var els = $document.find('a');

				for (var i = 0, l = els.length; i < l; i++) {
					var url = $window.location.href;
					if (url.includes('#')) {
						var split = url.split('#');
						// Url = url[0];
						url = split[0];
					}

					if (els[i].href === url) {
						if (
							url === $window.location.origin + '/group-organiser' ||
							url === $window.location.origin + '/group-moderation' ||
							url === $window.location.origin + '/group-results' ||
							url === $window.location.origin + '/group-manage'
						) {
							$('.tab-content').removeClass('turquoise');
							$('#nav-edit-content-tab').css({
								'background-color': '#4e535b',
								'border-color': '#4e535b',
							});

							$('.tab-content').addClass('orange');
							$('#nav-facilitate-tab').css({
								'background-color': '#f58220',
								'border-color': '#f58220',
							});
							$('#edit-content ').removeClass('active');
							$('#edit-content ').removeClass('show');
							$('#facilitate').addClass('active');
							$('#nav-facilitate-tab').addClass('active');
							$('#facilitate').addClass('show');
						}

						$('.select-pane button').removeClass('shine');
						$('#' + els[i].id + ' button').addClass('shine');

						$('.select-pane button').removeClass('activeItem');
						$('#' + els[i].id + ' button').addClass('activeItem');
					}
				}
			});
		});

		$transitions.onStart({}, function($transition) {
			// Do some stuff here if you want
			var fromState = $transition.from();

			if (fromState.url === '/login') {
				$rootScope.isFreshLogin = true;
			} else {
				$rootScope.isFreshLogin = false;
			}
		});

		$rootScope.getProgress = function() {
			dashboardFactory.progress().then(function(result) {
				let current_progress = JSON.parse(result.current_progress);
				let current_game = current_progress.current_game;

				$timeout(function() {
					if (current_game === 1) {
						$state.go('dashboard.immersion-debrief');
					}
					if (current_game === 2) {
						$state.go('dashboard.pws');
					}
					if (current_game === 3) {
						$state.go('dashboard.human-truth');
					}
					if (current_game === 4) {
						$state.go('dashboard.product-truth');
					}
					if (current_game === 5) {
						$state.go('dashboard.brand-pov');
					}
					if (current_game === 6) {
						// $state.go('dashboard.pyramid');
						$state.go('dashboard.brand-pov');
					}
					if (current_game === 7) {
						$state.go('dashboard.pyramid');
					}
				});
			});
		};
		$rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
			routeHistory.push({route: from, routeParams: fromParams});
		});
		// If user then get workshop progress
		/*
		if ($localStorage.user && $localStorage.user.role[0].role_name === 'user') {
			$rootScope.getProgress();
		}
		*/
	})
	.factory('socket', function($rootScope) {
		// var socket = io.connect('http://localhost:8890');
		var socket = io.connect('https://api.purposedive.com', { secure: true });

		return {
			on: function(eventName, callback) {
				socket.on(eventName, function() {
					var args = arguments;
					$rootScope.$apply(function() {
						callback.apply(socket, args);
					});
				});
			},
			emit: function(eventName, data, callback) {
				socket.emit(eventName, data, function() {
					var args = arguments;
					$rootScope.$apply(function() {
						if (callback) {
							callback.apply(socket, args);
						}
					});
				});
			},
		};
	});
