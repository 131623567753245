angular.module('app').component('dashboardHeader', {
	templateUrl: 'app/header.html',
	controllerAs: 'vm',

	controller: function($state, $scope, $localStorage, dashboardFactory, sweetAlert, socket) {
		if (__env.enableDebug) {
			console.log('dashboardHeaderController');
		}

		$scope.role = $localStorage.role;
		$scope.showWorkshopHeader = $localStorage.showWorkshopHeader;

		// Recheck role to overcome race condition
		setTimeout(function(){
			$scope.role = $localStorage.role;
		}, 6000);

		// Notifications
		if($scope.role == "super admin"){

			// Notify facilitator via a popup that a group has requested that they join
			socket.on("requestFacilitatorJoinsChat", function (data) {
				if($localStorage.chosenWorkshop.id && parseInt($localStorage.chosenWorkshop.id) == parseInt(data.wid)){
					sweetAlert.swal({
						title: "Invite",
						html: "<u>Group " + data.gid + "</u> has requested that a facilitator<br/>joins their <u>" + data.game + "</u> game." ,
						type: "info",
					});
				}
			});

      // Notify facilitator via a popup that a group has locked in answers
			socket.on("lockGroupAnswers", function (data){
				if(data.method == "lock" && $localStorage.chosenWorkshop.id && parseInt($localStorage.chosenWorkshop.id) == parseInt(data.wid)){

					if(data.game === "Profiler") data.game = "People Portrait";
					if(data.game === "Human Truth Game") data.game = "Tensions"
					if(data.game === "Product Truth") data.game = "Product Statement"

          sweetAlert.swal({
            title: "Submission",
            html: "<u>Group " + data.gid + "</u> has locked in their answers<br/> for their <u>" + data.game + "</u> game." ,
            type: "info",
          });
        }
			});

		}

		if(angular.isDefined($localStorage.chosenWorkshop)){
			$scope.workshopName = $localStorage.chosenWorkshop.name;
			$scope.is_live = $localStorage.chosenWorkshop.is_live;
		}

		// Receive header title from sibling section
		$scope.$on('headerTitle', function() {

			$(document).ready(function() {
				$('.select-pane button').click(function() {
					$('.select-pane button').removeClass('shine');
					$(this).addClass('shine');

					$('.select-pane button').removeClass('activeItem');
					$(this).addClass('activeItem');
				});
			});

			$('#nav-facilitate-tab').click(function(event) {
				event.preventDefault();
				$('.tab-content').removeClass('turquoise');
				$('#nav-edit-content-tab').css({ 'background-color': '#4e535b', 'border-color': '#4e535b' });

				$('.tab-content').addClass('orange');
				$(this).css({ 'background-color': '#f58220', 'border-color': '#f58220' });
			});

			$('#nav-edit-content-tab').click(function(event) {
				event.preventDefault();
				$('.tab-content').removeClass('orange');
				$('#nav-facilitate-tab').css({ 'background-color': '#4e535b', 'border-color': '#4e535b' });

				$('.tab-content').addClass('turquoise');
				$(this).css({ 'background-color': '#08b2a6', 'border-color': '#08b2a6' });
			});
		});

		// Handle logout
		$scope.logout = function() {
			localStorage.clear();
			$state.go('login');
		};

		// Navigate to home
		$scope.home = function() {
			$scope.showWorkshopHeader = false;
			$localStorage.showWorkshopHeader = false;
			$state.go('dashboard.dashboard');
		};

		// Make workshop live
		$scope.goLive = function() {
			dashboardFactory.goLive($localStorage.chosenWorkshop.id).then(
				function(result) {
					$localStorage.chosenWorkshop = result.group;
					$scope.workshopName = $localStorage.chosenWorkshop.name;
					$scope.is_live = $localStorage.chosenWorkshop.is_live;

					sweetAlert.swal({
						title: "We're Live",
						text: 'The workshop is now live and the participants have been emailed.',
						type: 'success',
					});
				},
				function(error) {
					console.log(error);
				}
			);
		};

		// Store users groups
		if($scope.role == "user" && angular.isDefined($localStorage.chosenWorkshop)){
			var users = $localStorage.chosenWorkshop.user;
			var filtered = users.filter(obj => {
				return obj.email === $localStorage.user.email;
			});

			var userGroups = {};
			if (angular.isDefined(filtered[0].human_truth[0])){
				userGroups['human_truth'] = parseInt(filtered[0].human_truth[0].group_id);
			}
			if (angular.isDefined(filtered[0].pov[0])){
				userGroups['pov'] = parseInt(filtered[0].pov[0].group_id);
			}
			if (angular.isDefined(filtered[0].product_truth[0])){
				userGroups['product_truth'] = parseInt(filtered[0].product_truth[0].group_id);
			}
			if (angular.isDefined(filtered[0].pyramid[0])){
				userGroups['pyramid'] = parseInt(filtered[0].pyramid[0].group_id);
			}
			$localStorage.groups = userGroups;
		}

	},
});
