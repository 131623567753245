/* eslint-disable angular/di */
angular
	.module('app')
	// Remove spaces from string
	.filter('removeSpaces', [
		function() {
			return function(string) {
				if (!angular.isString(string)) {
					return string;
				}
				return string.replace(/[\s]/g, '');
			};
		},
	])

	// Remove spaces from string
	.filter('search', [
		'$http',
		'$timeout',
		function($http, $timeout) {
			return function(input) {
				var test = null;

				var result = $http({
					method: 'GET',
					url: 'http://127.0.0.1:8000/users/names/?email=' + input,
				})
					.then(function(response) {
						test = response.data;
						return test;
					})
					.then(function(error) {
						return error;
					});
				$timeout(function() {
					return test;
				}, 1000);
			};
		},
	])

	// Split string of words seperated by comma into array
	.filter('commaBreak', [
		function() {
			return function(value) {
				if (!value.length) {
					return;
				}
				return value.split(',');
			};
		},
	])

	.filter('trustUrl', function($sce) {
		return function(url) {
			return $sce.trustAsResourceUrl(url);
		};
	});
