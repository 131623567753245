angular.module('app').component('createGroup', {
	templateUrl: 'app/dashboard/group/group-create.html',
	controllerAs: 'vm',

	controller: function(__env, $scope, $rootScope, $localStorage, $window, groupFactory, sweetAlert, titleService, $stateParams) {
		if (__env.enableDebug) {
			console.log('createGroupController');
		}

		// Set this to self to avoid conflicts
		const self = this;
		// Set the basic controller data to be passed to title service
		titleService.data = {
			title: 'Creating Your Workshop',
		};

		// Run when component is ready
		self.$onInit = function() {
			// Set the initial button text
			self.button = 'Create Workshop';
			self.name = null;
		};

		// Handle group create handler
		self.createGroupHandler = function() {
			// Change button text
			self.button = 'Creating Workshop...';

			// Set the input object
			const input = {
				name: self.name,
				is_workshop: 1,
			};

			// Call the groupFactory create method
			groupFactory.create(input).then(
				function(result) {

					// Push this new workshop to localStorage
					$localStorage.user.group.push({
						id: result.group.id,
						is_live: "false",
						name: input.name,
						role: "admin"
					})

					// Assign this new workshop as my chosen workshop
					$localStorage.chosenWorkshop = { id: result.group.id, name: input.name, is_live: "false" }

					// Turn on the selected workshop header bar
					$localStorage.showWorkshopHeader = true;

					// On success redirect to manage group users
					$window.location.href = '/group-manage';
				},
				function(error) {
					self.button = 'Create Workshop';
					// Show error popup
					sweetAlert.swal({
						title: 'Error',
						text: error.data.message.name,
						type: 'error',
					});
				}
			);
		};
	},
});
