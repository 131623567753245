angular.module("app").component("workshopModeration", {
	templateUrl: "app/dashboard/group/group-moderation.html",
	controllerAs: "self",

	controller: function (
		$rootScope,
		$scope,
		$state,
		$timeout,
		$localStorage,
		dashboardFactory,
		socket,
		sweetAlert,
		$http,
		__env
	) {
		// Set this to self to avoid conflicts
		const self = this;

		// Run when component is ready
		self.$onInit = function () {
			$scope.userNames = $rootScope.userNames;
			$scope.collabGame = null;
			$scope.collabGroup = null;
			$scope.humanTruthReady = false;
			$scope.povReady = false;
			$scope.productTruthReady = false;
			$scope.pyramidReady = false;

			$scope.v = false;
			$scope.pyramidUsers = [];
			$scope.humanTruthUsers = [];
			$scope.humanTruthGameUsers = [];
			$scope.povUsers = [];
			$scope.productTruthUsers = [];

			$scope.group1showRemove = false;
			$scope.group2showRemove = false;
			$scope.group3showRemove = false;
			$scope.group4showRemove = false;
			$scope.humanTruthGroup1 = [];
			$scope.humanTruthGroup2 = [];
			$scope.humanTruthGroup3 = [];
			$scope.humanTruthGroup4 = [];

			$scope.group1showRemovePyramid = false;
			$scope.group2showRemovePyramid = false;
			$scope.group3showRemovePyramid = false;
			$scope.group4showRemovePyramid = false;
			$scope.pyramidGroup1 = [];
			$scope.pyramidGroup2 = [];
			$scope.pyramidGroup3 = [];
			$scope.pyramidGroup4 = [];

			$scope.group1showRemovePov = false;
			$scope.group2showRemovePov = false;
			$scope.group3showRemovePov = false;
			$scope.group4showRemovePov = false;
			$scope.povGroup1 = [];
			$scope.povGroup2 = [];
			$scope.povGroup3 = [];
			$scope.povGroup4 = [];

			$scope.group1showRemoveProductTruth = false;
			$scope.group2showRemoveProductTruth = false;
			$scope.group3showRemoveProductTruth = false;
			$scope.group4showRemoveProductTruth = false;
			$scope.productTruthGroup1 = [];
			$scope.productTruthGroup2 = [];
			$scope.productTruthGroup3 = [];
			$scope.productTruthGroup4 = [];

			$scope.group1showRemoveHumanTruthGame = false;
			$scope.group2showRemoveHumanTruthGame = false;
			$scope.group3showRemoveHumanTruthGame = false;
			$scope.group4showRemoveHumanTruthGame = false;
			$scope.HumanTruthGameGroup1 = [];
			$scope.HumanTruthGameGroup2 = [];
			$scope.HumanTruthGameGroup3 = [];
			$scope.HumanTruthGameGroup4 = [];

			dashboardFactory.fetch().then(
				function (result) {
					angular.forEach($scope.groupOptions, function (value, key) {
						if (value.id == $scope.selectedGroupId) {
							value = result.group;
						}
					});
					$scope.selectedGroup = result.group;
					$scope.optionValue = 1;
					$scope.chosenWorkshop = result.group;
					$localStorage.chosenWorkshop = result.group;
					$scope.locks = result.locks;
					$timeout(function () {
						$scope.group = null;
						angular.forEach($scope.selectedGroup.user, function (value, key) {
							if (value.human_truth.length > 0) {
								angular.forEach(value.human_truth, function (value2, key2) {
									if (value2.workshop_id == $scope.selectedGroup.id) {
										$scope.color = value2.tension[0].color;
										if (value2.group_id) {
											$scope.humanTruthReady = true;
											if (value2.group_id === "1") {
												$scope.humanTruthGroup1.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "2") {
												$scope.humanTruthGroup2.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "3") {
												$scope.humanTruthGroup3.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "4") {
												$scope.humanTruthGroup4.push({
													color: $scope.color,
													email: value.email,
												});
											}
										} else {
											$scope.humanTruthReady = true;
											$scope.humanTruthUsers.push({
												color: $scope.color,
												email: value.email,
											});
										}
									}
								});
							}

							if (value.pyramid.length > 0) {
								angular.forEach(value.pyramid, function (value2, key2) {
									if (value2.workshop_id == $scope.selectedGroup.id) {
										if (value2.group_id) {
											$scope.pyramidReady = true;
											if (value2.group_id == 1) {
												$scope.pyramidGroup1.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id == 2) {
												$scope.pyramidGroup2.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id == 3) {
												$scope.pyramidGroup3.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id == 4) {
												$scope.pyramidGroup4.push({
													color: $scope.color,
													email: value.email,
												});
											}
										} else {
											$scope.pyramidReady = true;
											$scope.pyramidUsers.push({
												color: $scope.color,
												email: value.email,
											});
										}
									}
								});
							}

							if (value.pov.length > 0) {
								angular.forEach(value.pov, function (value2, key2) {
									if (value2.workshop_id == $scope.selectedGroup.id) {
										angular.forEach(value2.answer, function (value3, key3) {
											if (value3.pov_question[0].type === "words") {
												$jsonObj = JSON.parse(
													value3.pov_question[0].answer_options
												);
												angular.forEach($jsonObj, function (value4, key4) {
													if (value4.word === value3.answer) {
														$scope.color = value4.color;
													}
												});
											}
										});
										if (value2.group_id) {
											$scope.povReady = true;
											if (value2.group_id === "1") {
												$scope.povGroup1.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "2") {
												$scope.povGroup2.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "3") {
												$scope.povGroup3.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "4") {
												$scope.povGroup4.push({
													color: $scope.color,
													email: value.email,
												});
											}
										} else {
											$scope.povReady = true;
											$scope.povUsers.push({
												color: $scope.color,
												email: value.email,
											});
										}
									}
								});
							}

							// Get product_truth for each user
							if (value.product_truth.length > 0) {
								// Get product_truth values
								angular.forEach(value.product_truth, function (value2, key2) {
									// Check values are for current workshop
									if (value2.workshop_id == $scope.selectedGroup.id) {
										angular.forEach(value2.answer, function (value3, key3) {
											if (value3.question[0] && value3.question[0].type === "words") {
												$jsonObj = JSON.parse(
													value3.question[0].answer_options
												);
												angular.forEach($jsonObj, function (value4, key4) {
													if (value4.word === value3.answer) {
														$scope.color = value4.color;
													}
												});
											}
										});
										if (value2.group_id) {
											$scope.productTruthReady = true;
											if (value2.group_id === "1") {
												$scope.productTruthGroup1.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "2") {
												$scope.productTruthGroup2.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "3") {
												$scope.productTruthGroup3.push({
													color: $scope.color,
													email: value.email,
												});
											}

											if (value2.group_id === "4") {
												$scope.productTruthGroup4.push({
													color: $scope.color,
													email: value.email,
												});
											}
										} else {
											$scope.productTruthReady = true;
											$scope.productTruthUsers.push({
												color: $scope.color,
												email: value.email,
											});
										}
									}
								});
							}
						});
					});
				},
				function (error) {
					console.log(error);
				}
			);
			$scope.finish = function () {
				$state.go("dashboard.dashboard");
			};

			if ($localStorage.user) {
				$scope.user = $localStorage.user;
			}

			$scope.QandA = [];
			$scope.url = __env.apiUrl;
			$scope.statements = [];
			$scope.answerSet = { answers: [] };
			$scope.progress = $localStorage.progress;
			$rootScope.userEmail = $localStorage.user.email;
			$scope.email = $localStorage.user.email;
		};

		// Run when admin joins a group
		$scope.joinGroup = function ($game, $group) {
			$scope.collabGame = $game;
			$scope.collabGroup = $group;

			// People Portrait
			if ($scope.collabGame === "People Portrait") {
				$scope.profilerChat = [];

				// Check if answers are locked
				if($scope.locks['profiler']) $scope.locked = $scope.locks['profiler'][$scope.collabGroup];

				$scope.choiceSetMethod = function ($id) {
					$scope.tensionID = $scope.chosenWorkshop.human_truth_game[$id - 1].id;
					$scope.choiceSet = {
						messages: [],
						editing: [],
						whoIsEditing: [],
						isDisabled: [],
						parts: [],
						questions: [],
						icons: [],
						ids: [],
						names: [],
					};
					angular.forEach(
						$scope.chosenWorkshop.collaboration_group[$id - 1].profiler,
						function (value, key) {
							$scope.choiceSet.messages.push(value.message);
							$scope.choiceSet.editing.push("");
							$scope.choiceSet.whoIsEditing.push("check");
							$scope.choiceSet.isDisabled.push("");
							$scope.choiceSet.parts.push(value.part);
							$scope.choiceSet.ids.push(value.id);
							$scope.choiceSet.questions.push(
								value.profiler_question[0].question
							);
							$scope.choiceSet.icons.push(value.profiler_question[0].icon);
							$scope.choiceSet.names.push("");
						}
					);

					dashboardFactory
						.groupMembers($scope.chosenWorkshop.id, $id, "profiler")
						.then(function (result) {
							$scope.groupMembers = result.members;
							$admin = {
								name: $scope.user.name,
								firstLetterOfSurname: $scope.user.firstLetterOfSurname,
								email: $scope.user.email,
							};
							$scope.groupMembers.push($admin);
							$data = {
								game: "People Portrait",
								user: $admin,
								group: $id,
								workshop: $scope.chosenWorkshop.id,
							};
							socket.emit("adminJoined", $data);
							$timeout(function () {});
						});

					angular.forEach(
						$scope.chosenWorkshop.collaboration_group[$id - 1].chat,
						function (value, key) {
							if ($rootScope.userEmail == value.email) {
								is_currentUser = "true";
							} else {
								is_currentUser = "false";
							}

							$message = {
								id: value.id,
								message: value.message,
								name: value.name,
								user: value.email,
								timestamp: value.created_at,
								is_reply: is_currentUser,
							};
							$scope.profilerChat.push($message);
							$timeout(function () {
								var scroller = document.getElementById("chatscreen");
								scroller.scrollTop = scroller.scrollHeight;
							});
						}
					);
					$timeout(function () {
						socket.emit("check", "first check");
					});
				};

				// User clicks input to edit
				$scope.focusInput = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							$name =
								$localStorage.user.name +
								" " +
								$localStorage.user.firstLetterOfSurname;
							// Broadcasts to to connected sockets that the user is editing an input
							socket.emit("editing", {
								user: $rootScope.userEmail,
								name: $name,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						}
					});
				};

				$scope.passThePen = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							// Broadcasts to to connected sockets that the user is editing an input
							socket.emit("editing", {
								user: null,
								name: null,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						}
					});
				};

				// User wants to unlock a input that another user is editing
				$scope.unlockInput = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							// Show sweet alert with callback
							sweetAlert
								.swal(
									{
										title: "Warning",
										text:
											"Please note by unlocking you are removing " +
											$scope.choiceSet.names[key] +
											" from editing.",
										type: "warning",
									},
									function () {}
								)
								.then(function () {
									$name =
										$localStorage.user.name +
										" " +
										$localStorage.user.firstLetterOfSurname;
									socket.emit("editing", {
										user: $rootScope.userEmail,
										name: $name,
										element: data,
										id: $scope.choiceSet.ids[key],
									});
								});
						}
					});
				};

				// Submit edit of input to the api
				$scope.submitProfiler = function ($part, id, group) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if ($part === value && id == $scope.choiceSet.ids[key]) {
							$scope.input = {
								part: $part,
								message: $scope.choiceSet.messages[key],
								profiler_id: $scope.choiceSet.ids[key],
								group_id: group,
							};
							dashboardFactory.save($scope.input).then(
								function (result) {
									$timeout(function () {
										socket.emit("newProfiler", result.profiler);
									});
								},
								function (error) {
									console.log(error);
								}
							);
						}
					});
				};

				$scope.choiceSetMethod($scope.collabGroup);

				// Sockets
				socket.on("editingCheck", function (data) {
					if (data === "check") {
						angular.forEach($scope.choiceSet.whoIsEditing, function (
							value,
							key
						) {
							if (value != "check") {
								socket.emit("editing", {
									user: value.email,
									name: value.name,
									element: $scope.choiceSet.parts[key],
									id: $scope.choiceSet.ids[key],
								});
							}
						});
					}
				});
				socket.on("editor", function (data) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (
							data.element === value &&
							data.id == $scope.choiceSet.ids[key]
						) {
							$scope.choiceSet.whoIsEditing[key] = data.user;
							$scope.choiceSet.names[key] = data.name;
							if ($scope.choiceSet.whoIsEditing[key] == null) {
								$scope.choiceSet.isDisabled[key] = false;
							} else {
								if (
									$scope.choiceSet.whoIsEditing[key] !== $rootScope.userEmail
								) {
									$scope.choiceSet.isDisabled[key] = true;
								} else {
									$scope.choiceSet.isDisabled[key] = false;
								}

								$scope.choiceSet.editing[key] = false;
							}
						}
					});
				});

				// Socket for chat
				socket.on("recieveProfilerMessage", function (data) {
					if (
						data.collaboration_group[0].id ==
						$scope.chosenWorkshop.collaboration_group[$scope.collabGroup - 1].id
					) {
						if ($rootScope.userEmail == data.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}

						$message = {
							id: data.id,
							message: data.message,
							name: data.name,
							user: data.email,
							timestamp: data.created_at,
							is_reply: is_currentUser,
						};
						$scope.profilerChat.push($message);
						$timeout(function () {
							var scroller = document.getElementById("chatscreen");
							scroller.scrollTop = scroller.scrollHeight;
						});
					}
				});

				// Socket to receive change to an input from another user
				socket.on("profiler", function (data) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data.part === value && data.id == $scope.choiceSet.ids[key]) {
							$scope.choiceSet.messages[key] = data.message;
						}
					});
				});
			}

			// Human Truth Games
			if ($scope.collabGame === "Human Truth") {
				$id = $scope.collabGroup;

				// Check if answers are locked
				if($scope.locks['human-truth-game']) $scope.locked = $scope.locks['human-truth-game'][$scope.collabGroup];

				// Check acgtive state
				$scope.isActive = 3;
				if (
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_4_word_1
				) {
					$scope.isActive = 4;
				}
				if (
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_5_word_1
				) {
					$scope.isActive = 5;
				}
				if (
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_6_word_1
				) {
					$scope.isActive = 6;
				}
				if (
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_7_word_1
				) {
					$scope.isActive = 7;
				}

				// Initialise Variables
				$scope.profilerChat = [];

				$scope.tensionID = $scope.chosenWorkshop.human_truth_game[$id - 1].id;
				$scope.statement_1_shown = "true";
				$scope.statement_1_1 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_1_word_1;
				$scope.statement_1_1_isDisabled = "";
				$scope.statement_1_1_editing = "";
				$scope.statement_1_1_whoIsEditing = "check";
				$scope.statement_1_1_whoIsEditingName = "";

				$scope.statement_1_2 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_1_word_2;
				$scope.statement_1_2_isDisabled = "";
				$scope.statement_1_2_editing = "";
				$scope.statement_1_2_whoIsEditing = "check";
				$scope.statement_1_2_shown = "true";
				$scope.statement_1_2_whoIsEditingName = "";

				$scope.statement_2_shown = "true";
				$scope.statement_2_1 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_2_word_1;
				$scope.statement_2_1_isDisabled = "";
				$scope.statement_2_1_editing = "";
				$scope.statement_2_1_whoIsEditing = "check";
				$scope.statement_2_1_whoIsEditingName = "";

				$scope.statement_2_2 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_2_word_2;
				$scope.statement_2_2_isDisabled = "";
				$scope.statement_2_2_editing = "";
				$scope.statement_2_2_whoIsEditing = "check";
				$scope.statement_2_2_shown = "true";
				$scope.statement_2_2_whoIsEditingName = "";

				$scope.statement_3_shown = "true";
				$scope.statement_3_1 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_3_word_1;
				$scope.statement_3_1_isDisabled = "";
				$scope.statement_3_1_editing = "";
				$scope.statement_3_1_whoIsEditing = "check";
				$scope.statement_3_1_whoIsEditingName = "";

				$scope.statement_3_2 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_3_word_2;
				$scope.statement_3_2_isDisabled = "";
				$scope.statement_3_2_editing = "";
				$scope.statement_3_2_whoIsEditing = "check";
				$scope.statement_3_2_shown = "true";
				$scope.statement_3_2_whoIsEditingName = "";

				$scope.statement_4_shown = "false";
				$scope.statement_4_1 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_4_word_1;
				$scope.statement_4_1_isDisabled = "";
				$scope.statement_4_1_editing = "";
				$scope.statement_4_1_whoIsEditing = "check";
				$scope.statement_4_1_whoIsEditingName = "";

				$scope.statement_4_2 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_4_word_2;
				$scope.statement_4_2_isDisabled = "";
				$scope.statement_4_2_editing = "";
				$scope.statement_4_2_whoIsEditing = "check";
				$scope.statement_4_2_shown = "true";
				$scope.statement_4_2_whoIsEditingName = "";

				$scope.statement_5_shown = "false";
				$scope.statement_5_1 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_5_word_1;
				$scope.statement_5_1_isDisabled = "";
				$scope.statement_5_1_editing = "";
				$scope.statement_5_1_whoIsEditing = "check";
				$scope.statement_5_1_whoIsEditingName = "";

				$scope.statement_5_2 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_5_word_2;
				$scope.statement_5_2_isDisabled = "";
				$scope.statement_5_2_editing = "";
				$scope.statement_5_2_whoIsEditing = "check";
				$scope.statement_5_2_shown = "true";
				$scope.statement_5_2_whoIsEditingName = "";

				$scope.statement_6_shown = "false";
				$scope.statement_6_1 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_6_word_1;
				$scope.statement_6_1_isDisabled = "";
				$scope.statement_6_1_editing = "";
				$scope.statement_6_1_whoIsEditing = "check";
				$scope.statement_6_1_whoIsEditingName = "";

				$scope.statement_6_2 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_6_word_2;
				$scope.statement_6_2_isDisabled = "";
				$scope.statement_6_2_editing = "";
				$scope.statement_6_2_whoIsEditing = "check";
				$scope.statement_6_2_shown = "true";
				$scope.statement_6_2_whoIsEditingName = "";

				$scope.statement_7_shown = "false";
				$scope.statement_7_1 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_7_word_1;
				$scope.statement_7_1_isDisabled = "";
				$scope.statement_7_1_editing = "";
				$scope.statement_7_1_whoIsEditing = "check";
				$scope.statement_7_1_whoIsEditingName = "";

				$scope.statement_7_2 =
					$scope.chosenWorkshop.human_truth_game[$id - 1].statement_7_word_2;
				$scope.statement_7_2_isDisabled = "";
				$scope.statement_7_2_editing = "";
				$scope.statement_7_2_whoIsEditing = "check";
				$scope.statement_7_2_shown = "true";
				$scope.statement_7_2_whoIsEditingName = "";

				socket.emit("check", "first check");

				// Socket to check if any inputs are locked by another user
				socket.on("editingCheck", function (data) {
					if (data === "check") {
						if ($scope.statement_1_1_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_1_1_whoIsEditing,
								name: $scope.statement_1_1_whoIsEditingName,
								element: "statement_1_1",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_1_2_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_1_2_whoIsEditing,
								name: $scope.statement_1_2_whoIsEditingName,
								element: "statement_1_2",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_2_1_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_2_1_whoIsEditing,
								name: $scope.statement_2_1_whoIsEditingName,
								element: "statement_2_1",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_2_2_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_2_2_whoIsEditing,
								name: $scope.statement_2_2_whoIsEditingName,
								element: "statement_2_2",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_3_1_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_3_1_whoIsEditing,
								name: $scope.statement_3_1_whoIsEditingName,
								element: "statement_3_1",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_3_2_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_3_2_whoIsEditing,
								name: $scope.statement_3_2_whoIsEditingName,
								element: "statement_3_2",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_4_1_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_4_1_whoIsEditing,
								name: $scope.statement_4_1_whoIsEditingName,
								element: "statement_4_1",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_4_2_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_4_2_whoIsEditing,
								name: $scope.statement_4_2_whoIsEditingName,
								element: "statement_4_2",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_5_1_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_5_1_whoIsEditing,
								name: $scope.statement_5_1_whoIsEditingName,
								element: "statement_5_1",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_5_2_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_5_2_whoIsEditing,
								name: $scope.statement_5_2_whoIsEditingName,
								element: "statement_5_2",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_6_1_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_6_1_whoIsEditing,
								name: $scope.statement_6_1_whoIsEditingName,
								element: "statement_6_1",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_6_2_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_6_2_whoIsEditing,
								name: $scope.statement_6_2_whoIsEditingName,
								element: "statement_6_2",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_7_1_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_7_1_whoIsEditing,
								name: $scope.statement_7_1_whoIsEditingName,
								element: "statement_7_1",
								id: $scope.tensionID,
							});
						}

						if ($scope.statement_7_2_whoIsEditing != "check") {
							socket.emit("editing", {
								user: $scope.statement_7_2_whoIsEditing,
								name: $scope.statement_7_2_whoIsEditingName,
								element: "statement_7_2",
								id: $scope.tensionID,
							});
						}
					}
				});

				// Fetch all of the selected group's members
				dashboardFactory
					.groupMembers($scope.chosenWorkshop.id, $id, "HumanTruthGame")
					.then(function (result) {
						$scope.groupMembers = result.members;
						$admin = {
							name: $scope.user.name,
							firstLetterOfSurname: $scope.user.firstLetterOfSurname,
							email: $scope.user.email,
						};
						$scope.groupMembers.push($admin);
						$data = {
							game: "Human Truth",
							user: $admin,
							group: $id,
							workshop: $scope.chosenWorkshop.id,
						};
						socket.emit("adminJoined", $data);
					});

				$scope.scrollFunction = function ($id) {
					var scroller = document.getElementById($id);
					scroller.scrollTop = scroller.scrollHeight;
				};

				// Get all of the chat messages
				angular.forEach(
					$scope.chosenWorkshop.human_truth_game[$id - 1].chat,
					function (value, key) {
						if ($rootScope.userEmail == value.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}
						$message = {
							id: value.id,
							message: value.message,
							user: value.email,
							name: value.name,
							timestamp: value.created_at,
							is_reply: is_currentUser,
						};
						$scope.profilerChat.push($message);
						$timeout(function () {
							var scroller = document.getElementById("chatscreen");
							scroller.scrollTop = scroller.scrollHeight;
						});
					}
				);

				// Socket to recieve who is editing an input
				socket.on("editor", function (data) {
					if (data.id == $scope.tensionID) {
						if (data.element === "statement_1_1") {
							$scope.statement_1_1_whoIsEditing = data.user;
							$scope.statement_1_1_whoIsEditingName = data.name;
							if ($scope.statement_1_1_whoIsEditing == null) {
								$scope.statement_1_1_isDisabled = false;
							} else {
								if (
									$scope.statement_1_1_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_1_1_isDisabled = true;
								} else {
									$scope.statement_1_1_isDisabled = false;
								}
								$scope.statement_1_1_editing = false;
							}
						}

						if (data.element === "statement_1_2") {
							$scope.statement_1_2_whoIsEditing = data.user;
							$scope.statement_1_2_whoIsEditingName = data.name;
							if ($scope.statement_1_2_whoIsEditing == null) {
								$scope.statement_1_2_isDisabled = false;
							} else {
								if (
									$scope.statement_1_2_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_1_2_isDisabled = true;
								} else {
									$scope.statement_1_2_isDisabled = false;
								}
								$scope.statement_1_2_editing = false;
							}
						}

						if (data.element === "statement_2_1") {
							$scope.statement_2_1_whoIsEditing = data.user;
							$scope.statement_2_1_whoIsEditingName = data.name;
							if ($scope.statement_2_1_whoIsEditing == null) {
								$scope.statement_2_1_isDisabled = false;
							} else {
								if (
									$scope.statement_2_1_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_2_1_isDisabled = true;
								} else {
									$scope.statement_2_1_isDisabled = false;
								}
								$scope.statement_2_1_editing = false;
							}
						}

						if (data.element === "statement_2_2") {
							$scope.statement_2_2_whoIsEditing = data.user;
							$scope.statement_2_2_whoIsEditingName = data.name;
							if ($scope.statement_2_2_whoIsEditing == null) {
								$scope.statement_2_2_isDisabled = false;
							} else {
								if (
									$scope.statement_2_2_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_2_2_isDisabled = true;
								} else {
									$scope.statement_2_2_isDisabled = false;
								}
								$scope.statement_2_2_editing = false;
							}
						}

						if (data.element === "statement_3_1") {
							$scope.statement_3_1_whoIsEditing = data.user;
							$scope.statement_3_1_whoIsEditingName = data.name;
							if ($scope.statement_3_1_whoIsEditing == null) {
								$scope.statement_3_1_isDisabled = false;
							} else {
								if (
									$scope.statement_3_1_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_3_1_isDisabled = true;
								} else {
									$scope.statement_3_1_isDisabled = false;
								}
								$scope.statement_3_1_editing = false;
							}
						}

						if (data.element === "statement_3_2") {
							$scope.statement_3_2_whoIsEditing = data.user;
							$scope.statement_3_2_whoIsEditingName = data.name;
							if ($scope.statement_3_2_whoIsEditing == null) {
								$scope.statement_3_2_isDisabled = false;
							} else {
								if (
									$scope.statement_3_2_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_3_2_isDisabled = true;
								} else {
									$scope.statement_3_2_isDisabled = false;
								}
								$scope.statement_3_2_editing = false;
							}
						}

						if (data.element === "statement_4_1") {
							$scope.statement_4_1_whoIsEditing = data.user;
							$scope.statement_4_1_whoIsEditingName = data.name;
							if ($scope.statement_4_1_whoIsEditing == null) {
								$scope.statement_4_1_isDisabled = false;
							} else {
								if (
									$scope.statement_4_1_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_4_1_isDisabled = true;
								} else {
									$scope.statement_4_1_isDisabled = false;
								}
								$scope.statement_4_1_editing = false;
							}
						}

						if (data.element === "statement_4_2") {
							$scope.statement_4_2_whoIsEditing = data.user;
							$scope.statement_4_2_whoIsEditingName = data.name;
							if ($scope.statement_4_2_whoIsEditing == null) {
								$scope.statement_4_2_isDisabled = false;
							} else {
								if (
									$scope.statement_4_2_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_4_2_isDisabled = true;
								} else {
									$scope.statement_4_2_isDisabled = false;
								}
								$scope.statement_4_2_editing = false;
							}
						}

						if (data.element === "statement_5_1") {
							$scope.statement_5_1_whoIsEditing = data.user;
							$scope.statement_5_1_whoIsEditingName = data.name;
							if ($scope.statement_5_1_whoIsEditing == null) {
								$scope.statement_5_1_isDisabled = false;
							} else {
								if (
									$scope.statement_5_1_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_5_1_isDisabled = true;
								} else {
									$scope.statement_5_1_isDisabled = false;
								}
								$scope.statement_5_1_editing = false;
							}
						}

						if (data.element === "statement_5_2") {
							$scope.statement_5_2_whoIsEditing = data.user;
							$scope.statement_5_2_whoIsEditingName = data.name;
							if ($scope.statement_5_2_whoIsEditing == null) {
								$scope.statement_5_2_isDisabled = false;
							} else {
								if (
									$scope.statement_5_2_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_5_2_isDisabled = true;
								} else {
									$scope.statement_5_2_isDisabled = false;
								}
								$scope.statement_5_2_editing = false;
							}
						}

						if (data.element === "statement_6_1") {
							$scope.statement_6_1_whoIsEditing = data.user;
							$scope.statement_6_1_whoIsEditingName = data.name;
							if ($scope.statement_6_1_whoIsEditing == null) {
								$scope.statement_6_1_isDisabled = false;
							} else {
								if (
									$scope.statement_6_1_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_6_1_isDisabled = true;
								} else {
									$scope.statement_6_1_isDisabled = false;
								}
								$scope.statement_6_1_editing = false;
							}
						}

						if (data.element === "statement_6_2") {
							$scope.statement_6_2_whoIsEditing = data.user;
							$scope.statement_6_2_whoIsEditingName = data.name;
							if ($scope.statement_6_2_whoIsEditing == null) {
								$scope.statement_6_2_isDisabled = false;
							} else {
								if (
									$scope.statement_6_2_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_6_2_isDisabled = true;
								} else {
									$scope.statement_6_2_isDisabled = false;
								}
								$scope.statement_6_2_editing = false;
							}
						}

						if (data.element === "statement_7_1") {
							$scope.statement_7_1_whoIsEditing = data.user;
							$scope.statement_7_1_whoIsEditingName = data.name;
							if ($scope.statement_7_1_whoIsEditing == null) {
								$scope.statement_7_1_isDisabled = false;
							} else {
								if (
									$scope.statement_7_1_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_7_1_isDisabled = true;
								} else {
									$scope.statement_7_1_isDisabled = false;
								}
								$scope.statement_7_1_editing = false;
							}
						}

						if (data.element === "statement_7_2") {
							$scope.statement_7_2_whoIsEditing = data.user;
							$scope.statement_7_2_whoIsEditingName = data.name;
							if ($scope.statement_7_2_whoIsEditing == null) {
								$scope.statement_7_2_isDisabled = false;
							} else {
								if (
									$scope.statement_7_2_whoIsEditing !== $rootScope.userEmail
								) {
									$scope.statement_7_2_isDisabled = true;
								} else {
									$scope.statement_7_2_isDisabled = false;
								}
								$scope.statement_7_2_editing = false;
							}
						}
					}
				});

				// User wants to unlock a input that another user is editing
				$scope.unlockInput = function (data) {
					$scope.previousEditor = null;
					// Show sweet alert with callback
					if (data === "statement_1_1") {
						$scope.previousEditor = $scope.statement_1_1_whoIsEditingName;
					}

					if (data === "statement_1_2") {
						$scope.previousEditor = $scope.statement_1_2_whoIsEditingName;
					}

					if (data === "statement_2_1") {
						$scope.previousEditor = $scope.statement_2_1_whoIsEditingName;
					}

					if (data === "statement_2_2") {
						$scope.previousEditor = $scope.statement_2_2_whoIsEditingName;
					}

					if (data === "statement_3_1") {
						$scope.previousEditor = $scope.statement_3_1_whoIsEditingName;
					}

					if (data === "statement_3_2") {
						$scope.previousEditor = $scope.statement_3_2_whoIsEditingName;
					}

					if (data === "statement_4_1") {
						$scope.previousEditor = $scope.statement_4_1_whoIsEditingName;
					}

					if (data === "statement_4_2") {
						$scope.previousEditor = $scope.statement_4_2_whoIsEditingName;
					}

					if (data === "statement_5_1") {
						$scope.previousEditor = $scope.statement_5_1_whoIsEditingName;
					}

					if (data === "statement_5_2") {
						$scope.previousEditor = $scope.statement_5_2_whoIsEditingName;
					}

					if (data === "statement_6_1") {
						$scope.previousEditor = $scope.statement_6_1_whoIsEditingName;
					}

					if (data === "statement_6_2") {
						$scope.previousEditor = $scope.statement_6_2_whoIsEditingName;
					}

					if (data === "statement_7_1") {
						$scope.previousEditor = $scope.statement_7_1_whoIsEditingName;
					}

					if (data === "statement_7_2") {
						$scope.previousEditor = $scope.statement_7_2_whoIsEditingName;
					}

					sweetAlert
						.swal(
							{
								title: "Warning",
								text:
									"Please note by unlocking you are removing " +
									$scope.previousEditor +
									" from editing.",
								type: "warning",
							},
							function () {}
						)
						.then(function () {
							$name =
								$localStorage.user.name +
								" " +
								$localStorage.user.firstLetterOfSurname;
							socket.emit("editing", {
								user: $rootScope.userEmail,
								name: $name,
								element: data,
								id: $scope.tensionID,
							});
						});
				};

				$scope.activeCheck = function () {
					if ($scope.statement_4_shown === "false") {
						data = {
							field: "statement_4_shown",
							id: $scope.tensionID,
						};
						socket.emit("showTension", data);
						return;
					}

					if ($scope.statement_5_shown === "false") {
						data = {
							field: "statement_5_shown",
							id: $scope.tensionID,
						};
						socket.emit("showTension", data);
						return;
					}

					if ($scope.statement_6_shown === "false") {
						data = {
							field: "statement_6_shown",
							id: $scope.tensionID,
						};
						socket.emit("showTension", data);
						return;
					}

					if ($scope.statement_7_shown === "false") {
						data = {
							field: "statement_7_shown",
							id: $scope.tensionID,
						};
						socket.emit("showTension", data);
					}
				};

				$scope.showTension = function () {
					$scope.isActive++;
					$scope.activeCheck();
				};

				$scope.hideQuestion = function ($id) {
					$scope.isActive--;
					switch ($id) {
						case 4:
							$scope.scrollFunction("game-scroll");
							data = {
								field: "statement_4_shown",
								id: $scope.tensionID,
							};
							socket.emit("hideTension", data);
							break;
						case 5:
							$scope.scrollFunction("game-scroll");
							data = {
								field: "statement_5_shown",
								id: $scope.tensionID,
							};
							socket.emit("hideTension", data);
							break;
						case 6:
							$scope.scrollFunction("game-scroll");
							data = {
								field: "statement_6_shown",
								id: $scope.tensionID,
							};
							socket.emit("hideTension", data);
							break;
						case 7:
							$scope.scrollFunction("game-scroll");
							data = {
								field: "statement_7_shown",
								id: $scope.tensionID,
							};
							socket.emit("hideTension", data);
							break;
						default:
							$scope.scrollFunction("game-scroll");
						// Socket push
					}
				};

				$scope.submitTension = function ($field, $value) {
					$scope.input = {
						field: $field,
						value: $value,
						id: $scope.tensionID,
					};
					dashboardFactory.saveTension($scope.input).then(
						function (result) {
							$timeout(function () {
								socket.emit("newTension", result.HumanTruthGame);
							});
						},
						function (error) {
							console.log(error);
						}
					);
				};

				socket.on("recieveTension", function (data) {
					if (data.id == $scope.tensionID) {
						$scope.statement_1_1 = data.statement_1_word_1;
						$scope.statement_1_2 = data.statement_1_word_2;
						$scope.statement_2_1 = data.statement_2_word_1;
						$scope.statement_2_2 = data.statement_2_word_2;
						$scope.statement_3_1 = data.statement_3_word_1;
						$scope.statement_3_2 = data.statement_3_word_2;
						$scope.statement_4_1 = data.statement_4_word_1;
						$scope.statement_4_2 = data.statement_4_word_2;
						$scope.statement_5_1 = data.statement_5_word_1;
						$scope.statement_5_2 = data.statement_5_word_2;
						$scope.statement_6_1 = data.statement_6_word_1;
						$scope.statement_6_2 = data.statement_6_word_2;
						$scope.statement_7_1 = data.statement_7_word_1;
						$scope.statement_7_2 = data.statement_7_word_2;
					}
				});

				socket.on("hiddenTension", function (data) {
					if (data.id == $scope.tensionID) {
						if (data.field === "statement_4_shown") {
							$scope.statement_4_shown = "false";
						}

						if (data.field === "statement_5_shown") {
							$scope.statement_5_shown = "false";
						}

						if (data.field === "statement_6_shown") {
							$scope.statement_6_shown = "false";
						}

						if (data.field === "statement_7_shown") {
							$scope.statement_7_shown = "false";
						}
					}
				});

				socket.on("shownTension", function (data) {
					if (data.id == $scope.tensionID) {
						if (data.field === "statement_4_shown") {
							$scope.statement_4_shown = "true";
						}
						if (data.field === "statement_5_shown") {
							$scope.statement_5_shown = "true";
						}
						if (data.field === "statement_6_shown") {
							$scope.statement_6_shown = "true";
						}
						if (data.field === "statement_7_shown") {
							$scope.statement_7_shown = "true";
						}
					}
				});

				// Socket for chat
				socket.on("recieveTensionMessage", function (data) {
					if (data.human_truth_game[0].id == $scope.tensionID) {
						if ($rootScope.userEmail == data.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}

						$message = {
							id: data.id,
							message: data.message,
							user: data.email,
							name: data.name,
							timestamp: data.created_at,
							is_reply: is_currentUser,
						};
						$scope.profilerChat.push($message);
						$timeout(function () {
							var scroller = document.getElementById("chatscreen");
							scroller.scrollTop = scroller.scrollHeight;
						});
					}
				});

				// User unlocks input for editing
				$scope.passThePen = function (data) {
					socket.emit("editing", {
						user: null,
						name: null,
						element: data,
						id: $scope.tensionID,
					});
				};

				// User clicks input to edit
				$scope.focusInput = function (data) {
					$name =
						$localStorage.user.name +
						" " +
						$localStorage.user.firstLetterOfSurname;
					socket.emit("editing", {
						user: $rootScope.userEmail,
						name: $name,
						element: data,
						id: $scope.tensionID,
					});
				};
			}

			// Product Statement
			if ($scope.collabGame === "Product Truth") {
				$scope.editing = false;
				$scope.isDisabled = false;
				$scope.whoIsEditing = "check";
				$scope.EditorName = null;
				$scope.statements = [];

				// Check if answers are locked
				if($scope.locks['product-truth']) $scope.locked = $scope.locks['product-truth'][$scope.collabGroup];

				angular.forEach($scope.chosenWorkshop.statement, function (value, key) {
					if (value.game === "product_truth") {
						$scope.statements.push(value);
					}
				});
				$timeout(function () {
					$localStorage.statement_id =
						$scope.statements[$scope.collabGroup - 1].id;
					$scope.statement_id = $localStorage.statement_id;
					$scope.statement = null;
					$scope.statementChat = [];
					// Get statement for group
					dashboardFactory.fetchStatement($localStorage.statement_id).then(
						function (result) {
							$localStorage.statement = result.statement;
							$scope.statement = result.statement;
							angular.forEach(result.statement.chat, function (value, key) {
								if ($rootScope.userEmail == value.email) {
									is_currentUser = "true";
								} else {
									is_currentUser = "false";
								}

								$message = {
									id: value.id,
									message: value.message,
									name: value.name,
									user: value.email,
									timestamp: value.created_at,
									is_reply: is_currentUser,
								};
								$scope.statementChat.push($message);
								$timeout(function () {
									var scroller = document.getElementById("chatscreen");
									scroller.scrollTop = scroller.scrollHeight;
								});
							});
							$timeout(function () {
								socket.emit("checkStatement", "first check");
							});
						},
						function (error) {
							console.log(error);
						}
					);

					dashboardFactory
						.groupMembers(
							$localStorage.chosenWorkshop.id,
							$scope.collabGroup,
							"product"
						)
						.then(
							function (result) {
								$scope.groupMembers = result.members;
								$admin = {
									name: $scope.user.name,
									firstLetterOfSurname: $scope.user.firstLetterOfSurname,
									email: $scope.user.email,
								};
								$scope.groupMembers.push($admin);
								$data = {
									game: "Product Truth",
									user: $admin,
									group: $scope.collabGroup,
									workshop: $scope.chosenWorkshop.id,
								};
								socket.emit("adminJoined", $data);
								$timeout(function () {});
							},
							function (error) {
								console.log(error);
							}
						);
				});
				// User clicks input to edit
				$scope.focusInput = function (id) {
					if (id == $localStorage.statement.id) {
						$name =
							$localStorage.user.name +
							" " +
							$localStorage.user.firstLetterOfSurname;
						// Broadcasts to to connected sockets that the user is editing an input
						socket.emit("editingStatement", {
							user: $rootScope.userEmail,
							name: $name,
							id: $localStorage.statement.id,
						});
					}
				};

				$scope.passThePen = function (id) {
					if (id == $localStorage.statement.id) {
						// Broadcasts to to connected sockets that the user is editing an input
						socket.emit("editingStatement", {
							user: null,
							name: null,
							id: $localStorage.statement.id,
						});
					}
				};

				// User wants to unlock a input that another user is editing
				$scope.unlockInput = function (id) {
					if (id == $localStorage.statement.id) {
						// Show sweet alert with callback
						sweetAlert
							.swal(
								{
									title: "Warning",
									text:
										"Please note by unlocking you are removing " +
										$scope.EditorName +
										" from editing.",
									type: "warning",
								},
								function () {}
							)
							.then(function () {
								$name =
									$localStorage.user.name +
									" " +
									$localStorage.user.firstLetterOfSurname;
								socket.emit("editingStatement", {
									user: $rootScope.userEmail,
									name: $name,
									id: $localStorage.statement.id,
								});
							});
					}
				};

				// Socket for statement
				socket.on("recieveStatement", function (data) {
					if (data.id == $localStorage.statement_id) {
						$scope.statement = data;
					}
				});

				// Socket for chat
				socket.on("recieveStatementMessage", function (data) {
					if (data.statement[0].id == $localStorage.statement_id) {
						if ($rootScope.userEmail == data.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}

						$message = {
							id: data.id,
							message: data.message,
							name: data.name,
							user: data.email,
							timestamp: data.created_at,
							is_reply: is_currentUser,
						};
						$scope.statementChat.push($message);
						$timeout(function () {
							var scroller = document.getElementById("chatscreen");
							scroller.scrollTop = scroller.scrollHeight;
						});
					}
				});
				// Socket to check if any inputs are locked by another user
				socket.on("editingCheckStatement", function (data) {
					if (data === "check") {
						if ($scope.whoIsEditing != "check") {
							socket.emit("editingStatement", {
								user: $scope.whoIsEditing,
								name: $scope.EditorName,
								id: $localStorage.statement.id,
							});
						}
					}
				});
				// Socket to recieve who is editing an input
				socket.on("editorStatement", function (data) {
					if (data.id == $localStorage.statement.id) {
						$scope.whoIsEditing = data.user;
						$scope.EditorName = data.name;
						if ($scope.whoIsEditing == null) {
							$scope.isDisabled = false;
						} else {
							if ($scope.whoIsEditing !== $rootScope.userEmail) {
								$scope.isDisabled = true;
							} else {
								$scope.isDisabled = false;
							}

							$scope.editing = false;
						}
					}
				});
				$scope.submitStatement = function ($message, group) {
					$scope.input = {
						message: $message,
						statement_id: $localStorage.statement_id,
						group_id: group,
					};
					dashboardFactory.saveStatement($scope.input).then(
						function (result) {
							$timeout(function () {
								socket.emit("newStatement", result.statement);
							});
						},
						function (error) {
							console.log(error);
						}
					);
				};
			}

			// Brand Manifesto
			if ($scope.collabGame === "Brand POV") {

				// Check if answers are locked
				if($scope.locks['brand-manifesto']) $scope.locked = $scope.locks['brand-manifesto'][$scope.collabGroup];

				// Socket to recieve who is editing an input
				socket.on("editor", function (data) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (
							data.element === value &&
							data.id == $scope.choiceSet.ids[key]
						) {
							$scope.choiceSet.whoIsEditing[key] = data.user;
							$scope.choiceSet.names[key] = data.name;
							if ($scope.choiceSet.whoIsEditing[key] == null) {
								$scope.choiceSet.isDisabled[key] = false;
							} else {
								if (
									$scope.choiceSet.whoIsEditing[key] !== $rootScope.userEmail
								) {
									$scope.choiceSet.isDisabled[key] = true;
								} else {
									$scope.choiceSet.isDisabled[key] = false;
								}

								$scope.choiceSet.editing[key] = false;
							}
						}
					});
				});
				$scope.choiceSet = {
					messages: [],
					editing: [],
					whoIsEditing: [],
					isDisabled: [],
					parts: [],
					questions: [],
					ids: [],
					names: [],
				};
				$scope.editing = false;
				$scope.isDisabled = false;
				$scope.whoIsEditing = "check";
				$scope.statements = [];
				angular.forEach($localStorage.chosenWorkshop.statement, function (
					value,
					key
				) {
					if (value.game === "pov") {
						$scope.statements.push(value);
					}
				});
				$scope.groupPov = $scope.collabGroup;
				$localStorage.statement_id = $scope.statements[$scope.groupPov - 1].id;
				$scope.showDetails = true;
				dashboardFactory
					.groupMembers(
						$localStorage.chosenWorkshop.id,
						$scope.collabGroup,
						"pov"
					)
					.then(
						function (result) {
							$scope.groupMembers = result.members;
							$admin = {
								name: $scope.user.name,
								firstLetterOfSurname: $scope.user.firstLetterOfSurname,
								email: $scope.user.email,
							};
							$scope.groupMembers.push($admin);
							$data = {
								game: "Brand POV",
								user: $admin,
								group: $scope.collabGroup,
								workshop: $scope.chosenWorkshop.id,
							};
							socket.emit("adminJoined", $data);
							$timeout(function () {});
						},
						function (error) {
							console.log(error);
						}
					);
				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].message1
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("message1");
				$scope.choiceSet.questions.push($scope.chosenWorkshop.bp_statement_1);
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
				);
				$scope.choiceSet.names.push("");

				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].message2
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("message2");
				$scope.choiceSet.questions.push($scope.chosenWorkshop.bp_statement_2);
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
				);
				$scope.choiceSet.names.push("");

				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].message3
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("message3");
				$scope.choiceSet.questions.push($scope.chosenWorkshop.bp_statement_3);
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
				);
				$scope.choiceSet.names.push("");

				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].message4
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("message4");
				$scope.choiceSet.questions.push($scope.chosenWorkshop.bp_statement_4);
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
				);
				$scope.choiceSet.names.push("");

				$scope.choiceSet.messages[3] = $scope.choiceSet.questions[0];
				if ($scope.choiceSet.messages[0]) {
					$scope.choiceSet.messages[3] =
						$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[0];
				} else {
					$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
				}

				$scope.choiceSet.messages[3] =
					$scope.choiceSet.messages[3] + " " + $scope.choiceSet.questions[1];
				if ($scope.choiceSet.messages[1]) {
					$scope.choiceSet.messages[3] =
						$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[1];
				} else {
					$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
				}

				$scope.choiceSet.messages[3] =
					$scope.choiceSet.messages[3] + " " + $scope.choiceSet.questions[2];
				if ($scope.choiceSet.messages[2]) {
					$scope.choiceSet.messages[3] =
						$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[2];
				} else {
					$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
				}

				$scope.manifestoChat = [];
				angular.forEach(
					$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].chat,
					function (value, key) {
						if ($rootScope.userEmail == value.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}

						$message = {
							id: value.id,
							message: value.message,
							name: value.name,
							user: value.email,
							timestamp: value.created_at,
							is_reply: is_currentUser,
						};
						$scope.manifestoChat.push($message);
					}
				);
				$timeout(function () {
					socket.emit("checkBrandManifesto", "First Check");
					socket.emit("checkStatementPov", "first check");
				});
				// Socket for statement
				socket.on("recieveStatement", function (data) {
					if (data.id == $localStorage.statement_pov_id) {
						$scope.statement = data;
					}
				});

				socket.on("editingBrandManifesto", function (data) {
					if (data === "check") {
						angular.forEach($scope.choiceSet.whoIsEditing, function (
							value,
							key
						) {
							if (value != "check") {
								socket.emit("editing", {
									user: value,
									name: $scope.choiceSet.names[key],
									element: $scope.choiceSet.parts[key],
									id: $scope.choiceSet.ids[key],
								});
							}
						});
					}
				});
				// Socket for chat
				socket.on("recieveManifestoMessage", function (data) {
					if (
						data.manifesto[0].id ==
						$localStorage.chosenWorkshop.manifesto[$scope.groupPov - 1].id
					) {
						if ($rootScope.userEmail == data.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}

						$message = {
							id: data.id,
							message: data.message,
							name: data.name,
							user: data.email,
							timestamp: data.created_at,
							is_reply: is_currentUser,
						};
						$scope.manifestoChat.push($message);
						$timeout(function () {
							var scroller = document.getElementById("chatscreen");
							scroller.scrollTop = scroller.scrollHeight;
						});
					}
				});

				// User clicks input to edit
				$scope.focusInput = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							$name =
								$localStorage.user.name +
								" " +
								$localStorage.user.firstLetterOfSurname;
							// Broadcasts to to connected sockets that the user is editing an input
							socket.emit("editing", {
								user: $rootScope.userEmail,
								name: $name,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						}
					});
				};

				$scope.passThePen = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							// Broadcasts to to connected sockets that the user is editing an input
							socket.emit("editing", {
								user: null,
								name: null,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						}
					});
				};

				// User wants to unlock a input that another user is editing
				$scope.unlockInput = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							// Show sweet alert with callback
							sweetAlert
								.swal(
									{
										title: "Warning",
										text:
											"Please note by unlocking you are removing " +
											$scope.choiceSet.names[key] +
											" from editing.",
										type: "warning",
									},
									function () {}
								)
								.then(function () {
									$name =
										$localStorage.user.name +
										" " +
										$localStorage.user.firstLetterOfSurname;
									socket.emit("editing", {
										user: $rootScope.userEmail,
										name: $name,
										element: data,
										id: $scope.choiceSet.ids[key],
									});
								});
						}
					});
				};

				// Socket to receive change to an input from another user
				socket.on("profiler", function (data) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data.part === value && data.id == $scope.choiceSet.ids[key]) {
							$scope.choiceSet.messages[key] = data.message;
							$scope.newMessage =
								$scope.choiceSet.questions[key] +
								$scope.choiceSet.messages[key];
						}
					});

					$scope.choiceSet.messages[3] = $scope.choiceSet.questions[0];
					if ($scope.choiceSet.messages[0]) {
						$scope.choiceSet.messages[3] =
							$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[0];
					} else {
						$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
					}

					$scope.choiceSet.messages[3] =
						$scope.choiceSet.messages[3] + " " + $scope.choiceSet.questions[1];
					if ($scope.choiceSet.messages[1]) {
						$scope.choiceSet.messages[3] =
							$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[1];
					} else {
						$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
					}

					$scope.choiceSet.messages[3] =
						$scope.choiceSet.messages[3] + " " + $scope.choiceSet.questions[2];
					if ($scope.choiceSet.messages[2]) {
						$scope.choiceSet.messages[3] =
							$scope.choiceSet.messages[3] + " " + $scope.choiceSet.messages[2];
					} else {
						$scope.choiceSet.messages[3] = $scope.choiceSet.messages[3] + " ";
					}

					$scope.tshirtmanifest = $scope.choiceSet.messages[3];
				});
				$scope.statement = null;
				$scope.statementChat = [];
				// Get statement for group
				$scope.getStatement = function () {
					if ($localStorage.statement_pov_id) {
						// Get Statement
						dashboardFactory
							.fetchStatement($localStorage.statement_pov_id)
							.then(
								function (result) {
									$localStorage.statement = result.statement;
									$scope.statement = result.statement;
									angular.forEach(result.statement.chat, function (value, key) {
										if ($rootScope.userEmail == value.email) {
											is_currentUser = "true";
										} else {
											is_currentUser = "false";
										}

										$message = {
											id: value.id,
											message: value.message,
											name: value.name,
											user: value.email,
											timestamp: value.created_at,
											is_reply: is_currentUser,
										};
										$scope.statementChat.push($message);
										$timeout(function () {
											var scroller = document.getElementById("chatscreen");
											scroller.scrollTop = scroller.scrollHeight;
										});
									});
									$timeout(function () {
										socket.emit("checkStatementPov", "first check");
									});
								},
								function (error) {
									console.log(error);
								}
							);
					}
				};

				$scope.getStatement();

				// Socket to check if any inputs are locked by another user
				socket.on("editingCheckStatementPov", function (data) {
					if (data === "check") {
						if ($scope.whoIsEditing != "check") {
							socket.emit("editingStatementPov", {
								user: $scope.whoIsEditing,
								id: $localStorage.statement.id,
							});
						}
					}
				});

				// Socket to recieve who is editing an input
				socket.on("editorStatementPov", function (data) {
					if (data.id == $localStorage.statement.id) {
						$scope.whoIsEditing = data.user;

						if ($scope.whoIsEditing == null) {
							$scope.isDisabled = false;
						} else {
							if ($scope.whoIsEditing !== $rootScope.userEmail) {
								$scope.isDisabled = true;
							} else {
								$scope.isDisabled = false;
							}

							$scope.editing = false;
						}
					}
				});

				// Submit edit of input to the api
				$scope.submitManifesto = function ($part, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if ($part === value && id == $scope.choiceSet.ids[key]) {
							$scope.input = {
								part: $part,
								message: $scope.choiceSet.messages[key],
								profiler_id: $scope.choiceSet.ids[key],
								group_id: $scope.collabGroup,
							};
							dashboardFactory.saveManifesto($scope.input).then(
								function (result) {
									$timeout(function () {
										socket.emit("newProfiler", result.profiler);
									});
								},
								function (error) {
									console.log(error);
								}
							);
						}
					});

					$scope.input = {
						part: $scope.choiceSet.parts[3],
						message: $scope.choiceSet.messages[3],
						profiler_id: $scope.choiceSet.ids[3],
						group_id: $scope.collabGroup,
					};

					dashboardFactory.saveManifesto($scope.input).then(
						function (result) {
							$timeout(function () {
								socket.emit("newProfiler", result.profiler);
							});
						},
						function (error) {
							console.log(error);
						}
					);
				};
			}

			// Believer's Pyramid
			if ($scope.collabGame === "Believers Pyramid") {

				// Check if answers are locked
				if($scope.locks['group-pyramid']) $scope.locked = $scope.locks['group-pyramid'][$scope.collabGroup];

				dashboardFactory
					.groupMembers(
						$localStorage.chosenWorkshop.id,
						$scope.collabGroup,
						"pyramid"
					)
					.then(
						function (result) {
							$scope.groupMembers = result.members;
							$admin = {
								name: $scope.user.name,
								firstLetterOfSurname: $scope.user.firstLetterOfSurname,
								email: $scope.user.email,
							};
							$scope.groupMembers.push($admin);
							$data = {
								game: "Believers Pyramid",
								user: $admin,
								group: $scope.collabGroup,
								workshop: $scope.chosenWorkshop.id,
							};
							socket.emit("adminJoined", $data);
						},
						function (error) {
							console.log(error);
						}
					);

				$scope.editing = false;
				$scope.isDisabled = false;
				$scope.whoIsEditing = "check";
				$scope.povGroupID = $scope.collabGroup;

				// Build the pyramid
				$scope.choiceSet = {
					messages: [],
					editing: [],
					whoIsEditing: [],
					isDisabled: [],
					parts: [],
					questions: [],
					ids: [],
					names: [],
				};
				// Build Pyramid - People We Serve
				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.people_we_serve
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("people_we_serve");
				$scope.choiceSet.questions.push("People We Serve");
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1].id
				);
				$scope.choiceSet.names.push("");
				// Build Pyramid - Human Truth
				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.human_truth
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("human_truth");
				$scope.choiceSet.questions.push("Human Truth");
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1].id
				);
				$scope.choiceSet.names.push("");
				// Build Pyramid - Brand Point of View
				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.brand_point_of_view
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("brand_point_of_view");
				$scope.choiceSet.questions.push("Brand Point Of View");
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1].id
				);
				$scope.choiceSet.names.push("");
				// Build Pyramid - Product Truth
				$scope.choiceSet.messages.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.product_truth
				);
				$scope.choiceSet.editing.push("");
				$scope.choiceSet.whoIsEditing.push("check");
				$scope.choiceSet.isDisabled.push("");
				$scope.choiceSet.parts.push("product_truth");
				$scope.choiceSet.questions.push("Product Truth");
				$scope.choiceSet.ids.push(
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1].id
				);
				$scope.choiceSet.names.push("");
				// Validation
				if (
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.human_truth
				) {
					$human_truth =
						$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
							.human_truth;
				} else {
					$human_truth = "Statement for Human Truth here";
				}
				if (
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.product_truth
				) {
					$product_truth =
						$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
							.product_truth;
				} else {
					$product_truth = "Statement for Product Truth here";
				}
				if (
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.people_we_serve
				) {
					$people_we_serve =
						$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
							.people_we_serve;
				} else {
					$people_we_serve = "People We Serve Here";
				}
				if (
					$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
						.brand_point_of_view
				) {
					$brand_point_of_view =
						$localStorage.chosenWorkshop.pyramid[$scope.povGroupID - 1]
							.brand_point_of_view;
				} else {
					$brand_point_of_view = "Statement for Brand Pov here";
				}
				// Final built pyramid object
				$scope.pyramid = {
					human_truth: $human_truth,
					product_truth: $product_truth,
					serve: $people_we_serve,
					brand_purpose: $brand_point_of_view,
				};

				// Build the chat object
				$scope.immersionChat = [];
				angular.forEach(
					$scope.chosenWorkshop.pyramid[$scope.povGroupID - 1].chat,
					function (value, key) {
						if ($rootScope.userEmail == value.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}

						$message = {
							id: value.id,
							message: value.message,
							name: value.name,
							user: value.email,
							timestamp: value.created_at,
							is_reply: is_currentUser,
						};
						$scope.immersionChat.push($message);
						$timeout(function () {
							var scroller = document.getElementById("chatscreen");
							scroller.scrollTop = scroller.scrollHeight;
						});
					}
				);

				// User clicks input to edit
				$scope.focusInput = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							$name =
								$localStorage.user.name +
								" " +
								$localStorage.user.firstLetterOfSurname;
							// Broadcasts to to connected sockets that the user is editing an input
							socket.emit("editing", {
								user: $rootScope.userEmail,
								name: $name,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						}
					});
				};

				// User wants to unlock a input that another user is editing
				$scope.unlockInput = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							// Show sweet alert with callback
							sweetAlert
								.swal(
									{
										title: "Warning",
										text:
											"Please note by unlocking you are removing " +
											$scope.choiceSet.names[key] +
											" from editing.",
										type: "warning",
									},
									function () {}
								)
								.then(function () {
									$name =
										$localStorage.user.name +
										" " +
										$localStorage.user.firstLetterOfSurname;
									socket.emit("editing", {
										user: $rootScope.userEmail,
										name: $name,
										element: data,
										id: $scope.choiceSet.ids[key],
									});
								});
						}
					});
				};

				// Pass the pen
				$scope.passThePen = function (data, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data === value && id == $scope.choiceSet.ids[key]) {
							$name =
								$localStorage.user.name +
								" " +
								$localStorage.user.firstLetterOfSurname;
							// Broadcasts to to connected sockets that the user is editing an input
							socket.emit("editing", {
								user: null,
								name: $name,
								element: data,
								id: $scope.choiceSet.ids[key],
							});
						}
					});
				};

				// Submit edit of input to the api
				$scope.submitPyramid = function ($part, id) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if ($part === value && id == $scope.choiceSet.ids[key]) {
							$scope.input = {
								part: $part,
								message: $scope.choiceSet.messages[key],
								profiler_id: $scope.choiceSet.ids[key],
							};
							dashboardFactory.savePyramid($scope.input).then(
								function (result) {
									$timeout(function () {
										socket.emit('newProfiler', result.profiler);
									});
								},
								function (error) {
									console.log(error);
								}
							);
						}
					});
				};

				// Socket to recieve who is editing an input
				socket.on("editor", function (data) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (
							data.element === value &&
							data.id == $scope.choiceSet.ids[key]
						) {
							$scope.choiceSet.whoIsEditing[key] = data.user;
							$scope.choiceSet.names[key] = data.name;
							if ($scope.choiceSet.whoIsEditing[key] == null) {
								$scope.choiceSet.isDisabled[key] = false;
							} else {
								if (
									$scope.choiceSet.whoIsEditing[key] !== $rootScope.userEmail
								) {
									$scope.choiceSet.isDisabled[key] = true;
								} else {
									$scope.choiceSet.isDisabled[key] = false;
								}

								$scope.choiceSet.editing[key] = false;
							}
						}
					});
				});

				// Socket to receive change to an input from another user
				socket.on("profiler", function (data) {
					angular.forEach($scope.choiceSet.parts, function (value, key) {
						if (data.part === value && data.id == $scope.choiceSet.ids[key]) {
							$scope.choiceSet.messages[key] = data.message;
							dashboardFactory.fetch().then(
								function (result) {
									$scope.chosenWorkshop = result.group;
									$localStorage.chosenWorkshop = result.group;
								},
								function (error) {
									console.log(error);
								}
							);
						}
					});
				});

				// Socket for statement
				socket.on("recieveStatement", function (data) {
					if (data.id == $localStorage.statement_pov_id) {
						$scope.statement = data;
					}
				});

				// Socket for chat
				socket.on("recieveImmersionMessage", function (data) {
					if (
						data.pyramid[0].id ==
						$scope.chosenWorkshop.pyramid[$scope.povGroupID - 1].id
					) {
						if ($rootScope.userEmail == data.email) {
							is_currentUser = "true";
						} else {
							is_currentUser = "false";
						}

						$message = {
							id: data.id,
							message: data.message,
							name: data.name,
							user: data.email,
							timestamp: data.created_at,
							is_reply: is_currentUser,
						};
						$scope.immersionChat.push($message);
						var elem = angular.element(
							document.querySelector("#immersionChat")
						);
						elem.val("");
						$timeout(function () {
							var scroller = document.getElementById("chatscreen");
							scroller.scrollTop = scroller.scrollHeight;
						});
					}
				});
			}
		};

		// Navigate back to moderation main screen
		$scope.backToModeration = function () {
			$scope.collabGame = null;
			$scope.collabGroup = null;
		};

		// Approve group answers
		$scope.approve = function ($id, $group, pyramidId) {
			let progressData = {
				phase: $id,
				workshop: $scope.chosenWorkshop.id,
				group: $group,
				pyramid_id: pyramidId || 0,
			};

			// Update the progress for all group users (persist user progress)
			function progressUsers(progressData) {
				$http({
					method: "POST",
					url: __env.apiUrl + "progress-user",
					headers: {
						Authorization: "Bearer " + $localStorage.token,
					},
					data: progressData,
				}).then(
					function () {
						// do nothing on success
					},
					function (error) {
						console.log(error);
					}
				);
			}
			progressUsers(progressData);

			// Emit to socket (progress user views live)
			$data = {
				phase: $id,
				group: $group,
			};

			if ($id === "Phase One") {
				socket.emit("approveGroup", $data);
			}

			if ($id === "Phase Two") {
				socket.emit("approveGroup", $data);
			}

			if ($id === "Phase Three") {
				socket.emit("approveGroup", $data);
			}

			if ($id === "Phase Four") {
				socket.emit("approveGroup", $data);
			}

			if ($id === "Phase Five") {
				socket.emit("approveGroup", $data);
			}

			// Confirm progression to admin
			sweetAlert.swal({
				title: "Approved",
				text: "You have approved " + $id + " for Group " + $group + ".",
				type: "success",
			});
		};

		// Unlock group answers
		$scope.unlock = function (group, game) {

			let data = {
				wid: $scope.chosenWorkshop.id,
				gid: group,
				game: game
			};

			$http({
				method: "POST",
				url: __env.apiUrl + "unlock",
				headers: {
					Authorization: "Bearer " + $localStorage.token,
				},
				data: data,
			}).then(
				function () {
					sweetAlert.swal({
						title: "Unlocked",
						text: "You have unlocked the answers for Group " + group + ".",
						type: "success",
					});
					$scope.locked = 0;

					// Broadcast unlock
					data.method = "unlock";
					socket.emit("lockGroupAnswers", data);

				},
				function (error) {
					console.log(error);
				}
			);

		};

		// Send a chat message
		$scope.sendChatMessage = function (message, game, chatId, emit) {
			$scope.input = {
				message: message,
				chat_id: chatId,
			};
			dashboardFactory.chat($scope.input, game).then(
				function (result) {
					socket.emit(emit, result.chats);
					angular.element(document.getElementById(game)).val("");
				},
				function (error) {
					console.log(error);
				}
			);
		};
	},
});
