angular.module('app').component('groupOrganiser', {
	templateUrl: 'app/dashboard/group/group-organiser.html',
	controllerAs: 'self',

	controller: function(
		$rootScope,
		$scope,
		titleService,
		$timeout,
		$localStorage,
		dashboardFactory,
		socket,
		__env
	) {
		// Set this to self to avoid conflicts
		const self = this;

		// Set the basic controller data to be passed to title service
		titleService.data = {
			title: 'Workshop Organiser',
			subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
		};

		// Broadcast section title to the header
		$rootScope.$broadcast('headerTitle', {
			title: 'Workshop Organiser',
			icon: 'fa-dashboard',
		});

		// Run when component is ready (REFACTOR)
		self.$onInit = function() {

			$scope.groupHumanTruth = [[],[],[],[],[]];
			$scope.groupProductTruth = [[],[],[],[],[]];
			$scope.groupPov = [[],[],[],[],[]];
			$scope.groupPyramid = [[],[],[],[],[]];

			dashboardFactory.fetch().then(
				function(result) {

					$localStorage.chosenWorkshop = result.group;
					$scope.selectedGroup = result.group;
					$scope.chosenWorkshop = result.group;

					$timeout(function() {

						// Fetch all group users
						angular.forEach(result.group.user, function(value) {
							var sname = value.surname.slice(0, 1);

							function buildGroups(gameResults, gameGroupObject, defaultColor){
								if (gameResults.length > 0) {
									angular.forEach(gameResults, function(value2) {
										// Get workshop ID - get this workshop
										if (value2.workshop_id == $scope.selectedGroup.id) {

											if( angular.isDefined(value2.tension) ){
												$scope.color = value2.tension[0].color;
											}
											else{
												$scope.color = defaultColor;
											}

											// If user is part of a group
											if (value2.group_id) {
												gameGroupObject[+value2.group_id].push({ color: $scope.color, email: value.email, name: value.name, surname: sname });
											} else {
												gameGroupObject[0].push({ color: $scope.color, email: value.email, name: value.name, surname: sname });
											}
										}
									});
								}
							}
							buildGroups(value.human_truth, $scope.groupHumanTruth, "#F58220");
							buildGroups(value.product_truth, $scope.groupProductTruth, "#EF4D84");
							buildGroups(value.pov, $scope.groupPov, "#99CA43");
							buildGroups(value.pyramid, $scope.groupPyramid, "#2AB2A6");
						});

					});
				},
				function(error) {
					console.log(error);
				}
			);
		};

		// Handle frontend drag-and-drop logic for group assignment
		$scope.onDrop = function($event, $data, array, array2, group_id, game) {
			array.push($data);
			if (array2 !== 'original') {
				email = $data.email;
				var index = -1;
				array2.some(function(obj, i) {
					return obj.email === email ? (index = i) : false;
				});
				array2.splice(index, 1);
				// Confirm unused?
				$scope.input = {
					email: $data.email,
					group_id: group_id,
					game: game,
				};
			}
		};

		// Remove a user from a group (frontend only)
		$scope.removeUserFromGroup = function(thisUser, currentGroupUsers, unassignedGroupUsers) {
			email = thisUser.email;
			var index = -1;
			currentGroupUsers.some(function(obj, i) {
				return obj.email === email ? (index = i) : false;
			});
			currentGroupUsers.splice(index, 1);
			unassignedGroupUsers.push(thisUser);
		};

		// Add users to groups (bulk)(backend store)
		$scope.addUsersToGroups = function(game) {

			$scope.saving = game;

			let lookupGroupAssignments = {
				human_truth: $scope.groupHumanTruth,
				product_truth: $scope.groupProductTruth,
				pov: $scope.groupPov,
				pyramid: $scope.groupPyramid
			};

			let groupAssignments = {};
			groupAssignments[game] = lookupGroupAssignments[game];

			dashboardFactory.addToGroup(groupAssignments).then(
				function(result) {
					swal('Success', result.message, 'success');
					$scope.saving = false;
				},
				function(error) {
					console.log(error);
					$scope.saving = false;
				}
			);

		};

		// Unlock phase for all workshop users
		$scope.openPhase = function($phase) {
			$input = {
				id: $scope.chosenWorkshop.id,
				phase: $phase,
			};
			dashboardFactory.phaseOpen($input).then(
					function() {
						socket.emit('groupAssignment', { data: $scope.chosenWorkshop });
						swal('Success', 'The next phase has been unlocked for all assigned users.', 'success');
					},
					function(error) {
						console.log(error);
					}
			);
		};

		// Listen for game completions (future release)
		socket.on('played', function(data) {
			console.log('A new user is available for group assignment');
		});

	},
});
