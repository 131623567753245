angular.module('app').factory('dashboardFactory', function($http, $localStorage, __env) {
  return {
    /**
	 * Fetch workshop
	 */
    fetch: function() {
      if (__env.enableDebug) {
        console.log('dashboardFactory.fetch');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'group/' + $localStorage.chosenWorkshop.id,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End fetch workshop */

    /**
		 * Fetch statement
		 */
    fetchStatement: function(id) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.fetchStatement');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'statement/' + id,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End fetch statement */

    /**
		 * save profiler
		 */
    save: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.save');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'profiler/',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End fetch profiler */

    saveManifesto: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.saveManifesto');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'manifesto/',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    savePyramid: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.savePyramid');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'pyramid',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    /**
		 * save statement
		 */
    saveStatement: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.save Statement');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'statement/',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End save statement */

    /**
		 * chat
		 */
    chat: function(input, game) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.chat');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'chat/' + game,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    chatManifesto: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.chatManifesto');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'chatManifesto',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    chatTshirt: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.chatTshirt');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'chatTshirt',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    chatImmersion: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.chatImmersion');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'chatImmersion',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    chatStatement: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.chatStatement');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'chat/statement',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End fetch chat */

    /**
		 * product truth answers
		 */
    productTruth: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.productTruth');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'product-truth',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End product truth answers */

    /**
		 * pov answers
		 */
    pov: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.pov');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'pov',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End pov answers */

    /**
		 * tshirt update
		 */
    tshirt: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.tshirt');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'tshirt',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
    /* End tshirt update */

    /**
		 * Read game-1 data
		 */
    read: function() {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'game/1?lang=en',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(success) {
          return success.data;
        },
        function(failure) {
          return failure.data;
        }
      );
    }, // end read

    /**
		 * Read Tension
		 */
    readTension: function() {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'tension?lang=en',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(success) {
          return success.data;
        },
        function(failure) {
          return failure.data;
        }
      );
    }, // end read Tension

    fetchLanguage: function(language) {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'copy?lang=' + language,
				headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(response) {
          return response.data;
        },
        function(error) {
          return error;
        }
      );
    },
    progress: function() {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'workshop/progress/' + $localStorage.chosenWorkshop.id,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(response) {
          return response.data;
        },
        function(error) {
          return error;
        }
      );
    },
    collaborationPovGroupResults: function($wid, $gid) {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'pov/' + $wid + '/group/' + $gid,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(response) {
          return response.data;
        },
        function(error) {
          return error;
        }
      );
    },
    collaborationProductGroupResults: function($wid, $gid) {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'product/' + $wid + '/group/' + $gid,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(response) {
          return response.data;
        },
        function(error) {
          return error;
        }
      );
		},
    getWorkshopResults: function($wid, $game) {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'results/' + $wid + '/' + $game,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(response) {
          return response.data;
        },
        function(error) {
          return error;
        }
      );
    },
    collaborationHumanGroupResults: function($wid, $gid) {
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'human/' + $wid + '/group/' + $gid,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      }).then(
        function(response) {
          return response.data;
        },
        function(error) {
          return error;
        }
      );
    },

    /**
		 * immersion answers
		 */
    immersion: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.immersion');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'immersion',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    /**
		 * immersion results
		 */
    immersionResults: function($wid) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.immersionResults');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'results/' + $wid + "/immersion",
				headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    /**
		 * Add to collaboration group
		 */
    addToGroup: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.addToGroup');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'collaboration/group/' + $localStorage.chosenWorkshop.id,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    /**
		 * group members
		 */
    groupMembers: function($wid, $id, $game) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.groupMembers');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'workshop/' + $wid + '/group/' + $id + '/' + $game,
				headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    submitPyramid: function($wid, pyramidId) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.submitPyramid');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'believers/pyramid/' + $wid,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: {
					pyramid_id: pyramidId
				},
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    ImageRotate: function($direction, $imagename) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.ImageRotate');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'image/rotation/',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: {
          direction: $direction,
          imageName: $imagename,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    notifyFacilitator: function($wid, $gid, $game) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.notifyFacilitator');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'notifyAdmin',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: {
          wid: $wid,
          gid: $gid,
          game: $game,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    updateProgress: function($part, $wid) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.updateProgress');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'update/progress',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: {
          wid: $wid,
          part: $part,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    updateTshirtStatus: function($wid) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.updateTshirtStatus');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'tshirt/status/' + $wid,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    getGroupTshirts: function($wid, $gid) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.getGroupTshirts');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'tshirt/' + $wid + '/group/' + $gid,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    vote: function($id) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.getGroupTshirts');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'vote/tshirt/' + $id,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    winnercheck: function($gid, $wid) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.winnercheck');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'winner/check/' + $gid + '/' + $wid,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    usersNames: function($input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.usersNames');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'users/names?=email' + input,
				headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    facilitatorMail: function(input) {
      if (__env.enableDebug) {
        console.log('groupFactory.facilitatorMail');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'contact/facilitator',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    deleteImage: function(input) {
      if (__env.enableDebug) {
        console.log('groupFactory.facilitatorMail');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'delete/image',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    phaseOpen: function(input) {
      if (__env.enableDebug) {
        console.log('groupFactory.phaseOpen');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'open/phase',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    /**
		 * Go live
		 */
    goLive: function(id) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.goLive');
      }
      return $http({
        method: 'GET',
        url: __env.apiUrl + 'go/live/' + id,
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    saveTension: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.saveTension');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'save/tension/',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },

    /**
		 * chat
		 */
    chatTension: function(input) {
      if (__env.enableDebug) {
        console.log('dashboardFactory.chat');
      }
      return $http({
        method: 'POST',
        url: __env.apiUrl + 'chat/tension',
        headers: {
          Authorization: 'Bearer ' + $localStorage.token,
        },
        data: input,
      })
        .then(function(response) {
          return response.data;
        })
        .then(function(error) {
          return error;
        });
    },
  };
});
