angular.module('app').factory('userFactory', function($http, $localStorage, __env) {
	return {
		/**
		 * Fetch a user
		 */
		fetch: function() {
			if (__env.enableDebug) {
				console.log('userFactory.fetch');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'users',
				// url: __env.apiUrl + 'group/user',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end fetch */

		/**
		 * Fetch a single user
		 */
		fetchSingle: function(id) {
			if (__env.enableDebug) {
				console.log('userFactory.fetchSingle');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'user/' + id,
				// url: __env.apiUrl + 'group/user',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},

		/**
		 * Fetch all users
		 */
		fetchAll: function() {
			if (__env.enableDebug) {
				console.log('userFactory.fetchAll');
			}
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'all/users',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/**
		 * Create a new user
		 */
		create: function(input) {
			if (__env.enableDebug) {
				console.log('userFactory.create');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'user',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end create */
		/**
		 * Assign a user to a group
		 */
		assign: function(input) {
			if (__env.enableDebug) {
				console.log('userFactory.assign');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'group/user',
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end create */

		/**
		 * Delete a user
		 */
		delete: function(input) {
			if (__env.enableDebug) {
				console.log('userFactory.delete');
			}
			return $http({
				method: 'DELETE',
				url: __env.apiUrl + 'admin/user/' + input.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end delete */

		/**
		 * Update a user
		 */
		update: function(input) {
			if (__env.enableDebug) {
				console.log('userFactory.update');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'user/' + input.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end update */

		/**
		 * Remove a user from group
		 */
		remove: function(input) {
			if (__env.enableDebug) {
				console.log('userFactory.remove');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'group/remove/user/' + input.user_id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: input,
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end remove */

		/**
		 * Make user a facilitator of a specific group
		 */
		makeAdmin: function(workshop_id, user_id) {
			if (__env.enableDebug) {
				console.log('userFactory.makeAdmin');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'add/workshop/' + workshop_id + '/user/' + user_id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end make facilitator */

		/**
		 * Make user a facilitator of a specific group
		 */
		removeAdmin: function(workshop_id, user_id) {
			if (__env.enableDebug) {
				console.log('userFactory.removeAdmin');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'remove/workshop/' + workshop_id + '/user/' + user_id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},
		/* end make facilitator */
	};
});
