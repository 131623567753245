angular.module("app").component("productTruth", {
	templateUrl: "app/dashboard/game/04-product-truth.html",
	controllerAs: "self",

	controller: function (
		$rootScope,
		$scope,
		$state,
		$timeout,
		$localStorage,
		$window,
		dashboardFactory,
		showMyResultsService,
		utilService,
		socket,
		sweetAlert,
		__env
	) {
		if (__env.enableDebug) {
			console.log("productTruthController");
		}

		if (
			$localStorage.role === "super admin" ||
			$localStorage.role === "facilitator"
		) {
			$state.go("dashboard.group-manage");
		}

		// Set this to self to avoid conflicts
		const self = this;

		// Used to set the navigator
		$scope.currentGame = 5;

		$scope.loadingScreen = true;
		$scope.editing = false;
		$scope.isDisabled = false;
		$scope.whoIsEditing = "check";
		$scope.EditorName = null;
		$scope.groupedAnswer = false;
		$scope.statementChat = [];
		$scope.chatStatementMessage = null;
		$scope.answers = [];
		$scope.selectedWordsGame = [];
		$localStorage.statement = { id: "" };

		// Don't show popup if phase has been completed
		self.showPopup = true;
		if (
			angular.isDefined($localStorage.progress) &&
			angular.isDefined($localStorage.progress.product_truth[0]) &&
			$localStorage.progress.product_truth[0].completed
		) {
			if (__env.enableDebug) {
				console.log("This game has been completed");
			}
			self.showPopup = false;
		}

		$scope.navigateToGamePhase = function(gamePhaseName, completedPhaseNumber) {
			var result = utilService.navigateToGamePhase(gamePhaseName, completedPhaseNumber);
			if (result) {
				$state.go(result);
			}
		};

		// Popup Content
		$scope.popupContent =
			'<div class="row">\
							<div class="col-md-12">\
								<p><strong>Diving into our product</strong></p>\
								<p>The product truth is the essential characteristic of what we sell, the core of the product or service people buy. It should be aspirational and bring the brand point of view to life but also be sharp and specific enough to serve as the basis for an overarching R&D brief.</p>\
								\
								<hr>\
								<p><strong>Why an embodied product experience?</strong></p>\
								<p>The embodied product experience helps us to take off our business hat and put ourselves in the shoes of the people we serve and the reality of using/consuming the product or service we offer them. This may mean going to the place where the product is sourced or made; using our product or service as the people we serve would; or experiencing a world with and without our product or service. In all cases, it is about giving the team a new and richer experience with the product, so that they can feel the real and metaphorical meaning it plays in people’s lives.</p>\
								<p>Look out for instructions in your inbox.</p>\
							</div>\
						</div>';

		$scope.htmlContent =
			'<div class="row">\
							<div class="col-md-12">\
								<p><strong>What should I do?</strong></p>\
								<p>As you read this, you should have received via email the specific instructions on how to do the embodied product experience. This experience is always very specific to your product and/or service so it is always designed in a different way. Read the instructions in your email and make sure to go through the exercise as explained.</p>\
								\
								<hr>\
								<p><strong>What will happen after?</strong></p>\
								<p>Once you are done with your embodied product experience we will guide you in identifying some learnings that will help in the following steps of the product truth definition. Before starting the exercises, close your eyes and think back to the key moments of the embodied product experience you did.</p>\
							</div>\
						</div>';

		$scope.showOthers = false;
		$scope.currentWorkshopId = $localStorage.chosenWorkshop.id;
		$scope.url = __env.apiUrl;
		$rootScope.userEmail = $localStorage.user.email;
		$scope.email = $rootScope.userEmail;
		$scope.answer2 = null;
		$scope.workshop = null;
		$scope.workshops = $localStorage.workshops;
		$scope.statement = null;
		$scope.statementChat = [];
		$scope.QandA = [];
		$scope.statements = [];
		$scope.answerSet = { answers: [] };

		// Get and Set available game results for show my results component
		showMyResultsService.getAvailableGameResults($scope.currentGame).then(function(myGameResults) {
			$scope.myResults = myGameResults;
			$scope.gameResultsToShow = {};
			$scope.currentResults = ($scope.myResults.length > 0) ? $scope.myResults[0].value : 'default';
			$scope.numberOfResults = showMyResultsService.getNumberOfShowingResults($scope.myResults);
		});

		// Set the function to update selected results on component
		$scope.updateSelectedResults = function(selectedResults) {
			if (angular.isDefined($scope.myResults)) {
				showMyResultsService.updateSelectedResults(selectedResults, $scope.myResults).then(function(result) {
					$scope.gameResultsToShow = result;
				});
			}
		};	

		// Run when component is ready
		self.$onInit = function () {
			dashboardFactory.progress().then(
				function (result) {
					$scope.progress = result.progress;
					$localStorage.progress = result.progress;
					// Get the users current workshop game
					$scope.game = JSON.parse(result.current_progress).current_game;
					var gotoPhase = utilService.navigateToGamePhase($scope.currentGame, $scope.game, $scope.currentGame);
					if (angular.isDefined(gotoPhase) && gotoPhase !== 'dashboard.product-truth') {
						$state.go(gotoPhase, {}, { reload: true });
					} else {
						$localStorage.lastPlayedPhase = $scope.game;
					}

					// Set completed state for
					if (angular.isDefined(result.progress) && angular.isDefined(result.progress.product_truth[0])) {
						$scope.completed = result.progress.product_truth[0].completed;

						angular.forEach(
							result.progress.product_truth[0].answer,
							function (value, key) {
								this[key] = value.answer;
							},
							$scope.answerSet.answers
						);
					}
				},
				function (error) {
					console.log(error);
				}
			);

			if ($localStorage.chosenWorkshop) {
				$scope.chosenWorkshop = $localStorage.chosenWorkshop;

				dashboardFactory.fetch().then(
					function (result) {
						$scope.chosenWorkshop = result.group;
						$localStorage.chosenWorkshop = result.group;
						$scope.optionValue = 1;

						// Manage locking of content
						if($localStorage.progress.product_truth[0]){
							$scope.group = $localStorage.progress.product_truth[0].group_id;
							if(result.locks['product-truth'] && result.locks['product-truth'][$scope.group]){
								$scope.locked = true;
							}
						}

						$timeout(function () {
							if (self.showPopup) {
								sweetAlert.swal({
									html: $scope.popupContent,
									customClass: "instructions",
									showCancelButton: false,
									showConfirmButton: false,
									showCloseButton: true,
								});
							}
							$scope.loadingScreen = true;
						});

						angular.forEach(result.group.question, function (value, key) {
							// $scope.answerSet.answers.push('');

							$q = {
								question: value.question,
								questionID: key + 1,
								question_id: value.id,
								type: value.type,
							};
							if (value.type != "words" && value.answer_options) {
								$splitoptions = value.answer_options.split(",");
								$options = [];
								angular.forEach($splitoptions, function (value, key) {
									$options.push({
										name: value,
										id: key + 1,
									});
									$scope.optionValue++;
								});
								$q.options = $options;
							}

							if (value.type === "words") {
								$jsonObj = JSON.parse(value.answer_options);
								$options = [];
								angular.forEach($jsonObj, function (value, key) {
									$options.push({
										name: value.word,
										id: key + 1,
										color: value.color,
									});
								});
								$q.options = $options;
							}

							$scope.QandA.push($q);
						});

						angular.forEach(result.group.statement, function (value) {
							if (value.game === "product_truth") {
								$scope.statements.push(value);
							}
						});
						$timeout(function () {
							$scope.group = null;
							if ($localStorage.progress.product_truth.length > 0) {
								if ($localStorage.progress.product_truth[0].group_id) {
									$localStorage.statement.id =
										$scope.statements[
											$localStorage.progress.product_truth[0].group_id - 1
										].id;
									$scope.group =
										$localStorage.progress.product_truth[0].group_id;
									$scope.progress = $localStorage.progress;
									$scope.getStatement();
									dashboardFactory
										.groupMembers(
											$localStorage.chosenWorkshop.id,
											$scope.group,
											"product"
										)
										.then(
											function (result) {
												$scope.groupMembers = result.members;
											},
											function (error) {
												console.log(error);
											}
										);
									dashboardFactory
										.collaborationProductGroupResults(
											$localStorage.chosenWorkshop.id,
											0
										)
										.then(function (result) {
											$scope.productGroups = result.groups;
											angular.forEach($scope.productGroups, function (
												value,
												key
											) {
												$timeout(function () {
													$scope.groupedAnswer = true;
												});
											});
										});

									if ($scope.chosenWorkshop.phase_two_open === "true") {
										$scope.loadingScreen = true;
									} else {
										$scope.loadingScreen = false;
									}
								} else {
									$scope.loadingScreen = false;
								}
							}
						});
					},
					function (error) {
						console.log(error);
					}
				);
			} else {
				$scope.chosenWorkshop = null;
			}

			if ($localStorage.selectedWorkshop) {
				$scope.workshop = $localStorage.selectedWorkshop;
				angular.forEach($scope.workshops, function (value, key) {
					if (value.id == $localStorage.selectedWorkshop) {
						// $scope.selectedWorkshop = value;
					}
				});
			} else {
				$scope.workshop = null;
			}

			if (
				angular.isDefined($localStorage.statement) &&
				$localStorage.statement.id
			) {
				$scope.statement_id = $localStorage.statement.id;
			} else {
				$scope.statement_id = null;
			}

			$scope.userGroups = $localStorage.groups;
		};

		$scope.next = function () {
			socket.emit("someOneSubmitted", "true");
			$localStorage.lastPlayedPhase = $scope.game;
			$state.go("dashboard.pyramid");
		};

		$scope.checkAnswers = function () {
			return $scope.answerSet.answers.filter(Boolean).length;
		};

		// Add selected words to answerSet
		$scope.selectWords = function (questionID, word) {
			if ($scope.selectedWordsGame.indexOf(word) !== -1) {
				var index = $scope.selectedWordsGame.indexOf(word);
				$scope.selectedWordsGame.splice(index, 1);
			} else {
				$scope.selectedWordsGame[0] = word;
			}

			$scope.answerSet.answers[questionID - 1] = word;
		};
		// Add selected image to answerSet
		$scope.selectImage = function (selectedItem, selectedId, questionID) {
			if (
				selectedItem != "text" &&
				selectedId != "text" &&
				selectedItem != "words" &&
				selectedId != "words"
			) {
				$scope.answerSet.answers[questionID - 1] = selectedId;
			}
		};
		// Collect data after question answered
		$scope.submitData = function (completed) {
			// Post answer set to api
			$scope.input = {
				workshop_id: $localStorage.chosenWorkshop.id,
				answers: $scope.answerSet,
				questions: $scope.QandA,
				complete: completed,
			};

			dashboardFactory.productTruth($scope.input).then(
				function () {
					socket.emit("someOneSubmitted", "true");
					dashboardFactory.progress().then(
						function (result) {
							$scope.progress = result.progress;
							$localStorage.progress = result.progress;
							$scope.loadingScreen = false;
							// Get the users current workshop game
							$scope.game = JSON.parse(result.current_progress).current_game;
						},
						function (error) {
							console.log(error);
						}
					);
				},
				function (error) {
					console.log(error);
				}
			);
		};

		// Submit statement
		$scope.sumbitStatement = function ($message) {
			$scope.input = {
				message: $message,
				statement_id: $localStorage.statement.id,
				group_id: $localStorage.progress.product_truth[0].group_id,
			};
			dashboardFactory.saveStatement($scope.input).then(
				function (result) {
					socket.emit("newStatement", result.statement);
				},
				function (error) {
					console.log(error);
				}
			);
		};
		// Get statement for group
		$scope.getStatement = function () {
			if ($localStorage.statement.id) {
				// Get Statement
				dashboardFactory.fetchStatement($localStorage.statement.id).then(
					function (result) {
						$scope.statement = result.statement;
						$scope.statementCompleted = result.statement.completed;

						angular.forEach(result.statement.chat, function (value) {
							if ($rootScope.userEmail == value.email) {
								is_currentUser = "true";
							} else {
								is_currentUser = "false";
							}

							$message = {
								id: value.id,
								message: value.message,
								user: value.email,
								name: value.name,
								timestamp: value.created_at,
								is_reply: is_currentUser,
							};
							$scope.statementChat.push($message);
							$timeout(function () {
								var scroller = document.getElementById("chatscreen");
								if (scroller) {
									scroller.scrollTop = scroller.scrollHeight;
								}
							});
						});
						$timeout(function () {
							socket.emit("checkStatement", "first check");
						});
					},
					function (error) {
						console.log(error);
					}
				);
			}
		};
		// Send a chat message
		$scope.sendChatMessage = function (message, game, chatId, emit) {
			$scope.input = {
				message: message,
				chat_id: chatId,
			};
			dashboardFactory.chat($scope.input, game).then(
				function (result) {
					result.chats.is_reply = "true"; // IN8-150
					socket.emit(emit, result.chats);
					angular.element(document.getElementById(game)).val("");
				},
				function (error) {
					console.log(error);
				}
			);
		};

		// User clicks input to edit
		$scope.focusInput = function (id) {
			if (id == $localStorage.statement.id) {
				$name =
					$localStorage.user.name +
					" " +
					$localStorage.user.surname.substr(0, 1);
				// Broadcasts to to connected sockets that the user is editing an input
				socket.emit("editingStatement", {
					user: $rootScope.userEmail,
					name: $name,
					id: $localStorage.statement.id,
				});
			}
		};

		$scope.showFacilitator = function ($group, $game) {
			dashboardFactory
				.notifyFacilitator($scope.currentWorkshopId, $group, $game)
				.then(
					function () {
						$rootScope.facilitatorPopUp("no redirect");

						// Broadcast lock for all other group participants
						let lockedData = {
							wid: $scope.currentWorkshopId,
							gid: $group,
							game: $game,
							method: "lock"
						}
						socket.emit("lockGroupAnswers", lockedData);

					},
					function (error) {
						console.log(error);
					}
				);
		};

		// Listen for answer locking broadcast
		socket.on("lockGroupAnswers", function (data) {
			// Is broadcast is applicable to current phase?
			if(data.game == "Product Truth"){
				// Is broadcast applicable to this workshop and group?
				if(parseInt(data.wid) == parseInt($scope.currentWorkshopId) && parseInt(data.gid) == parseInt($localStorage.progress.product_truth[0].group_id)){
					// Lock answers
					if(data.method == "lock"){
						console.log("[+] Recieved lock broadcast");
						$scope.locked = 1;
					}
					// Unlock answers
					if(data.method == "unlock"){
						console.log("[+] Recieved unlock broadcast");
						$scope.locked = 0;
					}
				}
			}
		});

		// Request that a Facilitator joins the chate
		$scope.requestFacilitatorJoinsChat = function ($group, $game){
			// Broadcast lock for all other group participants
			let payload = {
				wid: parseInt($scope.currentWorkshopId),
				gid: parseInt($group),
				game: $game,
			}
			socket.emit("requestFacilitatorJoinsChat", payload);

			sweetAlert.swal({
				title: "Requested",
				text: "The facilitator has been asked to join this group.",
				type: "warning",
			});

		}

		$scope.passThePen = function (id) {
			if (id == $localStorage.statement.id) {
				// Broadcasts to to connected sockets that the user is editing an input
				socket.emit("editingStatement", {
					user: null,
					name: null,
					id: $localStorage.statement.id,
				});
			}
		};

		$scope.others = function () {
			if ($scope.showOthers) {
				$scope.showOthers = false;
			} else {
				$scope.showOthers = true;
			}
		};

		// User wants to unlock a input that another user is editing
		$scope.unlockInput = function (id) {
			if (id == $localStorage.statement.id) {
				// Show sweet alert with callback
				sweetAlert
					.swal(
						{
							title: "Warning",
							text:
								"Please note by unlocking you are removing " +
								$scope.EditorName +
								" from editing.",
							type: "warning",
						},
						function () {}
					)
					.then(function () {
						$name =
							$localStorage.user.name +
							" " +
							$localStorage.user.surname.substr(0, 1);
						socket.emit("editingStatement", {
							user: $rootScope.userEmail,
							name: $name,
							id: $localStorage.statement.id,
						});
					});
			}
		};

		socket.on("recieveProductGroup", function (data) {
			if (data.id == $localStorage.chosenWorkshop.id) {
				dashboardFactory.progress().then(
					function (result) {
						$scope.loadingScreen = true;
						$scope.progress = result.progress;
						$localStorage.progress = result.progress;
						// Get the users current workshop game
						$scope.game = JSON.parse(result.current_progress).current_game;
						if ($localStorage.progress.product_truth[0].group_id) {
							$localStorage.statement.id =
								$scope.statements[
									$localStorage.progress.product_truth[0].group_id - 1
								].id;
							$scope.group = $localStorage.progress.product_truth[0].group_id;
							$scope.progress = $localStorage.progress;
							dashboardFactory
								.collaborationProductGroupResults(
									$localStorage.chosenWorkshop.id,
									0
								)
								.then(function (result) {
									$scope.productGroups = result.groups;
									angular.forEach($scope.productGroups, function (value, key) {
										if (value.selectedWords.length == 1) {
											$timeout(function () {
												$scope.groupedAnswer = true;
											});
										}
									});
								});
						}
					},
					function (error) {
						console.log(error);
					}
				);
			}
		});

		// Fires when group assignment is complete
		socket.on("groupAssigned", function (data) {
			if (data.data.id == $localStorage.chosenWorkshop.id) {
				if (!$scope.loadingScreen) {
					dashboardFactory.fetch().then(
						function (result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							dashboardFactory.progress().then(
								function (result) {
									$scope.progress = result.progress;
									$localStorage.progress = result.progress;
									// Get the users current workshop game
									$scope.game = JSON.parse(result.current_progress).current_game;

									if ($localStorage.progress.product_truth[0].group_id) {
										$scope.statement_id =
											$scope.statements[
												$localStorage.progress.product_truth[0].group_id - 1
											].id;
										$localStorage.statement.id = $scope.statement_id;
										$scope.group =
											$localStorage.progress.product_truth[0].group_id;
										$scope.progress = $localStorage.progress;
										dashboardFactory
											.groupMembers(
												$localStorage.chosenWorkshop.id,
												$localStorage.progress.product_truth[0].group_id,
												"product"
											)
											.then(
												function (result) {
													$scope.groupMembers = result.members;
												},
												function (error) {
													console.log(error);
												}
											);
										dashboardFactory
											.collaborationProductGroupResults(
												$localStorage.chosenWorkshop.id,
												0
											)
											.then(function (result) {
												$scope.productGroups = result.groups;
												angular.forEach($scope.productGroups, function (
													value,
													key
												) {
													$timeout(function () {
														$scope.groupedAnswer = true;
													});
												});
											});
										$scope.getStatement();
										$timeout(function () {
											$scope.showDetails = false;
											if ($scope.chosenWorkshop.phase_two_open === "true") {
												$scope.loadingScreen = false;
											} else {
												$scope.loadingScreen = true;
											}

											$window.scrollTo(0, 0);

											$timeout(function () {
												if (self.showPopup) {
													sweetAlert.swal({
														html: $scope.popupContent,
														customClass: "instructions",
														showCancelButton: false,
														showConfirmButton: false,
														showCloseButton: true,
													});
												}
												$scope.loadingScreen = true;
											});
										});
									} else {
										console.log("failed");
										$scope.loadingScreen = false;
									}
								},
								function (error) {
									console.log(error);
								}
							);
						},
						function (error) {
							console.log(error);
						}
					);
				}
			}
		});

		// Socket for statement
		socket.on("recieveStatement", function (data) {
			if (data.id == $localStorage.statement.id) {
				$scope.statement = data;
			}
		});

		// Socket for chat
		socket.on("recieveStatementMessage", function (data) {
			if (data.statement[0].id == $localStorage.statement.id) {
				if ($rootScope.userEmail == data.email) {
					data.is_reply = "true";
				} else {
					data.is_reply = "false";
				}

				$message = {
					id: data.id,
					message: data.message,
					name: data.name,
					user: data.email,
					timestamp: data.created_at,
					is_reply: data.is_reply,
				};
				$scope.statementChat.push($message);
				$timeout(function () {
					var scroller = document.getElementById("chatscreen");
					scroller.scrollTop = scroller.scrollHeight;
				});
			}
		});

		// Socket to check if any inputs are locked by another user
		socket.on("editingCheckStatement", function (data) {
			if (data === "check") {
				if ($scope.whoIsEditing != "check") {
					socket.emit("editingStatement", {
						user: $scope.whoIsEditing,
						name: $scope.EditorName,
						id: $localStorage.statement.id,
					});
				}
			}
		});

		// Socket to recieve who is editing an input
		socket.on("editorStatement", function (data) {
			if (data.id == $localStorage.statement.id) {
				$scope.whoIsEditing = data.user;
				$scope.EditorName = data.name;
				if ($scope.whoIsEditing == null) {
					$scope.isDisabled = false;
				} else {
					if ($scope.whoIsEditing !== $rootScope.userEmail) {
						$scope.isDisabled = true;
					} else {
						$scope.isDisabled = false;
					}

					$scope.editing = false;
				}
			}
		});

		socket.on("recieveAdminJoined", function (data) {
			if (
				data.workshop == $scope.chosenWorkshop.id &&
				data.group == $localStorage.progress.product_truth[0].group_id &&
				data.game === "Product Truth"
			) {
				if (
					!$scope.groupMembers.some((item) =>
						item.email.includes(data.user.email)
					)
				) {
					$scope.groupMembers.push(data.user);
				}

				sweetAlert
					.swal(
						{
							title: "Note",
							text: "The facilitator has joined the group.",
							type: "warning",
						},
						function () {}
					)
					.then(function () {});
			}
		});

		socket.on("groupApproved", function (data) {
			if (
				data.group == $localStorage.progress.product_truth[0].group_id &&
				data.phase === "Phase Two"
			) {
				$state.go("dashboard.pyramid");
			}
		});
	},
});
