angular
	.module('app')
	.service('showMyResultsService', function (dashboardFactory, $http, $q, $rootScope, $localStorage) {
        var availableGameResults = [
            {
                value: "default",
                name: "MY RESULTS",
                answers: [],
                show: {
                    inMenue: false,
                    phase: 0,
                    content: false
                }
            },
            {
                value: "immersion-debrief",
                name: "IMMERSION DEBRIEF",
                answers: [],
                show: {
                    inMenue: false,
                    phase: 1,
                    content: false
                }
            },
            {
                value: "moments",
                name: "MOMENTS",
                answers: [],
                show: {
                    inMenue: false,
                    phase: 4,
                    content: false
                }
            },
            {
                value: "t-shirt",
                name: "T-SHIRTS",
                answers: [],
                show: {
                    inMenue: false,
                    phase: 4,
                    content: false
                }
            },
            // {
            //     value: "metaphors",
            //     name: "METAPHOR",
            //     answers: [],
            //     show: {
            //         inMenue: false,
            //         phase: 5,
            //         content: false
            //     }
            // },
            {
                value: "pyramid",
                name: "PYRAMID",
                answers: [],
                show: {
                    inMenue: false,
                    phase: 6,
                    content: false
                }
            },
        ];

        return {
            getAllGames: function () {
                var results;
                results = availableGameResults.filter(function (game) {
                    if(game.value != "default") {
                        return game;
                    }
                });
                return results;
            },
            getAvailableGameResults: function(phase) {
                var results;
                var lastCompletedPhase = (angular.isDefined($localStorage.lastPlayedPhase)) ? $localStorage.lastPlayedPhase : false;
                results = this.getSingleUserResults($localStorage.chosenWorkshop.id).then(function(gamesResults){
                    var filteredGames = availableGameResults.filter(function (game) {
                        if(game.show.phase <= phase || game.show.phase <= lastCompletedPhase) {
                            if (game.value == 'default') {
                                game.show.inMenue = true;
                            }
                            if (game.value == "immersion-debrief") {
                                game.answers = gamesResults.immersions ? gamesResults.immersions.answers: [];
                            }
                            if (game.value == "metaphors") {
                                game.answers = gamesResults.metaphors ? gamesResults.metaphors: [];
                            }
                            if (game.value == "moments") {
                                game.answers = gamesResults.moments ? gamesResults.moments: [];
                            }
                            if (game.value == "pyramid") {
                                game.answers = gamesResults.pyramids ? gamesResults.pyramids: [];
                            }
                            if (game.value == "t-shirt") {
                                game.answers = gamesResults.tshirts ? gamesResults.tshirts: [];
                            }
                            if(game.answers && game.answers.length > 0) {
                                game.show.content = true;
                                game.show.inMenue = true;
                            }
                            return game;
                        }
                    });
                    return filteredGames;
                });
                return results;
            },
            updateSelectedResults: function(selectedResults, myResults) {
                var deferred = $q.defer();
                if (angular.isDefined(myResults)) {
                    var tmpChoice = myResults.filter(function (gameResultsChoice, index) {
                        if (gameResultsChoice.value == selectedResults)
                            return gameResultsChoice;
                    });
                    if (tmpChoice[0].value === "metaphors") {
                        // Get metaphor answer options
                        dashboardFactory.collaborationProductGroupResults($localStorage.chosenWorkshop.id,0).then(function (result) {
                            var i = 0;
                            var groupAnswers = "";

                            // Get the user answers
                            while (i < result.groups.length) {
                                if (result.groups[i].answer) {
                                    groupAnswers = result.groups[i].answer
                                    break;
                                }
                                i = i + 1;
                            }

                            if (result && result.groups && groupAnswers) {
                                var options = [groupAnswers.slice(0, 4), groupAnswers.slice(4, 8)];
                                var optionAnswerOne = tmpChoice[0].answers[0].answer;
                                var optionAnswerTwo =tmpChoice[0].answers[1].answer;
                                var metaphorImgUrlOne = options[0].filter(function (optionSet) {
                                    if (optionSet.answer == optionAnswerOne) {
                                        return optionSet.option;
                                    }
                                });
                                var metaphorImgUrlTwo = options[1].filter(function (optionSet) {
                                    if (optionSet.answer == optionAnswerTwo) {
                                        return optionSet.option;
                                    }
                                });
                                tmpChoice[0].options = {imgUrlOne: metaphorImgUrlOne[0], imgUrlTwo: metaphorImgUrlTwo[0]};
                                deferred.resolve(tmpChoice[0]);
                            }
                        });
                    } else {
                        deferred.resolve(tmpChoice[0]);
                    }
                }
                return deferred.promise;
            },
            getNumberOfShowingResults : function(games) {
                var showingGames = games.filter(function (game) {
                    if (game.show.inMenue === true) {
                        return game;
                    }
                });
                return showingGames.length;
            },
            getSingleUserResults: function(workshopID) {
                return $http({
                  method: 'GET',
                  url: __env.apiUrl + 'results/'+ workshopID + '/all/user',
                  headers: {
                    Authorization: 'Bearer ' + $localStorage.token,
                  }
                })
                  .then(function(response) {
                    return response.data;
                  })
                  .catch(function(error) {
                    return error;
                  });
            },
        }
    })
