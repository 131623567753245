/* eslint-disable angular/timeout-service */
/* eslint-disable angular/definedundefined */
/*! toolbar.js 2015-05-17 */
'function' != typeof Object.create &&
	(Object.create = function(a) {
		function b() {}
		return (b.prototype = a), new b();
	}),
	(function(a, b, c, d) {
		var e = {
			init: function(b, c) {
				var d = this;
				(d.elem = c),
					(d.$elem = a(c)),
					(d.options = a.extend({}, a.fn.toolbar.options, b)),
					(d.metadata = d.$elem.data()),
					d.overrideOptions(),
					(d.toolbar = a('<div class="tool-container" />')
						.addClass('tool-' + d.options.position)
						.addClass('toolbar-' + d.options.style)
						.append('<div class="tool-items" />')
						.append('<div class="arrow" />')
						.appendTo('body')
						.css('opacity', 0)
						.hide()),
					(d.toolbar_arrow = d.toolbar.find('.arrow')),
					d.initializeToolbar();
			},
			overrideOptions: function() {
				var b = this;
				a.each(b.options, function(a) {
					'undefined' != typeof b.$elem.data('toolbar-' + a) && (b.options[a] = b.$elem.data('toolbar-' + a));
				});
			},
			initializeToolbar: function() {
				var a = this;
				a.populateContent(), a.setTrigger(), (a.toolbarWidth = a.toolbar.width());
			},
			setTrigger: function() {
				function c() {
					d.$elem.hasClass('pressed')
						? (e = setTimeout(function() {
								d.hide();
						  }, 150))
						: clearTimeout(e);
				}
				var d = this;
				if ('click' != d.options.event) {
					var e;
					d.$elem.on({
						mouseenter: function(a) {
							d.$elem.hasClass('pressed') ? clearTimeout(e) : d.show();
						},
					}),
						d.$elem.parent().on({
							mouseleave: function(a) {
								c();
							},
						}),
						a('.tool-container').on({
							mouseenter: function(a) {
								clearTimeout(e);
							},
							mouseleave: function(a) {
								c();
							},
						});
				}
				'click' == d.options.event &&
					(d.$elem.on('click', function(a) {
						a.preventDefault(), d.$elem.hasClass('pressed') ? d.hide() : d.show();
					}),
					d.options.hideOnClick &&
						a('html').on('click.toolbar', function(a) {
							a.target != d.elem && 0 === d.$elem.has(a.target).length && 0 === d.toolbar.has(a.target).length && d.toolbar.is(':visible') && d.hide();
						})),
					a(b).resize(function(a) {
						a.stopPropagation(),
							d.toolbar.is(':visible') &&
								((d.toolbarCss = d.getCoordinates(d.options.position, 20)),
								d.collisionDetection(),
								d.toolbar.css(d.toolbarCss),
								d.toolbar_arrow.css(d.arrowCss));
					});
			},
			populateContent: function() {
				var b = this,
					c = b.toolbar.find('.tool-items'),
					d = a(b.options.content)
						.clone(!0)
						.find('a')
						.addClass('tool-item');
				c.html(d),
					c.find('.tool-item').on('click', function(a) {
						a.preventDefault(), b.$elem.trigger('toolbarItemClick', this);
					});
			},
			calculatePosition: function() {
				var a = this;
				(a.arrowCss = {}),
					(a.toolbarCss = a.getCoordinates(a.options.position, a.options.adjustment)),
					(a.toolbarCss.position = 'absolute'),
					(a.toolbarCss.zIndex = a.options.zIndex),
					a.collisionDetection(),
					a.toolbar.css(a.toolbarCss),
					a.toolbar_arrow.css(a.arrowCss);
			},
			getCoordinates: function(a, b) {
				var c = this;
				switch (
					((c.coordinates = c.$elem.offset()),
					c.options.adjustment && c.options.adjustment[c.options.position] && (b = c.options.adjustment[c.options.position] + b),
					c.options.position)
				) {
					case 'top':
						return {
							left: c.coordinates.left - c.toolbar.width() / 2 + c.$elem.outerWidth() / 2,
							top: c.coordinates.top - c.$elem.outerHeight() - b,
							right: 'auto',
						};
					case 'left':
						return {
							left: c.coordinates.left - c.toolbar.width() / 2 - c.$elem.outerWidth() / 2 - b,
							top: c.coordinates.top - c.toolbar.height() / 2 + c.$elem.outerHeight() / 2,
							right: 'auto',
						};
					case 'right':
						return {
							left: c.coordinates.left + c.toolbar.width() / 2 + c.$elem.outerWidth() / 2 + b,
							top: c.coordinates.top - c.toolbar.height() / 2 + c.$elem.outerHeight() / 2,
							right: 'auto',
						};
					case 'bottom':
						return {
							left: c.coordinates.left - c.toolbar.width() / 2 + c.$elem.outerWidth() / 2,
							top: c.coordinates.top + c.$elem.outerHeight() + b,
							right: 'auto',
						};
				}
			},
			collisionDetection: function() {
				var c = this,
					d = 20;
				('top' == c.options.position || 'bottom' == c.options.position) &&
					((c.arrowCss = { left: '50%', right: '50%' }),
					c.toolbarCss.left < d
						? ((c.toolbarCss.left = d), (c.arrowCss.left = c.$elem.offset().left + c.$elem.width() / 2 - d))
						: a(b).width() - (c.toolbarCss.left + c.toolbarWidth) < d &&
						  ((c.toolbarCss.right = d),
						  (c.toolbarCss.left = 'auto'),
						  (c.arrowCss.left = 'auto'),
						  (c.arrowCss.right = a(b).width() - c.$elem.offset().left - c.$elem.width() / 2 - d - 5)));
			},
			show: function() {
				var a = this;
				a.$elem.addClass('pressed'),
					a.calculatePosition(),
					a.toolbar
						.show()
						.css({ opacity: 1 })
						.addClass('animate-' + a.options.animation),
					a.$elem.trigger('toolbarShown');
			},
			hide: function() {
				var a = this,
					b = { opacity: 0 };
				switch ((a.$elem.removeClass('pressed'), a.options.position)) {
					case 'top':
						b.top = '+=20';
						break;
					case 'left':
						b.left = '+=20';
						break;
					case 'right':
						b.left = '-=20';
						break;
					case 'bottom':
						b.top = '-=20';
				}
				a.toolbar.animate(b, 200, function() {
					a.toolbar.hide();
				}),
					a.$elem.trigger('toolbarHidden');
			},
			getToolbarElement: function() {
				return this.toolbar.find('.tool-items');
			},
		};
		(a.fn.toolbar = function(b) {
			if (a.isPlainObject(b))
				return this.each(function() {
					var c = Object.create(e);
					c.init(b, this), a(this).data('toolbarObj', c);
				});
			if ('string' == typeof b && 0 !== b.indexOf('_')) {
				var c = a(this).data('toolbarObj'),
					d = c[b];
				return d.apply(c, a.makeArray(arguments).slice(1));
			}
		}),
			(a.fn.toolbar.options = {
				content: '#myContent',
				position: 'top',
				hideOnClick: !1,
				zIndex: 120,
				hover: !1,
				style: 'default',
				animation: 'standard',
				adjustment: 10,
			});
	})(jQuery, window, document);
