angular.module("app").component("pyramid", {
	templateUrl: "app/dashboard/game/06-pyramid.html",
	controllerAs: "self",

	controller: function (
		$rootScope,
		$scope,
		$state,
		titleService,
		$timeout,
		$localStorage,
		$window,
		dashboardFactory,
		showMyResultsService,
		utilService,
		socket,
		sweetAlert,
		__env
	) {
		if (__env.enableDebug) {
			console.log("pyramidController");
		}

		if (
			$localStorage.role === "super admin" ||
			$localStorage.role === "facilitator"
		) {
			$state.go("dashboard.group-manage");
		}

		// Set this to self to avoid conflicts
		const self = this;

		// Don't show popup if phase has been completed
		self.showPopup = true;
		if (
			angular.isDefined($localStorage.progress) && 
			angular.isDefined($localStorage.progress.pyramid[0]) &&
			$localStorage.progress.pyramid[0].completed
		) {
			if (__env.enableDebug) {
				console.log("This game has been completed");
			}
			self.showPopup = false;
		}

		$scope.navigateToGamePhase = function(gamePhaseName, completedPhaseNumber) {
			var result = utilService.navigateToGamePhase(gamePhaseName, completedPhaseNumber);
			if (result) {
				$state.go(result);
			}
		};

		// Popup Content
		$scope.popupContent =
			'<div class="row">\
				<div class="col-md-12">\
					<p><strong>Bringing it all together</strong></p>\
					<p>Now that all the elements of the Believer\'s Pyramid have been examined and discussed thoroughly, it\'s time to bring it all together in a coherent story. The Beliver\'s Pyramid is the core structure of a narrative and, like every story, needs to follow a logic that connects all the elements.</p>\
					\
					<hr>\
					<p><strong>A suggestion as you dive in</strong></p>\
					<p>It might be useful to look back at what you have done in the previous sessions and use those elements as inspiration. Follow what seems more engaging and that has the potential to drive passionate dedication.</p>\
					<p><a target="_blank" href="https://drive.google.com/file/d/10JLo0HNNIo8L9a0gC8UnYxaJDONepjJU/view">Click here to view examples</a></p>\
					\
					<hr>\
					<p><strong>Where we start</strong></p>\
					<p>Every good story need a protagonist. We suggest starting from the people the brand serves, following with the enemy they are facing (the human truth). Then, move to the revelation that defeats the struggle (the brand point of view) to finish with the concrete action that helps to win the day (the product truth). While you build the elements, you will realize that you may need to go back and forth several times to adjust and review the elements within the bigger context of how they hinge together.</p>\
					</div>\
				</div>\
			</div>';

		// Set the basic controller data to be passed to title service
		titleService.data = {
			title: "Building your pyramid",
			subtitle:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.",
		};

		// Broadcast section title to the header
		$rootScope.$broadcast("headerTitle", {
			title: "Building your pyramid",
			icon: "fa-dashboard",
		});

		$scope.currentGame = 6;
		$scope.showOthers = false;
		$rootScope.userEmail = $localStorage.user.email;
		$scope.email = $rootScope.userEmail;
		$scope.choiceSet = {
			messages: [],
			editing: [],
			whoIsEditing: [],
			isDisabled: [],
			parts: [],
			questions: [],
			ids: [],
			names: [],
		};
		$scope.editing = false;
		$scope.isDisabled = false;
		$scope.whoIsEditing = "check";
		$scope.loadingScreen = true;
		$scope.Math = $window.Math;
		$scope.chatStatementMessage = null;

		// Get and Set available game results for show my results component
		showMyResultsService.getAvailableGameResults($scope.currentGame).then(function(myGameResults) {
			$scope.myResults = myGameResults;
			$scope.gameResultsToShow = {};
			$scope.currentResults = ($scope.myResults.length > 0) ? $scope.myResults[0].value : 'default';
			$scope.numberOfResults = showMyResultsService.getNumberOfShowingResults($scope.myResults);
		});

		// Set the function to update selected results on component
		$scope.updateSelectedResults = function(selectedResults) {
			if (angular.isDefined($scope.myResults)) {
				showMyResultsService.updateSelectedResults(selectedResults, $scope.myResults).then(function(result) {
					$scope.gameResultsToShow = result;
				});
			}
		};

		// Run when component is ready
		self.$onInit = function () {
			$scope.currentWorkshopId = $localStorage.chosenWorkshop.id;
			$scope.userGroups = $localStorage.groups;
			$scope.facilitatorShown = false;
			$gid = 0;
			$scope.QandA = [];

			dashboardFactory.progress().then(
				function (result) {
					// Set completed state
					if (angular.isDefined(result.progress.pyramid[0])) {
						$scope.completed = result.progress.pyramid[0].completed;
					}
					// Get the users current workshop game
					var currentProgress = JSON.parse(result.current_progress);
					$scope.game = currentProgress.current_game;
					var gotoPhase =  utilService.navigateToGamePhase($scope.currentGame, $scope.game, $scope.currentGame);
					if (angular.isDefined(gotoPhase) && gotoPhase !== "dashboard.pyramid") {
						$state.go(gotoPhase, {}, { reload: true });
					} else {
						$localStorage.lastPlayedPhase = $scope.game;
					}

					if ($localStorage.chosenWorkshop) {
						dashboardFactory.fetch().then(
							function (result2) {
								$scope.chosenWorkshop = result2.group;
								$localStorage.chosenWorkshop = result2.group;
								$scope.progress = result.progress;
								$localStorage.progress = result.progress;

								// Manage locking of content
								$scope.groupId = result.progress.pyramid[0].group_id;
								if(result2.locks['group-pyramid'] && result2.locks['group-pyramid'][$scope.groupId]){
									$scope.locked = true;
								}

								if (
									angular.isDefined($localStorage.progress) &&
									$localStorage.progress.pyramid[0].group_id &&
									$scope.chosenWorkshop.phase_four_open === "true"
								) {
									if ($localStorage.progress.pyramid[0].group_id) {
										$povGroupID = $localStorage.progress.pyramid[0].group_id;
										$scope.povGroupID = $povGroupID;
									} else {
										$scope.loadingScreen = false;
									}
								} else {
									$povGroupID = 0;
									$scope.povGroupID = $povGroupID;
									if (
										angular.isDefined($localStorage.progress) &&
										$localStorage.progress.pyramid[0].human_truth &&
										$localStorage.progress.pyramid[0].people_we_serve &&
										$localStorage.progress.pyramid[0].brand_point_of_view &&
										$localStorage.progress.pyramid[0].product_truth
									) {
										$scope.loadingScreen = false;
									}
								}

								$scope.answerSet = { answers: [] };
								if ($localStorage.chosenWorkshop) {
									$scope.chosenWorkshop = $localStorage.chosenWorkshop;

									dashboardFactory.fetch().then(
										function (result) {
											$scope.chosenWorkshop = result.group;

											$localStorage.chosenWorkshop = result.group;
											angular.forEach(result.group.question, function (
												value,
												key
											) {
												$scope.answerSet.answers.push("");
												$q = {
													question: value.question,
													questionID: key + 1,
													question_id: value.id,
													type: value.type,
												};
												if (value.answer_options) {
													$splitoptions = value.answer_options.split(",");
													$options = [];
													angular.forEach($splitoptions, function (value, key) {
														$options.push({
															name: value,
															id: key + 1,
														});
														$scope.optionValue++;
													});
													$q.options = $options;
												}

												$scope.QandA.push($q);
											});

											$scope.optionValue = 1;
											$timeout(function () {
												$timeout(function () {
													if ($scope.loadingScreen && self.showPopup) {
														sweetAlert.swal({
															html: $scope.popupContent,
															customClass: "instructions",
															showCancelButton: false,
															showConfirmButton: false,
															showCloseButton: true,
														});
													}
												});
												if ($povGroupID > 0) {
													$scope.groupPyramidCompleted =
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].completed;

													$scope.choiceSet.messages.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].people_we_serve
													);
													$scope.choiceSet.editing.push("");
													$scope.choiceSet.whoIsEditing.push("check");
													$scope.choiceSet.isDisabled.push("");
													$scope.choiceSet.parts.push("people_we_serve");
													$scope.choiceSet.questions.push("People We Serve");
													$scope.choiceSet.ids.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].id
													);
													$scope.choiceSet.names.push("");

													$scope.choiceSet.messages.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].human_truth
													);
													$scope.choiceSet.editing.push("");
													$scope.choiceSet.whoIsEditing.push("check");
													$scope.choiceSet.isDisabled.push("");
													$scope.choiceSet.parts.push("human_truth");
													$scope.choiceSet.questions.push("Human Truth");
													$scope.choiceSet.ids.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].id
													);
													$scope.choiceSet.names.push("");

													$scope.choiceSet.messages.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].brand_point_of_view
													);
													$scope.choiceSet.editing.push("");
													$scope.choiceSet.whoIsEditing.push("check");
													$scope.choiceSet.isDisabled.push("");
													$scope.choiceSet.parts.push("brand_point_of_view");
													$scope.choiceSet.questions.push(
														"Brand Point Of View"
													);
													$scope.choiceSet.ids.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].id
													);
													$scope.choiceSet.names.push("");

													$scope.choiceSet.messages.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].product_truth
													);
													$scope.choiceSet.editing.push("");
													$scope.choiceSet.whoIsEditing.push("check");
													$scope.choiceSet.isDisabled.push("");
													$scope.choiceSet.parts.push("product_truth");
													$scope.choiceSet.questions.push("Product Truth");
													$scope.choiceSet.ids.push(
														$localStorage.chosenWorkshop.pyramid[
															$povGroupID - 1
														].id
													);
													$scope.choiceSet.names.push("");

													dashboardFactory
														.groupMembers(
															$scope.chosenWorkshop.id,
															$povGroupID,
															"pyramid"
														)
														.then(
															function (result) {
																$scope.groupMembers = result.members;
																$timeout(function () {});
															},
															function (error) {
																console.log(error);
															}
														);
												} else {
													$scope.choiceSet.messages.push(
														$localStorage.progress.pyramid[0].people_we_serve
													);
													$scope.choiceSet.parts.push("people_we_serve");
													$scope.choiceSet.questions.push("People We Serve");
													$scope.choiceSet.ids.push(
														$localStorage.progress.pyramid[0].id
													);

													$scope.choiceSet.messages.push(
														$localStorage.progress.pyramid[0].human_truth
													);
													$scope.choiceSet.parts.push("human_truth");
													$scope.choiceSet.questions.push("Human Truth");
													$scope.choiceSet.ids.push(
														$localStorage.progress.pyramid[0].id
													);

													$scope.choiceSet.messages.push(
														$localStorage.progress.pyramid[0]
															.brand_point_of_view
													);
													$scope.choiceSet.parts.push("brand_point_of_view");
													$scope.choiceSet.questions.push(
														"Brand Point Of View"
													);
													$scope.choiceSet.ids.push(
														$localStorage.progress.pyramid[0].id
													);

													$scope.choiceSet.messages.push(
														$localStorage.progress.pyramid[0].product_truth
													);
													$scope.choiceSet.parts.push("product_truth");
													$scope.choiceSet.questions.push("Product Truth");
													$scope.choiceSet.ids.push(
														$localStorage.progress.pyramid[0].id
													);
												}
											});
										},
										function (error) {
											console.log(error);
										}
									);
									$wid = $scope.chosenWorkshop.id;
									dashboardFactory
										.collaborationHumanGroupResults($wid, $gid)
										.then(function (result) {
											$scope.humanGroups = result.groups;
										});
								} else {
									$scope.chosenWorkshop = null;
								}

								$scope.url = __env.apiUrl;
								$scope.finish = function () {
									$state.go("dashboard.dashboard");
								};

								if ($localStorage.user) {
									$scope.user = $localStorage.user;
								}

								$scope.showMethod = function () {
									if ($scope.showDetails == true) {
										$scope.showDetails = false;
									} else {
										$scope.showDetails = true;
									}
								};

								dashboardFactory
									.collaborationProductGroupResults($wid, $gid)
									.then(function (result) {
										$scope.productGroups = result.groups;
									});

								dashboardFactory.immersionResults($wid).then(function (result) {
									$scope.immersions = result.immersions;
								});

								$scope.changeData = function (data, color) {
									$scope.dataPage = data;
									$scope.dataPageColor = color;
								};

								$scope.dataPage = "empty";

								// Build a new object with completed pyramids only
								$scope.completedPyramids = [];
								angular.forEach($localStorage.chosenWorkshop.pyramid, function (
									value,
									key
								) {
									if (value.completed) {
										$scope.completedPyramids.push(value);
									}
								});

								// Group pyramids
								$scope.groupPyramids = {
									1 : $localStorage.chosenWorkshop.pyramid[0],
									2 : $localStorage.chosenWorkshop.pyramid[1],
									3 : $localStorage.chosenWorkshop.pyramid[2],
									4 : $localStorage.chosenWorkshop.pyramid[3]
								}

								$scope.categories = [];
								$scope.data = [];
								dashboardFactory
									.collaborationPovGroupResults($wid, $gid)
									.then(function (result) {
										$scope.groupAnswers1 = result.groupOne.answer;
										$scope.brandStatements1 = result.groupOne.brandStatements;
										$scope.tshirts1 = result.groupOne.tshirts;
										$scope.categories1 = [];
										$scope.data1 = [];
										angular.forEach(result.groupOne.answer, function (
											value,
											key
										) {
											$scope.categories1.push(value.answer);
											$scope.data1.push(value.count);
										});

										/// //
										$scope.groupAnswers2 = result.groupTwo.answer;
										$scope.brandStatements2 = result.groupTwo.brandStatements;
										$scope.tshirts2 = result.groupTwo.tshirts;
										$scope.categories2 = [];
										$scope.data2 = [];
										angular.forEach(result.groupTwo.answer, function (
											value,
											key
										) {
											$scope.categories2.push(value.answer);
											$scope.data2.push(value.count);
										});

										/// //
										$scope.groupAnswers3 = result.groupThree.answer;
										$scope.brandStatements3 = result.groupThree.brandStatements;
										$scope.tshirts3 = result.groupThree.tshirts;
										$scope.categories3 = [];
										$scope.data3 = [];
										angular.forEach(result.groupThree.answer, function (
											value,
											key
										) {
											$scope.categories3.push(value.answer);
											$scope.data3.push(value.count);
										});

										/// //
										$scope.groupAnswers4 = result.groupFour.answer;
										$scope.brandStatements4 = result.groupFour.brandStatements;
										$scope.tshirts4 = result.groupFour.tshirts;
										$scope.categories4 = [];
										$scope.data4 = [];
										angular.forEach(result.groupFour.answer, function (
											value,
											key
										) {
											$scope.categories4.push(value.answer);
											$scope.data4.push(value.count);
										});
									});
								if ($povGroupID > 0) {
									if (
										$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
											.human_truth
									) {
										$human_truth =
											$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
												.human_truth;
									} else {
										$human_truth = "Statement for Human Truth here";
									}

									if (
										$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
											.product_truth
									) {
										$product_truth =
											$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
												.product_truth;
									} else {
										$product_truth = "Statement for Product Truth here";
									}

									if (
										$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
											.people_we_serve
									) {
										$people_we_serve =
											$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
												.people_we_serve;
									} else {
										$people_we_serve = "People We Serve Here";
									}

									if (
										$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
											.brand_point_of_view
									) {
										$brand_point_of_view =
											$localStorage.chosenWorkshop.pyramid[$povGroupID - 1]
												.brand_point_of_view;
									} else {
										$brand_point_of_view = "Statement for Brand Pov here";
									}

									$scope.immersionChat = [];

									angular.forEach(
										$scope.chosenWorkshop.pyramid[$povGroupID - 1].chat,
										function (value, key) {
											if ($rootScope.userEmail == value.email) {
												is_currentUser = "true";
											} else {
												is_currentUser = "false";
											}

											$message = {
												id: value.id,
												message: value.message,
												name: value.name,
												user: value.email,
												timestamp: value.created_at,
												is_reply: is_currentUser,
											};
											$scope.immersionChat.push($message);
											$timeout(function () {
												var scroller = document.getElementById("chatscreen");
												if(scroller) scroller.scrollTop = scroller.scrollHeight;
											});
										}
									);
								} else {
									if (angular.isDefined($scope.progress) && angular.isDefined($scope.progress.pyramid[0]) && $scope.progress.pyramid[0].human_truth) {
										$human_truth = $scope.progress.pyramid[0].human_truth;
									} else {
										$human_truth = "Statement for Human Truth here";
									}

									if (angular.isDefined($scope.progress) && angular.isDefined($scope.progress.pyramid[0]) && $scope.progress.pyramid[0].product_truth) {
										$product_truth = $scope.progress.pyramid[0].product_truth;
									} else {
										$product_truth = "Statement for Product Truth here";
									}

									if (angular.isDefined($scope.progress) && angular.isDefined($scope.progress.pyramid[0]) && $scope.progress.pyramid[0].people_we_serve) {
										$people_we_serve =
											$scope.progress.pyramid[0].people_we_serve;
									} else {
										$people_we_serve = "People We Serve Here";
									}

									if (angular.isDefined($scope.progress) && angular.isDefined($scope.progress.pyramid[0]) && $scope.progress.pyramid[0].brand_point_of_view) {
										$brand_point_of_view =
											$scope.progress.pyramid[0].brand_point_of_view;
									} else {
										$brand_point_of_view = "Statement for Brand Pov here";
									}
								}

								$scope.checkAnswers = function () {
									var otherThanNull = $scope.choiceSet.messages.some(function (
										el
									) {
										return el == null;
									});
									return otherThanNull;
								};
								$scope.pyramid = {
									human_truth: $human_truth,
									product_truth: $product_truth,
									serve: $people_we_serve,
									brand_purpose: $brand_point_of_view,
								};
								$scope.splitWords = [];
								$scope.QandA = [];
								$scope.statements = [];
								$scope.answerSet = { answers: [] };

								$rootScope.userEmail = $localStorage.user.email;
								$scope.answer2 = null;
								$scope.statement_pov_id = null;
								$scope.workshop = null;
								$scope.workshops = $localStorage.workshops;
								if ($localStorage.selectedWorkshop) {
									$scope.workshop = $localStorage.selectedWorkshop;
								} else {
									$scope.workshop = null;
								}

								// Socket for statement
								socket.on("recieveStatement", function (data) {
									if (data.id == $localStorage.statement_pov_id) {
										$scope.statement = data;
									}
								});

								// Socket for chat
								socket.on("recieveImmersionMessage", function (data) {
									if (
										data.pyramid[0].id ==
										$scope.chosenWorkshop.pyramid[$povGroupID - 1].id
									) {
										if ($rootScope.userEmail == data.email) {
											data.is_reply = "true";
										} else {
											data.is_reply = "false";
										}

										$message = {
											id: data.id,
											message: data.message,
											name: data.name,
											user: data.email,
											timestamp: data.created_at,
											is_reply: data.is_reply,
										};
										$scope.immersionChat.push($message);
										var elem = angular.element(
											document.querySelector("#immersionChat")
										);
										elem.val("");
										$timeout(function () {
											var scroller = document.getElementById("chatscreen");
											scroller.scrollTop = scroller.scrollHeight;
										});
									}
								});
								// User clicks input to edit
								$scope.focusInput = function (data, id) {
									angular.forEach($scope.choiceSet.parts, function (
										value,
										key
									) {
										if (data === value && id == $scope.choiceSet.ids[key]) {
											$name =
												$localStorage.user.name +
												" " +
												$localStorage.user.surname.substr(0, 1);
											// Broadcasts to to connected sockets that the user is editing an input
											socket.emit("editing", {
												user: $rootScope.userEmail,
												name: $name,
												element: data,
												id: $scope.choiceSet.ids[key],
											});
										}
									});
								};

								$scope.others = function () {
									if ($scope.showOthers) {
										$scope.showOthers = false;
									} else {
										$scope.showOthers = true;
									}
								};

								// User wants to unlock a input that another user is editing
								$scope.unlockInput = function (data, id) {
									angular.forEach($scope.choiceSet.parts, function (
										value,
										key
									) {
										if (data === value && id == $scope.choiceSet.ids[key]) {
											// Show sweet alert with callback
											sweetAlert
												.swal(
													{
														title: "Warning",
														text:
															"Please note by unlocking you are removing " +
															$scope.choiceSet.names[key] +
															" from editing.",
														type: "warning",
													},
													function () {}
												)
												.then(function () {
													$name =
														$localStorage.user.name +
														" " +
														$localStorage.user.surname.substr(0, 1);
													socket.emit("editing", {
														user: $rootScope.userEmail,
														name: $name,
														element: data,
														id: $scope.choiceSet.ids[key],
													});
												});
										}
									});
								};

								// SUbmit edit of input to the api
								$scope.sumbitPyramid = function ($part, id) {
									angular.forEach($scope.choiceSet.parts, function (
										value,
										key
									) {
										if ($part === value && id == $scope.choiceSet.ids[key]) {
											$scope.input = {
												part: $part,
												message: $scope.choiceSet.messages[key],
												profiler_id: $scope.choiceSet.ids[key],
											};

											dashboardFactory.savePyramid($scope.input).then(
												function (result) {
													$timeout(function () {
														socket.emit("newProfiler", result.profiler);
													});
												},
												function (error) {
													console.log(error);
												}
											);
										}
									});
								};

								// Socket to recieve who is editing an input
								socket.on("editor", function (data) {
									angular.forEach($scope.choiceSet.parts, function (
										value,
										key
									) {
										if (
											data.element === value &&
											data.id == $scope.choiceSet.ids[key]
										) {
											$scope.choiceSet.whoIsEditing[key] = data.user;
											$scope.choiceSet.names[key] = data.name;
											if ($scope.choiceSet.whoIsEditing[key] == null) {
												$scope.choiceSet.isDisabled[key] = false;
											} else {
												if (
													$scope.choiceSet.whoIsEditing[key] !==
													$rootScope.userEmail
												) {
													$scope.choiceSet.isDisabled[key] = true;
												} else {
													$scope.choiceSet.isDisabled[key] = false;
												}

												$scope.choiceSet.editing[key] = false;
											}
										}
									});
								});

								// Socket to receive change to an input from another user
								socket.on("profiler", function (data) {
									angular.forEach($scope.choiceSet.parts, function (
										value,
										key
									) {
										if (
											data.part === value &&
											data.id == $scope.choiceSet.ids[key]
										) {
											$scope.choiceSet.messages[key] = data.message;
											dashboardFactory.fetch().then(
												function (result) {
													$scope.chosenWorkshop = result.group;
													$localStorage.chosenWorkshop = result.group;
													dashboardFactory.progress().then(
														function (result) {
															$scope.progress = result.progress;
															$localStorage.progress = result.progress;
															// Get the users current workshop game
															$scope.game = JSON.parse(result.current_progress).current_game;
															$timeout(function () {
																if ($povGroupID > 0) {
																	if (
																		$localStorage.chosenWorkshop.pyramid[
																			$povGroupID - 1
																		].human_truth
																	) {
																		$human_truth =
																			$localStorage.chosenWorkshop.pyramid[
																				$povGroupID - 1
																			].human_truth;
																	} else {
																		$human_truth =
																			"Statement for Human Truth here";
																	}

																	if (
																		$localStorage.chosenWorkshop.pyramid[
																			$povGroupID - 1
																		].product_truth
																	) {
																		$product_truth =
																			$localStorage.chosenWorkshop.pyramid[
																				$povGroupID - 1
																			].product_truth;
																	} else {
																		$product_truth =
																			"Statement for Product Truth here";
																	}

																	if (
																		$localStorage.chosenWorkshop.pyramid[
																			$povGroupID - 1
																		].people_we_serve
																	) {
																		$people_we_serve =
																			$localStorage.chosenWorkshop.pyramid[
																				$povGroupID - 1
																			].people_we_serve;
																	} else {
																		$people_we_serve = "People We Serve Here";
																	}

																	if (
																		$localStorage.chosenWorkshop.pyramid[
																			$povGroupID - 1
																		].brand_point_of_view
																	) {
																		$brand_point_of_view =
																			$localStorage.chosenWorkshop.pyramid[
																				$povGroupID - 1
																			].brand_point_of_view;
																	} else {
																		$brand_point_of_view =
																			"Statement for Brand Pov here";
																	}
																} else {
																	if ($scope.progress.pyramid[0].human_truth) {
																		$human_truth =
																			$scope.progress.pyramid[0].human_truth;
																	} else {
																		$human_truth =
																			"Statement for Human Truth here";
																	}

																	if (
																		$scope.progress.pyramid[0].product_truth
																	) {
																		$product_truth =
																			$scope.progress.pyramid[0].product_truth;
																	} else {
																		$product_truth =
																			"Statement for Product Truth here";
																	}

																	if (
																		$scope.progress.pyramid[0].people_we_serve
																	) {
																		$people_we_serve =
																			$scope.progress.pyramid[0]
																				.people_we_serve;
																	} else {
																		$people_we_serve = "People We Serve Here";
																	}

																	if (
																		$scope.progress.pyramid[0]
																			.brand_point_of_view
																	) {
																		$brand_point_of_view =
																			$scope.progress.pyramid[0]
																				.brand_point_of_view;
																	} else {
																		$brand_point_of_view =
																			"Statement for Brand Pov here";
																	}
																}

																$scope.pyramid = {
																	human_truth: $human_truth,
																	product_truth: $product_truth,
																	serve: $people_we_serve,
																	brand_purpose: $brand_point_of_view,
																};
															});
														},
														function (error) {
															console.log(error);
														}
													);
												},
												function (error) {
													console.log(error);
												}
											);
										}
									});
								});
							},
							function (error) {
								console.log(error);
							}
						);
					} else {
						$scope.chosenWorkshop = null;
					}
				},
				function (error) {
					console.log(error);
				}
			);
		};

		$scope.next = function () {
			socket.emit("someOneSubmitted", "true");
			$state.go("dashboard.completed");
		};

		$scope.showFacilitator = function ($group, $game) {

			dashboardFactory
				.notifyFacilitator($scope.currentWorkshopId, $group, $game)
				.then(
					function () {
						$rootScope.facilitatorPopUp("no redirect");

						// Broadcast lock for all other group participants
						let lockedData = {
							wid: $scope.currentWorkshopId,
							gid: $group,
							game: $game,
							method: "lock"
						}
						socket.emit("lockGroupAnswers", lockedData);

					},
					function (error) {
						console.log(error);
					}
				);
		};

		// Listen for answer locking broadcast
		socket.on("lockGroupAnswers", function (data) {
			// Is broadcast is applicable to current phase?
			if(data.game == "Group Pyramid"){
				// Is broadcast applicable to this workshop and group?
				if(parseInt(data.wid) == parseInt($scope.currentWorkshopId) && parseInt(data.gid) == parseInt($localStorage.progress.pyramid[0].group_id)){
					// Lock answers
					if(data.method == "lock"){
						console.log("[+] Recieved lock broadcast");
						$scope.locked = 1;
					}
					// Unlock answers
					if(data.method == "unlock"){
						console.log("[+] Recieved unlock broadcast");
						$scope.locked = 0;
					}
				}
			}
		});

		// Request that a Facilitator joins the chate
		$scope.requestFacilitatorJoinsChat = function ($group, $game){
			// Broadcast lock for all other group participants
			let payload = {
				wid: parseInt($scope.currentWorkshopId),
				gid: parseInt($group),
				game: $game,
			}
			socket.emit("requestFacilitatorJoinsChat", payload);

			sweetAlert.swal({
				title: "Requested",
				text: "The facilitator has been asked to join this group.",
				type: "warning",
			});

		}

		$scope.nextPyramid = function () {
			dashboardFactory
				.submitPyramid(
					$scope.chosenWorkshop.id,
					$localStorage.progress.pyramid[0].id
				)
				.then(
					function () {
						$scope.loadingScreen = false;
						socket.emit("someOneSubmitted", "true");
					},
					function (error) {
						console.log(error);
					}
				);
		};

		$scope.passThePen = function (data, id) {
			angular.forEach($scope.choiceSet.parts, function (value, key) {
				if (data === value && id == $scope.choiceSet.ids[key]) {
					// Broadcasts to to connected sockets that the user is editing an input
					socket.emit("editing", {
						user: null,
						name: null,
						element: data,
						id: $scope.choiceSet.ids[key],
					});
				}
			});
		};

		$scope.contactFacilitator = function () {
			Swal({
				title: "Message to facilitator",
				input: "textarea",
				inputPlaceholder: "Type your message here...",
				showCancelButton: true,
			}).then((result) => {
				if (result.value) {
					$input = {
						id: $localStorage.chosenWorkshop.id,
						message: result.value,
					};
					dashboardFactory.facilitatorMail($input).then(function (result) {
						Swal(result.message);
					});
				}
			});
		};

		// Show all group individual pyramids

		socket.on("groupAssigned", function () {
			location.reload();
		});

		// Send a chat message
		$scope.sendChatMessage = function (message, game, chatId, emit) {
			$scope.input = {
				message: message,
				chat_id: $localStorage.chosenWorkshop.pyramid[$povGroupID - 1].id,
			};
			dashboardFactory.chat($scope.input, game).then(
				function (result) {
					result.chats.is_reply = "true"; // IN8-150
					socket.emit(emit, result.chats);
					angular.element(document.getElementById(game)).val("");
				},
				function (error) {
					console.log(error);
				}
			);
		};

		socket.on("recievePovGroup", function (data) {
			if (data.id == $localStorage.chosenWorkshop.id) {
				dashboardFactory.progress().then(
					function (result) {
						$scope.loadingScreen = true;
						$scope.progress = result.progress;
						$localStorage.progress = result.progress;
						// Get the users current workshop game
						$scope.game = JSON.parse(result.current_progress).current_game;
						if ($localStorage.progress.pov[0].group_id) {
							$localStorage.statement_id =
								$scope.statements[
									$localStorage.progress.pov[0].group_id - 1
								].id;
							$scope.groupPov = $localStorage.progress.pov[0].group_id;
							$scope.progress = $localStorage.progress;
							$scope.collaborationPovGroupResults(
								$scope.chosenWorkshop.id,
								$scope.groupPov
							);
						}

						var html1 = null;
						var html2 = null;
						var html3 = null;
						angular.forEach($scope.chosenWorkshop.immersion_data[0], function (
							value,
							key
						) {
							if (key === "heading_1") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_1 +
											"</h4>";
									} else {
										html1 =
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_1 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_1") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_1 +
											"</p>";
									} else {
										html1 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_1 +
											"</p>";
									}
								}
							}

							if (key === "heading_2") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_2 +
											"</h4>";
									} else {
										html1 =
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_2 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_2") {
								if (value) {
									if (html1) {
										html1 =
											html1 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_2 +
											"</p>";
									} else {
										html1 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_2 +
											"</p>";
									}
								}
							}

							if (key === "heading_3") {
								if (value) {
									if (html2) {
										html2 =
											html2 +
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_3 +
											"</h4>";
									} else {
										html2 =
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_3 +
											"</h4>";
									}
								}
							}

							if (key === "video") {
								if (value) {
									if (html2) {
										html2 =
											html2 +
											'<video width="100%" src="' +
											$scope.url +
											$scope.chosenWorkshop.immersion_data[0].video +
											'" controls>';
									} else {
										html2 =
											'<video width="100%" src="' +
											$scope.url +
											$scope.chosenWorkshop.immersion_data[0].video +
											'" controls>';
									}
								}
							}

							if (key === "heading_4") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_4 +
											"</h4>";
									} else {
										html3 =
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_4 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_4") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_4 +
											"</p>";
									} else {
										html3 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_4 +
											"</p>";
									}
								}
							}

							if (key === "heading_5") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_5 +
											"</h4>";
									} else {
										html3 =
											"<h4>" +
											$scope.chosenWorkshop.immersion_data[0].heading_5 +
											"</h4>";
									}
								}
							}

							if (key === "paragraph_5") {
								if (value) {
									if (html3) {
										html3 =
											html3 +
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_5 +
											"</p>";
									} else {
										html3 =
											'<p class="m-0">' +
											$scope.chosenWorkshop.immersion_data[0].paragraph_5 +
											"</p>";
									}
								}
							}
						});
						$timeout(function () {
							var htmlmain =
								'<div class="row"><div class="col-md-6 mt-3">' +
								html1 +
								'</div><div class="col-md-6 mt-3">' +
								html2 +
								'</div></div><hr><div class="row instructions"><div class="col-md-12">' +
								html3 +
								"</div></div>";
							sweetAlert.swal({
								html: htmlmain,
								customClass: "instructions",
								showCancelButton: false,
								showConfirmButton: false,
								showCloseButton: true,
							});
						});
					},
					function (error) {
						console.log(error);
					}
				);
			}
		});

		// Socket to check if any inputs are locked by another user
		socket.on("editingCheckStatementPov", function (data) {
			if (data === "check") {
				if ($scope.whoIsEditing != "check") {
					socket.emit("editingStatementPov", {
						user: $scope.whoIsEditing,
						id: $localStorage.statement.id,
					});
				}
			}
		});

		// Socket to recieve who is editing an input
		socket.on("editorStatementPov", function (data) {
			if (data.id == $localStorage.statement.id) {
				$scope.whoIsEditing = data.user;

				if ($scope.whoIsEditing == null) {
					$scope.isDisabled = false;
				} else {
					if ($scope.whoIsEditing !== $rootScope.userEmail) {
						$scope.isDisabled = true;
					} else {
						$scope.isDisabled = false;
					}

					$scope.editing = false;
				}
			}
		});

		socket.on("recieveAdminJoined", function (data) {
			if (
				data.workshop == $scope.chosenWorkshop.id &&
				data.group == $localStorage.progress.pyramid[0].group_id &&
				data.game === "Believers Pyramid"
			) {
				if (
					!$scope.groupMembers.some((item) =>
						item.email.includes(data.user.email)
					)
				) {
					$scope.groupMembers.push(data.user);
				}

				sweetAlert
					.swal(
						{
							title: "Note",
							text: "The facilitator has joined the group.",
							type: "warning",
						},
						function () {}
					)
					.then(function () {});
			}
		});

		// Listen for socket push
		socket.on("groupApproved", function (data) {
			if (
				data.group == $scope.progress.pyramid[0].group_id &&
				data.phase === "Phase Four"
			) {
				sweetAlert.swal({
					title: "Congratulations",
					text: "The facilitator has approved this final phase.",
					type: "success",
					allowOutsideClick: false,
					confirmButtonText: "Done",
				});
				$scope.groupPyramidCompleted = true;
				$state.go("dashboard.completed");
			}
		});
	},
});
