angular.module('app').component('manageContent', {
	templateUrl: 'app/dashboard/group/group-content.html',
	controllerAs: 'vm',
	bindings: {
		groups: '<',
		users: '<',
	},

	controller: function(
		__env,
		$rootScope,
		dashboardFactory,
		$scope,
		$localStorage,
		$stateParams,
		$document,
		groupFactory,
		userFactory,
		sweetAlert,
		titleService,
		Upload,
		$timeout,
		ngToast,
		$compile
	) {
		// Set this to self to avoid conflicts
		const self = this;

		// Manage reminder to save
		$scope.unsaved = false;

		if (!$scope.unsaved) {
			document.addEventListener('keyup', function(event) {
				$scope.unsaved = true;
			});
		}

		// Set the basic controller data to be passed to title service
		titleService.data = {
			title: 'Manage Content',
			subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo augue, placerat quis nisi sit amet, feugiat tristique mi.',
		};

		// Broadcast section title to the header
		$rootScope.$broadcast('headerTitle', {
			title: 'Content Management',
			icon: 'fa-users',
		});
		self.show = null;
		self.word = null;
		self.test = null;
		$scope.url = __env.apiUrl;
		// Set defaults
		self.toggle = true;
		self.uploadButton = 'Upload';
		self.uploadButtonVideo = 'Upload Video';
		self.uploadButtonImage = 'Upload Image';
		self.updatingQuestionFile = 'Update File';
		$scope.selectedPage = null;
		$scope.icons = ['icon-values.png', 'icon-belief.png', 'icon-hand.png', 'icon-grounds.png', 'icon-dilemma.png'];
		$scope.chosenWorkshop = $localStorage.chosenWorkshop;
		$scope.selectedTab;
		$scope.newprofilerQuestion = {
			icon: null,
			question: null,
		};

		$scope.immersionQuestion = {
			question: null,
			type: null,
		};

		$scope.povQuestionNew = {
			type: null,
		};
		$scope.sortableOptions = {
			containment: '#sortable-container',
		};

		$scope.htInstructions = false;
		$scope.contentPOV = 1;
		$scope.tensions = false;
		$scope.tensions = false;
		$scope.profiler = false;
		$scope.bpInstruction = false;
		$scope.bpQuestions = false;
		$scope.ptInstruction = false;
		$scope.psInstruction = false;
		$scope.ptQuestions = false;
		$scope.pwsQuestions = true;
		$scope.pwsInstruction = false;
		$scope.editImmersionQuestions = false;
		$scope.editPeoplePortrait = false;
		$scope.editProductTruth = false;
		$scope.editBrandPov = false;
		$scope.editProductTruthStatement = false;

		$scope.tensions = true;
		$scope.edittensions = false;
		$scope.editBrandPovStatements = false;

		$scope.newMainQuestion = '';
		$scope.profilerQuestion = false;

		$scope.showMethod = function($type) {
			if ($type === 'pwsInstruction') {
				if ($scope.pwsInstruction == true) {
					$scope.pwsInstruction = false;
				} else {
					$scope.pwsInstruction = true;
				}
			}

			if ($type === 'pwsQuestions') {
				if ($scope.pwsQuestions == true) {
					$scope.pwsQuestions = false;
				} else {
					$scope.pwsQuestions = true;
				}
			}

			if ($type === 'ptInstruction') {
				if ($scope.ptInstruction == true) {
					$scope.ptInstruction = false;
				} else {
					$scope.ptInstruction = true;
				}
			}

			if ($type === 'psInstruction') {
				if ($scope.psInstruction == true) {
					$scope.psInstruction = false;
				} else {
					$scope.psInstruction = true;
				}
			}

			if ($type === 'htInstructions') {
				if ($scope.htInstructions == true) {
					$scope.htInstructions = false;
				} else {
					$scope.htInstructions = true;
				}
			}

			if ($type === 'tensions') {
				if ($scope.tensions == true) {
					$scope.tensions = false;
				} else {
					$scope.tensions = true;
				}
			}

			if ($type === 'bpInstruction') {
				if ($scope.bpInstruction == true) {
					$scope.bpInstruction = false;
				} else {
					$scope.bpInstruction = true;
				}
			}

			if ($type === 'ptQuestions') {
				if ($scope.ptQuestions == true) {
					$scope.ptQuestions = false;
				} else {
					$scope.ptQuestions = true;
				}
			}

			if ($type === 'bpQuestions') {
				if ($scope.bpQuestions == true) {
					$scope.bpQuestions = false;
				} else {
					$scope.bpQuestions = true;
				}
			}

			if ($type === 'tensionGame') {
				if ($scope.tensionGame == true) {
					$scope.tensionGame = false;
				} else {
					$scope.tensionGame = true;
				}
			}

			if ($type === 'profiler') {
				if ($scope.profiler == true) {
					$scope.profiler = false;
				} else {
					$scope.profiler = true;
				}
			}
		};

		// Run when component is ready
		self.$onInit = function() {
			dashboardFactory.fetch().then(
				function(result) {
					$scope.chosenWorkshop = result.group;
					$localStorage.chosenWorkshop = result.group;
				},
				function(error) {}
			);
			$($document).ready(function() {
				$('.select-pane button').click(function() {
					$('.select-pane button').removeClass('shine');
					$(this).addClass('shine');
				});
			});

			$('#nav-facilitate-tab').click(function(event) {
				event.preventDefault();
				$('.tab-content').removeClass('turquoise');
				$('#nav-edit-content-tab').css({ 'background-color': '#4e535b', 'border-color': '#4e535b' });

				$('.tab-content').addClass('orange');
				$(this).css({ 'background-color': '#f58220', 'border-color': '#f58220' });
			});

			$('#nav-edit-content-tab').click(function(event) {
				event.preventDefault();
				$('.tab-content').removeClass('orange');
				$('#nav-facilitate-tab').css({ 'background-color': '#4e535b', 'border-color': '#4e535b' });

				$('.tab-content').addClass('turquoise');
				$(this).css({ 'background-color': '#08b2a6', 'border-color': '#08b2a6' });
			});
			$scope.toggleEdit = function($id) {
				if ($id === 'editImmersionQuestions') {
					if ($scope.editImmersionQuestions == true) {
						$scope.editImmersionQuestions = false;
					} else {
						$scope.editImmersionQuestions = true;
					}
				}
				if ($id === 'editPeoplePortrait') {
					if ($scope.editPeoplePortrait == true) {
						$scope.editPeoplePortrait = false;
					} else {
						$scope.editPeoplePortrait = true;
					}
				}
				if ($id === 'editProductTruth') {
					if ($scope.editProductTruth == true) {
						$scope.editProductTruth = false;
					} else {
						$scope.editProductTruth = true;
					}
				}
				if ($id === 'editBrandPov') {
					if ($scope.editBrandPov == true) {
						$scope.editBrandPov = false;
					} else {
						$scope.editBrandPov = true;
					}
				}

				if ($id === 'editProductTruthStatement') {
					if ($scope.editProductTruthStatement == true) {
						$scope.editProductTruthStatement = false;
					} else {
						$scope.editProductTruthStatement = true;
					}
				}
				if ($id === 'editBrandPovStatements') {
					if ($scope.editBrandPovStatements == true) {
						$scope.editBrandPovStatements = false;
					} else {
						$scope.editBrandPovStatements = true;
					}
				}

				if ($id === 'edittensions') {
					if ($scope.edittensions == true) {
						$scope.edittensions = false;
					} else {
						$scope.edittensions = true;
					}
				}
			};
			$scope.showContentPage = function($id) {
				$scope.selectedPage = $id;
				$scope.choiceSet = { messages: [], editing: [], whoIsEditing: [], isDisabled: [], parts: [], questions: [], icons: [], ids: [] };
				if ($id == 'bpov') {
					$scope.upload = 'Upload Image';
					$scope.QandA = [];
					$scope.answerSet = { answers: [] };
					$scope.optionValue = 1;
					$questionID = -1;
					angular.forEach($localStorage.chosenWorkshop.pov_question, function(value, key) {
						if (value.type === 'video' || value.type === 'image' || value.type === 'youtube') {
							$scope.answerSet.answers.push('none');
							$questionID++;
							$scope.mainQandA = [];
							$q = {
								question: value.question,
								questionID: $questionID,
								question_id: value.id,
								type: value.type,
								questions: $scope.mainQandA,
							};
							$scope.QandA.push($q);
							if (value.main.length) {
								angular.forEach(value.main, function(value2, key2) {
									$scope.answerSet.answers.push('');
									$questionID++;

									$q = {
										question: value2.question,
										questionID: $questionID,
										question_id: value2.id,
										type: value2.type,
									};
									$scope.mainQandA.push($q);
								});
							}
						}
						if (value.type === 'ordered') {
							$scope.answerSet.answers.push('');
							$questionID++;
							$q = {
								question: value.question,
								questionID: $questionID,
								question_id: value.id,
								type: value.type,
							};
							$scope.QandA.push($q);
						}
						if (value.type === 'words') {
							$scope.answerSet.answers.push('');
							$questionID++;
							$jsonObj = JSON.parse(value.answer_options);
							$options = [];
							angular.forEach($jsonObj, function(value, key) {
								$options.push({
									name: value.word,
									id: $questionID,
									color: value.color,
								});
							});
							$q = {
								question: value.question,
								questionID: $questionID,
								question_id: value.id,
								type: value.type,
							};
							$q.options = $options;
							$scope.QandA.push($q);
						}
					});

					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('message1');
					$scope.choiceSet.questions.push($localStorage.chosenWorkshop.bp_statement_1);
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.manifesto[0].id);

					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('message2');
					$scope.choiceSet.questions.push($localStorage.chosenWorkshop.bp_statement_2);
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.manifesto[0].id);

					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('message3');
					$scope.choiceSet.questions.push($localStorage.chosenWorkshop.bp_statement_3);
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.manifesto[0].id);

					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('message4');
					$scope.choiceSet.questions.push($localStorage.chosenWorkshop.bp_statement_4);
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.manifesto[0].id);
				}
				if ($id == 'bp') {
					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('people_we_serve');
					$scope.choiceSet.questions.push('People We Serve');
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[0].id);

					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('human_truth');
					$scope.choiceSet.questions.push('Human Truth');
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[0].id);

					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('brand_point_of_view');
					$scope.choiceSet.questions.push('Brand Point Of View');
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[0].id);

					$scope.choiceSet.messages.push('');
					$scope.choiceSet.editing.push('');
					$scope.choiceSet.whoIsEditing.push('check');
					$scope.choiceSet.isDisabled.push('');
					$scope.choiceSet.parts.push('product_truth');
					$scope.choiceSet.questions.push('Product Truth');
					$scope.choiceSet.ids.push($localStorage.chosenWorkshop.pyramid[0].id);

					$scope.pyramid = {
						human_truth: 'Statement for Human Truth here',
						product_truth: 'Statement for Product Truth here',
						serve: 'Statement for People We Serve here',
						brand_purpose: 'Statement for Brand Pov here',
					};
				}
				if ($id == 'ppws') {
					angular.forEach($localStorage.chosenWorkshop.collaboration_group[0].profiler, function(value, key) {
						$scope.choiceSet.messages.push('');
						$scope.choiceSet.editing.push('');
						$scope.choiceSet.whoIsEditing.push('check');
						$scope.choiceSet.isDisabled.push('');
						$scope.choiceSet.parts.push(value.part);
						$scope.choiceSet.ids.push(value.id);
						$scope.choiceSet.questions.push(value.profiler_question[0].question);
						$scope.choiceSet.icons.push(value.profiler_question[0].icon);
					});
					$scope.profilerQuestion = false;
				}

				if ($id == 'pt') {
					$scope.QandA = [];
					$scope.answerSet = { answers: [] };
					$scope.chosenWorkshop = $localStorage.chosenWorkshop;
					angular.forEach($localStorage.chosenWorkshop.question, function(value, key) {
						$scope.answerSet.answers.push('');
						$q = {
							question: value.question,
							questionID: key + 1,
							question_id: value.id,
							type: value.type,
						};
						if (value.type != 'words' && value.answer_options) {
							$splitoptions = value.answer_options.split(',');
							$options = [];
							angular.forEach($splitoptions, function(value, key) {
								$options.push({
									name: value,
									id: key + 1,
								});
								$scope.optionValue++;
							});
							$q.options = $options;
						}

						if (value.type === 'words') {
							$jsonObj = JSON.parse(value.answer_options);
							$options = [];
							angular.forEach($jsonObj, function(value, key) {
								$options.push({
									name: value.word,
									id: key + 1,
									color: value.color,
								});
							});
							$q.options = $options;
						}
						$scope.QandA.push($q);
					});
				}
				if ($id == 'immersion') {
					$scope.povQuestion = false;
				}

				$scope.selectedTab = $id;
			};
			$scope.showContentPage($stateParams.param1);
			$scope.defaultOptions = [];
			$scope.defaultOptions.push('../assets/img/blankOption.png');
			$scope.defaultOptions.push('../assets/img/blankOption.png');
			$scope.defaultOptions.push('../assets/img/blankOption.png');
			$scope.defaultOptions.push('../assets/img/blankOption.png');
			$scope.povQuestion = false;
			$scope.productExperience = false;
			$scope.tension = false;
			$scope.dataset = $localStorage.dataset;
			$scope.ptQuestion = {
				question: null,
				type: null,
				options: $scope.defaultOptions,
				words: [],
			};
			// self.pendingInvites = null;
			// self.pendingInviteDisplay = null;
			// self.pendingInviteMsg = null;
			self.group = $localStorage.chosenWorkshop;
			self.selectedGroupId = self.group.id;
			groupFactory.content(self.group.id).then(function(result) {
				$scope.content = result.content;
				angular.forEach($scope.content.product_truth_questions, function(value, key) {
					if (value['type'] === 'words') {
						value['answer_options'] = JSON.parse(value['answer_options']);
						self.addOption[value['id']] = null;
					}
				});

				angular.forEach($scope.content.pov_questions, function(value, key) {
					if (value['type'] === 'words') {
						value['answer_options'] = JSON.parse(value['answer_options']);
						self.addOption[value['id']] = null;
					}
				});
				$scope.chosenWorkshop.pt_statement = $scope.content.pt_statement;
				$scope.chosenWorkshop.bp_statement_1 = $scope.content.bp_statement_1;
				$scope.chosenWorkshop.bp_statement_2 = $scope.content.bp_statement_2;
				$scope.chosenWorkshop.bp_statement_3 = $scope.content.bp_statement_3;
				$scope.chosenWorkshop.bp_statement_4 = $scope.content.bp_statement_4;
			});
		};

		self.addOption = [];

		$scope.answerC = null;
		$scope.answerA = null;
		$scope.answerB = null;

		$scope.answer = function(qId, answerText) {
			if (qId === '1') {
				$scope.answerA = answerText;
				$scope.answers = null;
			}
			if (qId === '2') {
				$scope.answerB = answerText;
				$scope.answers = null;
			}
			if (qId === '3') {
				$scope.answerC = answerText;
			}
			if ($scope.answerA && $scope.answerB) {
				$scope.answerAB = $scope.answerA + $scope.answerB;
			}
			if ($scope.answerA && $scope.answerB && $scope.answerC) {
				$scope.answers = $scope.answerA + $scope.answerB + $scope.answerC;
			}
		};

		$scope.moveUp = function(index) {
			//$scope.move($scope.content.pov_questions, index, 1);
			$input = {
				secondId: $scope.content.pov_questions[index].id,
				firstId: $scope.content.pov_questions[index - 1].id,
			};

			$backupSecond = $scope.content.pov_questions[index];
			$backupFirst = $scope.content.pov_questions[index - 1];
			$scope.content.pov_questions[index] = $backupFirst;
			$scope.content.pov_questions[index - 1] = $backupSecond;

			groupFactory.reorder($input).then(
				function(result) {
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
					});
				},
				function(error) {}
			);
		};

		$scope.movedown = function(index) {
			$input = {
				secondId: $scope.content.pov_questions[index].id,
				firstId: $scope.content.pov_questions[index + 1].id,
			};
			$backupSecond = $scope.content.pov_questions[index];
			$backupFirst = $scope.content.pov_questions[index + 1];
			$scope.content.pov_questions[index] = $backupFirst;
			$scope.content.pov_questions[index + 1] = $backupSecond;
			groupFactory.reorder($input).then(
				function(result) {
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
					});
				},
				function(error) {}
			);
		};

		$scope.addNewWordOption = function(word) {
			if ($scope.ptQuestion.words.indexOf(word) !== -1) {
				$scope.newWord = null;
			} else {
				$scope.ptQuestion.words.push(word);
				$scope.newWord = null;
			}
		};

		$scope.removeNewWordOption = function(index) {
			$scope.ptQuestion.words.splice(index, 1);
		};

		self.uploadListHandler = function() {
			// Upload the file
			Upload.upload({
				url: env.apiUrl + 'import/users',
				data: {
					file: self.file,
					name: self.selectedGroupId,
					password: self.password,
				},
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
			}).then(
				function(result) {
					// Update the group model
					self.updateGroupInfo(self.group.id);
				},
				function(result) {}
			);
		};

		$scope.changeIcon = function(icon, id) {
			angular.forEach($scope.content.profiler_questions, function(value, key) {
				if (value['id'] == id) {
					value['icon'] = icon;
				}
			});
		};

		$scope.selectNewIcon = function(icon) {
			$scope.newprofilerQuestion.icon = icon;
		};

		$scope.submitContentAndQuestions = function(showSuccess, content1, content2, type, content3) {
			if (type === 'PeopleWeServe' || type === 'BrandPovData' || type === 'ProductTruthData') {
				$scope.valid = true;
				angular.forEach(content1, function(value, key) {
					if (key === 'created_at' || key === 'pivot' || key === 'options') {
						$scope.valid = true;
					} else {
						if (value == null) {
							$scope.valid = false;
						}
					}
				});

				angular.forEach(content2, function(value, key) {
					if (key === 'created_at' || key === 'pivot' || key === 'options') {
						$scope.valid = true;
					} else {
						if (value == null) {
							$scope.valid = false;
						}
					}
				});
				if (content3) {
					angular.forEach(content3, function(value, key) {
						if (key === 'created_at' || key === 'pivot' || key === 'options') {
							$scope.valid = true;
						} else {
							if (value == null) {
								$scope.valid = false;
							}
						}
					});
				}

				$timeout(function() {
					if ($scope.valid == true) {
						if (content3) {
							$input = {
								data: content1,
								data2: content2,
								arrtype: type,
								data3: content3,
							};
						} else {
							$input = {
								data: content1,
								data2: content2,
								arrtype: type,
							};
						}

						groupFactory.contentSubmit($input, self.group.id).then(
							function(result) {
								$scope.content = result.content;
								angular.forEach($scope.content.product_truth_questions, function(value, key) {
									if (value['type'] === 'words') {
										value['answer_options'] = JSON.parse(value['answer_options']);
										self.addOption[value['id']] = null;
									}
								});

								angular.forEach($scope.content.pov_questions, function(value, key) {
									if (value['type'] === 'words') {
										value['answer_options'] = JSON.parse(value['answer_options']);
										self.addOption[value['id']] = null;
									}
								});
								dashboardFactory.fetch().then(
									function(result) {
										$scope.chosenWorkshop = result.group;
										$localStorage.chosenWorkshop = result.group;
										$scope.showContentPage($scope.selectedPage);
									},
									function(error) {}
								);
								if(showSuccess){
									swal('Success', 'The content has been updated.', 'success');
								}
							},
							function(error) {}
						);
					} else {
						swal('Error', 'Please make sure all inputs are filled in.', 'error');
						self.addButton = 'Add User';
					}
				});
			}
			if (type === 'pt_statment') {
				$input = {
					data: $scope.chosenWorkshop.pt_statment,
					data2: $scope.chosenWorkshop.pt_statment,
					arrtype: 'pt_statment',
					data3: $scope.chosenWorkshop.pt_statment,
				};
				groupFactory.contentSubmit($input, self.group.id).then(
					function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);

						swal('Success', 'The content has been updated.', 'success');
					},
					function(error) {}
				);
			}

			if (type === 'bp_statement') {
				$array = {
					bp_statement_1: $scope.chosenWorkshop.bp_statement_1,
					bp_statement_2: $scope.chosenWorkshop.bp_statement_2,
					bp_statement_3: $scope.chosenWorkshop.bp_statement_3,
					bp_statement_4: $scope.chosenWorkshop.bp_statement_4,
				};
				$input = {
					data: $scope.chosenWorkshop,
					data2: $array,
					arrtype: 'bp_statement',
					data3: $array,
				};
				groupFactory.contentSubmit($input, self.group.id).then(
					function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);

						swal('Success', 'The content has been updated.', 'success');
					},
					function(error) {}
				);
			}
			if (type === 'ht_statement') {
				$array = {
					ht_statement_1: $scope.chosenWorkshop.ht_statement_1,
					ht_statement_2: $scope.chosenWorkshop.ht_statement_2,
				};
				$input = {
					data: $scope.chosenWorkshop,
					data2: $array,
					arrtype: 'ht_statement',
					data3: $array,
				};
				groupFactory.contentSubmit($input, self.group.id).then(
					function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);

						swal('Success', 'The content has been updated.', 'success');
					},
					function(error) {}
				);
			}
		};

		$scope.submitContent = function(showSuccess, content, type) {
			if (type === 'HumanTruthData' || type === 'Tension' || type === 'TensionData' || type === 'ProfilerQuestion' || type === 'ImmersionData') {
				$scope.valid = true;
				angular.forEach(content, function(value, key) {
					if (key === 'created_at' || key === 'pivot' || key === 'options') {
						$scope.valid = true;
					} else {
						if (value == null) {
							$scope.valid = false;
						}
					}
				});
				$timeout(function() {
					if ($scope.valid == true) {
						$input = {
							data: content,
							arrtype: type,
						};
						groupFactory.contentSubmit($input, self.group.id).then(
							function(result) {
								$scope.content = result.content;
								angular.forEach($scope.content.product_truth_questions, function(value, key) {
									if (value['type'] === 'words') {
										value['answer_options'] = JSON.parse(value['answer_options']);
										self.addOption[value['id']] = null;
									}
								});

								angular.forEach($scope.content.pov_questions, function(value, key) {
									if (value['type'] === 'words') {
										value['answer_options'] = JSON.parse(value['answer_options']);
										self.addOption[value['id']] = null;
									}
								});
								dashboardFactory.fetch().then(
									function(result) {
										$scope.chosenWorkshop = result.group;
										$localStorage.chosenWorkshop = result.group;
										$scope.showContentPage($scope.selectedPage);
									},
									function(error) {}
								);
								if(showSuccess){
									swal('Success', 'The content has been updated.', 'success');
								}
							},
							function(error) {}
						);
					} else {
						swal('Error', 'Please make sure all inputs are filled in.', 'error');
						self.addButton = 'Add User';
					}
				});
			}
		};

		self.ChangeGroup = function(group) {
			angular.forEach(self.selectGroups, function(value, key) {
				if (value.id == self.selectedGroupId) {
					self.selectedGroup = value;
				}
			});
		};

		/**
		 * Perform profile picture upload
		 */
		$scope.uploadInstructionVideo = function(file, id, type) {
			self.uploadButton = 'Uploading...';
			Upload.upload({
				url: __env.apiUrl + 'instruction/video/' + id + '/' + type,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: {
					upload: file,
				},
			}).then(
				function(resp) {
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						$scope.ProductTruthQuestion = false;
						$scope.defaultOptions = [];
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.ptQuestion = {
							question: null,
							type: null,
							options: $scope.defaultOptions,
						};
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
						swal('Success', 'The content has been updated.', 'success');
						self.uploadButton = 'Upload';
					});
				},
				function(resp) {},
				function(evt) {}
			);
		};

		$scope.deleteWordOption = function(id, key2, array) {
			angular.forEach(array, function(value, key) {
				if (value['id'] == id) {
					value['answer_options'].splice(key2, 1);
				}
			});
		};

		$scope.addWordOption = function(word, id) {
			angular.forEach($scope.content.product_truth_questions, function(value, key) {
				if (value['id'] == id) {
					value['answer_options'].push(word);
				}
				if (value['type'] === 'words') {
					self.addOption[value['id']] = null;
				}
			});
		};

		$scope.removeVideo = function($id) {
			groupFactory.removePovVideo($id).then(function(result) {
				groupFactory.content(self.group.id).then(function(result) {
					$scope.content = result.content;
					angular.forEach($scope.content.product_truth_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});

					angular.forEach($scope.content.pov_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});
					dashboardFactory.fetch().then(
						function(result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							$scope.showContentPage($scope.selectedPage);
						},
						function(error) {}
					);
				});
			});
		};
		$scope.ptQuestionsNew = false;

		$scope.showNewQuestion = function() {
			if ($scope.ptQuestionsNew) {
				$scope.ptQuestionsNew = false;
			} else {
				$scope.ptQuestionsNew = true;
			}
		};

		$scope.addVideo = function($id, $url) {
			$input = {
				url: $url,
			};
			groupFactory.addPovVideo($id, $input).then(function(result) {
				groupFactory.content(self.group.id).then(function(result) {
					$scope.content = result.content;
					angular.forEach($scope.content.product_truth_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});

					angular.forEach($scope.content.pov_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});
					dashboardFactory.fetch().then(
						function(result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							$scope.showContentPage($scope.selectedPage);
						},
						function(error) {}
					);
				});
			});
		};

		$scope.addImage = function($file, key) {
			$scope.ptQuestion.options[key] = $file;
		};

		$scope.addNewProductTruthQuestion = function(question) {
			if (question.type === 'image') {
				$minTwo = 0;
				angular.forEach(question.options, function(value, key) {
					if (value != '../assets/img/blankOption.png') {
						$minTwo++;
					}
				});
				$timeout(function() {
					if ($minTwo == 4) {
						groupFactory.ProductTruthQuestion(question, self.group.id).then(function(result) {
							if (question.type === 'image') {
								Upload.upload({
									url: __env.apiUrl + 'image/question/' + result.question.id + '/option/all',
									headers: {
										Authorization: 'Bearer ' + $localStorage.token,
									},
									data: {
										upload: question.options,
									},
								}).then(
									function(resp) {
										groupFactory.content(self.group.id).then(function(result) {
											$scope.content = result.content;
											angular.forEach($scope.content.product_truth_questions, function(value, key) {
												if (value['type'] === 'words') {
													value['answer_options'] = JSON.parse(value['answer_options']);
													self.addOption[value['id']] = null;
												}
											});

											angular.forEach($scope.content.pov_questions, function(value, key) {
												if (value['type'] === 'words') {
													value['answer_options'] = JSON.parse(value['answer_options']);
													self.addOption[value['id']] = null;
												}
											});
											dashboardFactory.fetch().then(
												function(result) {
													$scope.chosenWorkshop = result.group;
													$localStorage.chosenWorkshop = result.group;
													$scope.showContentPage($scope.selectedPage);
												},
												function(error) {}
											);
											$scope.ProductTruthQuestion = false;
											$scope.defaultOptions = [];
											$scope.defaultOptions.push('../assets/img/blankOption.png');
											$scope.defaultOptions.push('../assets/img/blankOption.png');
											$scope.defaultOptions.push('../assets/img/blankOption.png');
											$scope.defaultOptions.push('../assets/img/blankOption.png');
											$scope.ptQuestion = {
												question: null,
												type: null,
												options: $scope.defaultOptions,
											};
										});
									},
									function(resp) {},
									function(evt) {}
								);
							}
						});
					} else {
						swal('Warning', 'Please provide four options.', 'warning');
					}
				});
			}

			if (question.type === 'words') {
				$minTwo = question.words.length;

				if ($minTwo == 4) {
					groupFactory.ProductTruthQuestion(question, self.group.id).then(function(result) {
						groupFactory.content(self.group.id).then(function(result) {
							$scope.content = result.content;
							angular.forEach($scope.content.product_truth_questions, function(value, key) {
								if (value['type'] === 'words') {
									value['answer_options'] = JSON.parse(value['answer_options']);
									self.addOption[value['id']] = null;
								}
							});
							angular.forEach($scope.content.pov_questions, function(value, key) {
								if (value['type'] === 'words') {
									value['answer_options'] = JSON.parse(value['answer_options']);
									self.addOption[value['id']] = null;
								}
							});
							$scope.ProductTruthQuestion = false;
							$scope.defaultOptions = [];
							$scope.defaultOptions.push('../assets/img/blankOption.png');
							$scope.defaultOptions.push('../assets/img/blankOption.png');
							$scope.defaultOptions.push('../assets/img/blankOption.png');
							$scope.defaultOptions.push('../assets/img/blankOption.png');
							$scope.ptQuestion = {
								question: null,
								type: null,
								options: $scope.defaultOptions,
								words: [],
							};
							dashboardFactory.fetch().then(
								function(result) {
									$scope.chosenWorkshop = result.group;
									$localStorage.chosenWorkshop = result.group;
									$scope.showContentPage($scope.selectedPage);
								},
								function(error) {}
							);
						});
					});
				} else {
					swal('Warning', 'Please provide four options.', 'warning');
				}
			}
			if (question.type === 'text') {
				groupFactory.ProductTruthQuestion(question, self.group.id).then(function(result) {
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						$scope.ProductTruthQuestion = false;
						$scope.defaultOptions = [];
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.defaultOptions.push('../assets/img/blankOption.png');
						$scope.ptQuestion = {
							question: null,
							type: null,
							options: $scope.defaultOptions,
							words: [],
						};
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
					});
				});
			}
		};

		$scope.uploadImageOption = function(file, index, id) {
			var myElement = angular.element(document.querySelector('#upload-' + id + '-' + index));
			myElement.html('Uploading');
			Upload.upload({
				url: __env.apiUrl + 'image/question/' + id + '/option/' + index,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: {
					upload: file,
				},
			}).then(
				function(resp) {
					myElement.html('Upload');
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
					});
				},
				function(resp) {},
				function(evt) {}
			);
		};

		$scope.deleteQuestion = function($id, $type) {
			$input = {
				id: $id,
				arrtype: $type,
			};
			sweetAlert
				.swal({
					title: 'Are you sure?',
					text: "You won't be able to revert this! Please also make sure you have saved all changes before deleting or else those changes will be lost.",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!',
				})
				.then(result => {
					if (result.value) {
						groupFactory.deleteQuestion($input).then(function(result) {
							groupFactory.content(self.group.id).then(function(result) {
								$scope.content = result.content;
								angular.forEach($scope.content.product_truth_questions, function(value, key) {
									if (value['type'] === 'words') {
										value['answer_options'] = JSON.parse(value['answer_options']);
										self.addOption[value['id']] = null;
									}
								});

								angular.forEach($scope.content.pov_questions, function(value, key) {
									if (value['type'] === 'words') {
										value['answer_options'] = JSON.parse(value['answer_options']);
										self.addOption[value['id']] = null;
									}
								});
								dashboardFactory.fetch().then(
									function(result) {
										$scope.chosenWorkshop = result.group;
										$localStorage.chosenWorkshop = result.group;
										$scope.showContentPage($scope.selectedPage);
									},
									function(error) {}
								);
							});
						});
					}
				});
		};

		$scope.addMainQuestion = function($question, $questionid) {
			$input = {
				question: $question,
				questionid: $questionid,
			};
			$scope.newMainQuestion = '';
			groupFactory.saveMainQuestion($input).then(function(result) {
				$scope.newMainQuestion = '';
				var elem = $('#newMainQuestion' + $questionid);
				angular.forEach(elem, function(value, key) {
					value.value = null;
				});
				groupFactory.content(self.group.id).then(function(result) {
					$scope.content = result.content;
					angular.forEach($scope.content.product_truth_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});

					angular.forEach($scope.content.pov_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});
					dashboardFactory.fetch().then(
						function(result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							$scope.showContentPage($scope.selectedPage);
						},
						function(error) {}
					);
				});
			});
		};

		$scope.UpdatePovQuestionFile = function($file, $qid) {
			self.updatingQuestionFile = 'Updating File...';
			Upload.upload({
				url: __env.apiUrl + 'pov/' + self.group.id + '/video/add/' + $qid,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: {
					upload: $file,
				},
			}).then(
				function(resp) {
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
						self.updatingQuestionFile = 'Update File';
					});
				},
				function(resp) {},
				function(evt) {}
			);
		};

		$scope.addNewPovYouTube = function($url) {

			if($url.length == 11){

				$input = {
					url: 'https://www.youtube.com/embed/' + $url,
					type: 'youtube',
				};
				groupFactory.createPovQuestion($input, self.group.id).then(function(result) {
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;

						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						$scope.povQuestionNew = {
							type: null,
						};

						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {
								console.log(error);
							}
						);
					});
				});

			}
			else{

				alert("Bad YouTube Code. Please ensure you're only adding the code, which is everything after ?v=.");

			}

		};

		$scope.addNewPovUrl = function($url) {
			$input = {
				url: $url,
				type: 'video',
			};

			groupFactory.createPovQuestion($input, self.group.id).then(function(result) {
				groupFactory.content(self.group.id).then(function(result) {
					$scope.content = result.content;
					angular.forEach($scope.content.product_truth_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});

					angular.forEach($scope.content.pov_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});

					$scope.povQuestionNew = {
						type: null,
					};

					dashboardFactory.fetch().then(
						function(result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							$scope.showContentPage($scope.selectedPage);
						},
						function(error) {}
					);
				});
			});
		};

		$scope.addPovImage = function($file) {
			self.uploadButtonImage = 'Uploading Image...';
			Upload.upload({
				url: __env.apiUrl + 'pov/question/create/' + self.group.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: {
					upload: $file,
					type: 'image',
				},
			}).then(
				function(resp) {
					self.uploadButtonImage = 'Upload Image';
					$scope.povQuestionNew = {
						type: null,
					};
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});

						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
					});
				},
				function(resp) {},
				function(evt) {}
			);
		};

		$scope.addNewPovFile = function($file) {
			self.uploadButtonVideo = 'Uploading Video...';
			$scope.newPovQuestion = false;
			$scope.povQuestionNew = null;
			Upload.upload({
				url: __env.apiUrl + 'pov/question/create/' + self.group.id,
				headers: {
					Authorization: 'Bearer ' + $localStorage.token,
				},
				data: {
					upload: $file,
					type: 'video',
				},
			}).then(
				function(resp) {
					self.uploadButtonVideo = 'Upload Video';
					groupFactory.content(self.group.id).then(function(result) {
						$scope.content = result.content;
						angular.forEach($scope.content.product_truth_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						angular.forEach($scope.content.pov_questions, function(value, key) {
							if (value['type'] === 'words') {
								value['answer_options'] = JSON.parse(value['answer_options']);
								self.addOption[value['id']] = null;
							}
						});
						dashboardFactory.fetch().then(
							function(result) {
								$scope.chosenWorkshop = result.group;
								$localStorage.chosenWorkshop = result.group;
								$scope.showContentPage($scope.selectedPage);
							},
							function(error) {}
						);
					});
				},
				function(response) {
					if (response.status > 0) $scope.errorMsg = response.status + ': ' + response.data;
				},
				function(evt) {
					let progress = parseInt((100.0 * evt.loaded) / evt.total);
					console.log(progress);
				}
			);
		};

		$scope.addNewImmersionQuestion = function($input) {
			groupFactory.createImmersionQuestion($input, self.group.id).then(function(result) {
				$scope.povQuestion = false;
				$scope.immersionQuestion = {
					question: null,
					type: null,
				};
				groupFactory.content(self.group.id).then(function(result) {
					$scope.content = result.content;
					angular.forEach($scope.content.product_truth_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});

					angular.forEach($scope.content.pov_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});
					dashboardFactory.fetch().then(
						function(result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							$scope.showContentPage($scope.selectedPage);
						},
						function(error) {}
					);
				});
			});
		};

		$scope.addNewProfilerQuestion = function($input) {
			groupFactory.createProfilerQuestion($input, self.group.id).then(function(result) {
				$scope.profilerQuestion = false;
				$scope.newprofilerQuestion = {
					icon: null,
					question: null,
				};
				groupFactory.content(self.group.id).then(function(result) {
					$scope.content = result.content;
					angular.forEach($scope.content.product_truth_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});

					angular.forEach($scope.content.pov_questions, function(value, key) {
						if (value['type'] === 'words') {
							value['answer_options'] = JSON.parse(value['answer_options']);
							self.addOption[value['id']] = null;
						}
					});
					dashboardFactory.fetch().then(
						function(result) {
							$scope.chosenWorkshop = result.group;
							$localStorage.chosenWorkshop = result.group;
							$scope.showContentPage($scope.selectedPage);
						},
						function(error) {}
					);
				});
			});
		};
	},
});
