/* eslint-disable angular/window-service */
(function(window) {
	window.__env = window.__env || {};

	// API url
	window.__env.apiUrl = 'https://api.purposedive.com/';

	// Base url
	window.__env.baseUrl = '/';

	// Whether or not to enable debug mode
	// Setting this to false will disable console output
	window.__env.enableDebug = false;
})(this);
