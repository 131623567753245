angular.module('app').component('dashboard.dashboard', {
	templateUrl: 'app/dashboard/dashboard-dashboard.html',
	controllerAs: 'vm',

	controller: function($rootScope, $scope, $localStorage, authFactory, $state, $window, sweetAlert, dashboardFactory, __env) {
		if (__env.enableDebug) {
			console.log('dashboardController');
		}

		$scope.game = 1;
		$scope.freshLogin = false;
		if(angular.isDefined($rootScope.isFreshLogin)) {
			$scope.freshLogin = $rootScope.isFreshLogin;
		}

		// Assign a default user role
		$scope.role = "user"
		$localStorage.role = 'user';
		$localStorage.showWorkshopHeader = false;
		$scope.showWorkshopHeader = false;

		// Runs after a user has selected a workshop
		$scope.selectWorkshop = function($id, $workshopName, $workshopUserRole, $workshopIsLive) {
			if (__env.enableDebug) {
				console.log('$scope.selectWorkshop');
				console.log('Workshop ID: ' + $id);
				console.log('Workshop Role: ' + $workshopUserRole);
				console.log('Workshop Live: ' + $workshopIsLive);
			}

			$localStorage.showWorkshopHeader = true;
			$localStorage.lastPlayedPhase = 1;

			// Per workshop role assignment
			if ('admin' == $workshopUserRole || 'super admin' == $workshopUserRole) {
				$scope.role = 'super admin';
				$localStorage.role = 'super admin';
			} else {
				$scope.role = 'user';
				$localStorage.role = 'user';
			}

			// If the workshop isn't live then notify users
			if ($workshopUserRole === 'user' && $workshopIsLive === 'false') {
				sweetAlert.swal({
					title: 'Sorry',
					text: 'This workshop is not available yet, you will receive and email informing you when you can collaborate.',
					type: 'error',
				});
			}

			// If the workshop is live or if admin / super admin then proceed
			else {
				$localStorage.chosenWorkshop = {
					id: $id,
					name: $workshopName,
					is_live: $workshopIsLive
					};

				// Fetch the selected workshop information
				dashboardFactory.fetch().then(
					function(result) {
						$scope.chosenWorkshop = result.group;
						$localStorage.chosenWorkshop = result.group;

						// Write dataset to localstorage
						$localStorage.dataset = {
							introduction: $scope.chosenWorkshop.tension_data[0].introduction,
							1: {
								question: $scope.chosenWorkshop.tension_data[0].q01q,
								a: $scope.chosenWorkshop.tension_data[0].q01a,
								b: $scope.chosenWorkshop.tension_data[0].q01b,
							},
							2: {
								question: $scope.chosenWorkshop.tension_data[0].q02q,
								a: $scope.chosenWorkshop.tension_data[0].q02a,
								b: $scope.chosenWorkshop.tension_data[0].q02b,
							},
							BA: {
								// BA
								question: $scope.chosenWorkshop.tension_data[0].q03qba,
								a: $scope.chosenWorkshop.tension_data[0].q03bab,
								b: $scope.chosenWorkshop.tension_data[0].q03baa,
							},
							AA: {
								// AA
								question: $scope.chosenWorkshop.tension_data[0].q03qaa,
								a: $scope.chosenWorkshop.tension_data[0].q03aab,
								b: $scope.chosenWorkshop.tension_data[0].q03aaa,
							},
							BB: {
								// BB
								question: $scope.chosenWorkshop.tension_data[0].q03qbb,
								a: $scope.chosenWorkshop.tension_data[0].q03bba,
								b: $scope.chosenWorkshop.tension_data[0].q03bbb,
							},
							AB: {
								// AB
								question: $scope.chosenWorkshop.tension_data[0].q03qab,
								a: $scope.chosenWorkshop.tension_data[0].q03abb,
								b: $scope.chosenWorkshop.tension_data[0].q03aba,
							},
						};

										// Handle users
				if ($workshopUserRole === 'user') {
					// Get the current workshop progress of the user
					$scope.getProgress = function() {
						dashboardFactory.progress().then(function(result) {
							$localStorage.progress = result.progress;

							// Check the users current workshop game
							$scope.game = JSON.parse(result.current_progress).current_game;
							$localStorage.lastPlayedPhase = $scope.game;

							// Redirect the user to correct game
								if ($scope.game === 1) {
									$state.go('dashboard.immersion-debrief', {}, { reload: true });
								}
								if ($scope.game === 2) {
									$state.go('dashboard.pws', {}, { reload: true });
								}
								if ($scope.game === 3) {
									$state.go('dashboard.human-truth', {}, { reload: true });
								}
								if ($scope.game === 4) {
									$state.go('dashboard.brand-pov', {}, { reload: true });
								}
								if ($scope.game === 5) {
									$state.go('dashboard.product-truth', {}, { reload: true });
								}
								if ($scope.game === 6) {
									$state.go('dashboard.pyramid', {}, { reload: true });
								}
								if ($scope.game === 7) {
									$state.go('dashboard.completed', {}, { reload: true });
								}

						});
					};
					// Get user progress and navigate to phase
					$scope.getProgress();
				}

				// Handle admin / super admin
				if ('admin' == $workshopUserRole || 'super admin' == $workshopUserRole) {
					$window.location.href = '/manage-content/immersion';
				}

					},
					function(error) {
						console.log(error);
					}
				);


			}
		};

		// Fetch the users profile information
		authFactory.profile($localStorage.token).then(function(response) {

			$localStorage.user = response.user;
			// Check if the user is a super admin
			if(angular.isDefined(response.user) && angular.isDefined(response.user.isSuperAdmin) && response.user.isSuperAdmin){
				$scope.role = "super admin"
				$localStorage.role = 'super admin';
			}else{
				$scope.role = "user"
				$localStorage.role = 'user';
			}

			if(angular.isDefined(response.user) && angular.isDefined(response.user.group) ){

				if($scope.role === "user" && $scope.freshLogin === true && angular.isDefined(response.user.current_group)) {
					var current_group = response.user.current_group;
					var workshops = response.user.group;
					var activeWorkshop = (workshops.length > 1) ? workshops.find(function (workshop) {
						if (workshop.id === current_group) {
							return workshop;
						}
						return false;
					}) : workshops[0] ? workshops[0] : false;

					if (angular.isDefined(activeWorkshop) && activeWorkshop !== false && activeWorkshop.is_live === "true") {
						$scope.selectWorkshop(activeWorkshop.id, activeWorkshop.name, activeWorkshop.role, activeWorkshop.is_live);
					}
				}

				$scope.allworkshops = response.user.group;
			}
		});

	},
});
