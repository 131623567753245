angular.module('app').factory('authFactory', function($state, $http, $localStorage, __env) {
	return {
		login: function(input) {
			if (__env.enableDebug) {
				console.log('authFactory.login');
			}
			return $http({
				method: 'POST',
				url: __env.apiUrl + 'oauth/token',
				data: {
					grant_type: 'password',
					client_id: '1',
					client_secret: '8h8j9589h',
					username: input.username,
					password: input.password,
				},
			})
				.then(function(response) {
					return response.data;
				})
				.then(function(error) {
					return error;
				});
		},

		/**
		 * Logout the user by resetting the users local storage variables
		 */
		logout: function() {
			if (__env.enableDebug) {
				console.log('loginService.logout ran');
			}
			// Reset the user local storage variables
			$localStorage.token = null;
			$localStorage.role = null;
			$localStorage.user = null;
			$localStorage.user.email = null;
			$localStorage.workshops = null;
			$localStorage.lastPlayedPhase = 1;

			// Go to login state
			$state.go('login');
		},

		/**
		 * Fetch logged in users profile
		 */
		profile: function(data) {
			return $http({
				method: 'GET',
				url: __env.apiUrl + 'profile',
				headers: {
					Authorization: 'Bearer ' + data,
				},
			}).then(
				function(response) {
					return response.data;
				},
				function(response) {
					return response;
				}
			);
		},
	};
});
