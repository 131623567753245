angular.module('app').component('login', {
	templateUrl: 'app/auth/login.html',
	controllerAs: 'vm',

	controller: function($state, $rootScope, $localStorage, authFactory, sweetAlert, __env) {
		if (__env.enableDebug) {
			console.log('loginController');
		}

		// Set this to self to avoid conflicts
		const self = this;
		self.version = $rootScope.version;

		// Run when component is ready
		self.$onInit = function() {
			// Set initial button text
			self.button = 'Login';
		};

		// Handle user sign in
		self.loginHandler = function() {
			if (__env.enableDebug) {
				console.log('loginHandler');
			}

			// Change button text
			self.button = 'Logging in..';

			// Set the input object
			const input = {
				username: self.email,
				password: self.password,
			};

			// Call the authFactory
			authFactory.login(input).then(
				function(result) {
					// Write token to local storage
					$localStorage.token = result.access_token;
					$localStorage.showWorkshopHeader = false;

					// Auth successful so redirect to dashboard
					$state.go('dashboard.dashboard');
					self.button = 'Login';
				},
				function(error) {
					console.error(error);
					// Show user error message
					sweetAlert.swal({
						title: 'Error',
						text: error.data.message,
						type: 'error',
					});
					self.button = 'Try again';
				}
			);
		};
	},
});
