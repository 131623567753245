angular.module('app').component('groupResults', {
	templateUrl: 'app/dashboard/group/group-results.html',
	controllerAs: 'vm',
	bindings: {
		groups: '<',
	},

	controller: function(
		$rootScope,
		$scope,
		$localStorage,
		$stateParams,
		$window,
		groupFactory,
		userFactory,
		sweetAlert,
		titleService,
		Upload,
		$timeout,
		ngToast,
		$compile,
		dashboardFactory,
		__env
	) {
		// Set this to self to avoid conflicts
		const self = this;
		// Set the basic controller data to be passed to title service
		titleService.data = {
			title: 'Workshop Results',
		};

		// Broadcast section title to the header
		$rootScope.$broadcast('headerTitle', {
			title: 'Workshop Results',
			icon: 'fa-users',
		});

		// Run when component is ready
		self.$onInit = function() {
			self.groups = $localStorage.workshops;
			$scope.QandA = [];
			$scope.answerSet = { answers: [] };

			$scope.dataPage = 'immersion';

			$scope.changeData = function(data) {
				$scope.dataPage = data;
			};

			$scope.url = __env.apiUrl;

			self.selectedGroupId = $localStorage.chosenWorkshop.id;
			$scope.chosenWorkshop = $localStorage.chosenWorkshop;
			$scope.pyramid1 = {
				human_truth: $localStorage.chosenWorkshop.pyramid[0].human_truth,
				product_truth: $localStorage.chosenWorkshop.pyramid[0].product_truth,
				serve: $localStorage.chosenWorkshop.pyramid[0].people_we_serve,
				brand_purpose: $localStorage.chosenWorkshop.pyramid[0].brand_point_of_view,
			};
			$scope.pyramid2 = {
				human_truth: $localStorage.chosenWorkshop.pyramid[1].human_truth,
				product_truth: $localStorage.chosenWorkshop.pyramid[1].product_truth,
				serve: $localStorage.chosenWorkshop.pyramid[1].people_we_serve,
				brand_purpose: $localStorage.chosenWorkshop.pyramid[1].brand_point_of_view,
			};
			$scope.pyramid3 = {
				human_truth: $localStorage.chosenWorkshop.pyramid[2].human_truth,
				product_truth: $localStorage.chosenWorkshop.pyramid[2].product_truth,
				serve: $localStorage.chosenWorkshop.pyramid[2].people_we_serve,
				brand_purpose: $localStorage.chosenWorkshop.pyramid[2].brand_point_of_view,
			};
			$scope.pyramid4 = {
				human_truth: $localStorage.chosenWorkshop.pyramid[3].human_truth,
				product_truth: $localStorage.chosenWorkshop.pyramid[3].product_truth,
				serve: $localStorage.chosenWorkshop.pyramid[3].people_we_serve,
				brand_purpose: $localStorage.chosenWorkshop.pyramid[3].brand_point_of_view,
			};
			self.gameOrder = [];
			angular.forEach($localStorage.chosenWorkshop.question, function(value, key) {
				$scope.answerSet.answers.push('');
				$q = {
					question: value.question,
					questionID: key + 1,
					question_id: value.id,
					type: value.type,
				};
				if (value.answer_options) {
					$splitoptions = value.answer_options.split(',');
					$options = [];
					angular.forEach($splitoptions, function(value, key) {
						$options.push({
							name: value,
							id: key + 1,
						});
						$scope.optionValue++;
					});
					$q.options = $options;
				}
				$scope.QandA.push($q);
			});
			$scope.collaborationPovGroupResults($localStorage.chosenWorkshop.id, 0);
			$scope.collaborationProductGroupResults($localStorage.chosenWorkshop.id, 0);
			$scope.collaborationHumanGroupResults($localStorage.chosenWorkshop.id, 0);

			$scope.getMetaphorResults($localStorage.chosenWorkshop.id);
			$scope.getMomentsResults($localStorage.chosenWorkshop.id);
			$scope.getTshirtResults($localStorage.chosenWorkshop.id);
			$scope.getPyramidResults($localStorage.chosenWorkshop.id);

			dashboardFactory.immersionResults($localStorage.chosenWorkshop.id).then(function(result) {
				$scope.immersions = result.immersions;
			});
		};

		$scope.collaborationPovGroupResults = function($wid, $gid) {
			dashboardFactory.collaborationPovGroupResults($wid, $gid).then(function(result) {
				$scope.groupAnswers1 = result.groupOne.answer;
				$scope.brandStatements1 = result.groupOne.brandStatements;
				$scope.tshirts1 = result.groupOne.tshirts[0];
				$scope.categories1 = [];
				$scope.data1 = [];
				$scope.Math = $window.Math;

				angular.forEach(result.groupOne.answer, function(value, key) {
					// QandA[key].options[(value.answer-1)].name;
					$scope.categories1.push(value.answer);
					$scope.data1.push(value.count);
				});
				/////
				$scope.groupAnswers2 = result.groupTwo.answer;
				$scope.brandStatements2 = result.groupTwo.brandStatements;
				$scope.tshirts2 = result.groupTwo.tshirts[0];
				$scope.categories2 = [];
				$scope.data2 = [];
				angular.forEach(result.groupTwo.answer, function(value, key) {
					$scope.categories2.push(value.answer);
					$scope.data2.push(value.count);
				});
				/////
				$scope.groupAnswers3 = result.groupThree.answer;
				$scope.brandStatements3 = result.groupThree.brandStatements;
				$scope.tshirts3 = result.groupThree.tshirts[0];
				$scope.categories3 = [];
				$scope.data3 = [];
				angular.forEach(result.groupThree.answer, function(value, key) {
					$scope.categories3.push(value.answer);
					$scope.data3.push(value.count);
				});

				/////
				$scope.groupAnswers4 = result.groupFour.answer;
				$scope.brandStatements4 = result.groupFour.brandStatements;
				$scope.tshirts4 = result.groupFour.tshirts[0];
				$scope.categories4 = [];
				$scope.data4 = [];
				angular.forEach(result.groupFour.answer, function(value, key) {
					$scope.categories4.push(value.answer);
					$scope.data4.push(value.count);
				});
			});
		};

		// BUG - CAN RETURN EMPTY RESULTS
		$scope.collaborationProductGroupResults = function($wid, $gid) {
			/*
			dashboardFactory.collaborationProductGroupResults($wid, $gid).then(function(result) {
				$scope.productGroups = result.groups;
			});
			*/
			dashboardFactory.fetch().then(function(result) {
				$scope.productGroups = result.group.statement;
			});

		};

		$scope.collaborationHumanGroupResults = function($wid, $gid) {
			dashboardFactory.collaborationHumanGroupResults($wid, $gid).then(function(result) {
				$scope.humanGroups = result.groups;
			});
		};

		// Individual Results
		$scope.getMetaphorResults = function($wid) {
			dashboardFactory.getWorkshopResults($wid, 'metaphors').then(function(response) {
				$scope.metaphors = response.data;
			});
		};

		$scope.getMomentsResults = function($wid) {
			dashboardFactory.getWorkshopResults($wid, 'moments').then(function(response) {
				$scope.moments = response.data;
			});
		};

		$scope.getTshirtResults = function($wid) {
			dashboardFactory.getWorkshopResults($wid, 'tshirts').then(function(response) {
				$scope.tshirts = response.data;
			});
		};

		$scope.getPyramidResults = function($wid) {
			dashboardFactory.getWorkshopResults($wid, 'pyramids').then(function(response) {
				$scope.individualPyramids = response.data;
			});
		};


		self.ChangeGroup = function(group) {
			angular.forEach(self.selectGroups, function(value, key) {
				if (value.id == self.selectedGroupId) {
					self.selectedGroup = value;
				}
			});
		};
	},
});
